import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { AddressEligibility } from '../models/addressEligibility.model';
import { AddressForEligibilityRequest } from '../models/addressForEligibility.model';
import { VyVTarifCheckAddressRequest } from '../models/vyVTarifCheckAddressRequest';

@Injectable({
  providedIn: 'root'
})
export class EligibilityService {
  public configuration = new Configuration();
  baseUrl: string;

  constructor(private http: HttpClient, configuration: Configuration) {
    this.baseUrl = configuration.apiBasePath ?? '';
  }

  public checkAddressEligibility(
    addr: VyVTarifCheckAddressRequest,
    distributorCode: string
  ): Observable<AddressEligibility> {
    const address: AddressForEligibilityRequest = {
      StreetName: addr.NomDeRue ?? null,
      StreetNumber: addr.NumeroDeRue ?? null,
      StreetNameAndNumber: addr.NumeroEtRue ?? null,
      ZipCode: addr.CodePostal,
      City: addr.Ville,
      Country: 'France'
    };

    let params = new HttpParams()
      .append('PackageCode', 'CON')
      .append('DistributorCode', distributorCode)
      .append('ZipCode', address.ZipCode)
      .append('City', address.City)
      .append('Country', address.Country);

    if (address.StreetName) {
      params = params.append('StreetName', address.StreetName);
    }
    if (address.StreetNumber) {
      params = params.append('StreetNumber', address.StreetNumber);
    }
    if (address.StreetNameAndNumber) {
      params = params.append('StreetNameAndNumber', address.StreetNameAndNumber);
    }

    return this.http.get<AddressEligibility>(`${this.baseUrl}eligibilities/address`, {
      params
    });
  }
}
