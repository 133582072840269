import { PopUpInfoHAVM, PopUpInfoVM } from '../../models/components/popupinfoVm';
import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppResource } from 'src/app/app.resource';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-popup-info',
  templateUrl: './popup-info.component.html',
  styleUrls: ['./popup-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PopupInfoComponent implements OnInit {
  @Input() popUpInfo!: PopUpInfoVM;
  @Input() popUpInfoHA!: PopUpInfoHAVM;
  @ViewChild('op') child!: OverlayPanel;
  actif: boolean = false;
  imgSrc!: string;
  resource: any;

  constructor(resources: AppResource) {
    this.resource = resources['resource'];
  }

  ngOnInit() {
    this.imgSrc = this.resource.infobulle.popupInactive;
  }

  showHide() {
    this.actif = !this.actif;
    if (this.actif) this.imgSrc = this.resource.infobulle.popupActive;
    else this.imgSrc = this.resource.infobulle.popupInactive;
  }
}
