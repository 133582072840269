import { SaisieSignature } from './../../models/form-state.model';
export interface ElectronicSignatureState {
  electronicSignature: SaisieSignature;
  isValid: boolean;
}

export const initialState: ElectronicSignatureState = {
  electronicSignature: {
    AcceptationCP: false,
    AcceptationFIC: false,
    AcceptationMandatSEPA: false,
    CodeSms: undefined,
    SignatureToken: undefined
  },
  isValid: false
};
