export interface PriceRequestModel {
  ProductCode: string;
  DistributorCode: string;
  PromotionCode?: string | null;
  ContextId: string;
  Address: AddressModel;
  SubscriptionInformation: MrhSubscriptionInformationModel;
}

export interface AddressModel {
  StreetNumberAndName: string;
  ZipCode: string;
  City: string;
  Country: string;
  Housing: string;
  Floor?: string | null;
}

export interface MrhSubscriptionInformationModel {
  ResidenceType: string;
  OccupationStatus: string;
  RoomCount: number;
  RoomCountGt40M2: number;
  HasClaims: boolean;
  DeductibleType?: string | null;
  SchoolInsuranceType?: string | null;
  HasSchoolInsuranceOption?: boolean | null;
  HasOptionVelo?: boolean | null;
  ValueObjectOptionType?: string | null;
  QuotationDate?: Date | null;
  HasSwimmingPool: boolean;
  HasVeranda: boolean;
  HasNomadBusinessOption?: boolean | null;
  DependencyType?: string;
  HasGreenEnergyProductionInstallation: boolean;
  VYVCollaboratorDiscount?: string | null;
  CommercialLatitudeRequestedType?: string | null;
  QuotationReference?: string | null;
}
export type DependencyTypes = 'Aucune' | 'MoinsDe50m2' | 'De50a100m2' | 'PlusDe100m2';
export const DependencyTypes = {
  _none: 'Aucune' as DependencyTypes,
  _0_50m2: '0-50m2' as DependencyTypes,
  _50_100m2: '50-100m2' as DependencyTypes,
  _100_250m2: '100-250m2' as DependencyTypes
};
