<div class="mb-6 space-y-4 md:grid md:gap-x-4 md:grid-cols-2 md:space-y-0">
  <button
    pButton
    class="buttonToggleSM"
    [label]="resource.form.yes"
    (click)="selectValue(genericEnum.Yes)"
    [ngClass]="setClassActive(yes)"></button>
  <button
    pButton
    class="buttonToggleSM"
    [label]="resource.form.no"
    (click)="selectValue(genericEnum.No)"
    [ngClass]="setClassActive(no)"></button>
</div>

<app-web-callback-popup (cancel)="cancel()" [show]="displayModal"></app-web-callback-popup>
