export class FormuleRate {
  rateF_B!: number;
  rateF!: number;
  rateF_Annuel!: number;
  rateF_Show!: string | null;
  F_Url!: string;

  constructor(
    rateF_B: number,
    rateF: number,
    rateF_Annuel: number,
    rateF_Show: string | null,
    F_Url: string
  ) {
    this.rateF_B = rateF_B;
    this.rateF = rateF;
    this.rateF_Annuel = rateF_Annuel;
    this.rateF_Show = rateF_Show;
    this.F_Url = F_Url;
  }
}

export class rateVM {
  F1!: FormuleRate;
  F2!: FormuleRate;
  offerSelected!: string;
  promoOK!: number;

  constructor() {}
}
