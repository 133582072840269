import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import deepmerge from 'deepmerge';

@Injectable()
export class AppResource {
  private resource: any = null!;

  constructor(private http: HttpClient) {}

  public getResource(key: any) {
    return this.resource[key];
  }

  public load(brand: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const baseRequest = this.http.get(`./locale/base.json`);
      if (baseRequest) {
        baseRequest.subscribe((responseData: any) => {
          this.resource = responseData;
          const brandRequest = this.http.get(`./locale/${brand}/configuration.json`);
          if (brandRequest) {
            brandRequest.subscribe((responseData: any) => {
              this.resource = deepmerge(this.resource, responseData);
              resolve();
            });
          } else {
            console.error('Locale resource file "configuration.json" is not valid');
            reject();
          }
        });
      } else {
        console.error('Locale resource file "base.json" is not valid');
        reject();
      }
    });
  }
}
