<div class="flex flex-col min-h-screen">
  <app-header [headerStep]="headerStep" [local]="true"></app-header>

  <div class="flex-grow flex items-center justify-center">
    <div
      *ngIf="title"
      class="bg-white text-center rounded-lg shadow-md font-sans text-grey mx-auto my-8 p-8 max-w-xl">
      <h2 class="text-text font-bold mb-4 text-xl">{{ title }}</h2>
      <p *ngIf="message" class="text-lg">{{ message }}</p>
    </div>
  </div>

  <div class="container mx-auto h-28 md:h-40">
    <app-footer class="footer"></app-footer>
  </div>
  <app-web-callback></app-web-callback>
</div>
