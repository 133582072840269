import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Context } from 'src/app/api/models/context.model';
import { ContextService } from 'src/app/api/services/context.service';

@Injectable()
export class ContextResolver {
  constructor(private ContextService: ContextService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<Context> {
    const contextId = route.params['id'];
    return this.ContextService.getContext(contextId);
  }
}

