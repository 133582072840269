export class SubscriberCGVm {
  text1CG: string;
  text2CG: string;
  text3CG: string;
  text3bisCG: string;
  text4CG: string;
  text5CG: string;

  constructor(
    text1CG: string,
    text2CG: string,
    text3CG: string,
    text3bisCG: string,
    text4CG: string,
    text5CG: string
  ) {
    this.text1CG = text1CG;
    this.text2CG = text2CG;
    this.text3CG = text3CG;
    this.text3bisCG = text3bisCG;
    this.text4CG = text4CG;
    this.text5CG = text5CG;
  }
}
