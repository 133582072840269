import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpUrlEncodingCodec
} from '@angular/common/http';
import { inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from 'src/app/api/configuration';
import { BASE_PATH } from 'src/app/api/variables';
import { environment } from 'src/environments/environment';
import { VyVConsentPostRequest } from '../models/vyVConsentPostRequest';

@Injectable()
export class ConsentService {
  protected basePathRoot = '/';
  private basePath = inject(BASE_PATH, { optional: true });
  public defaultHeaders = new HttpHeaders({
    'x-brand': environment.brand
  });
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() configuration: Configuration) {
    if (this.basePath) {
      this.basePathRoot = this.basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePathRoot = this.basePath ?? configuration.basePath ?? '/';
    }
  }

  /**
   *
   * Get list Consentement
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public GetConsentement(observe?: 'body', reportProgress?: boolean): Observable<any>;
  public GetConsentement(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public GetConsentement(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public GetConsentement(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new HttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json', 'application/problem+json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    return this.httpClient.request<any>('get', `${this.basePath}consents`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  PostConsentement(
    consentRequest: VyVConsentPostRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    return this.httpClient.request<any>('post', `${this.basePath}consents`, {
      body: consentRequest,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }
}
