export class InsuranceBenefitVM {
  text1: string;
  textB_1: string;
  text2: string;
  textB_2: string;
  description: string[];

  constructor(
    text1: string,
    textB_1: string,
    text2: string = '',
    textB_2: string = '',
    description: string[]
  ) {
    this.text1 = text1;
    this.textB_1 = textB_1;
    this.text2 = text2;
    this.textB_2 = textB_2;
    this.description = description;
  }
}
