import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppResource } from 'src/app/app.resource';
import { PolicyState } from 'src/app/core/enums/policy-state.enum';
import { Resource } from 'src/app/resources/resource';
import { HeaderVM } from 'src/app/shared/models/components/headerVm';

@Component({
  selector: 'app-e-sign-msg',
  templateUrl: './e-sign-msg.component.html',
  styleUrls: ['./e-sign-msg.component.scss']
})
export class ESignMsgComponent implements OnInit {
  status: number = 0;
  message?: string = '';
  title: string = '';
  public resource!: Resource;
  headerStep: HeaderVM = new HeaderVM('Signature', 3, 'Documents contractuels', 9);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private resources: AppResource
  ) {
    this.resource = resources['resource'];
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const statusParam = params.get('status');
      const status = statusParam ? parseInt(statusParam, 10) : NaN;
      this.handleStatus(status);
    });
  }

  private handleStatus(status: number): void {
    if (isNaN(status)) {
      this.router.navigate(['error404']);
    } else if (status === PolicyState.Wait4Sign) {
      this.router.navigate(['electronicsignature']);
    } else {
      this.status = status;
      this.setMessage();
    }
  }

  private setMessage(): void {
    switch (this.status) {
      case PolicyState.Signed:
        this.setMessageContent(
          this.resource.signature['signature_msg_title'],
          this.resource.signature['signed_message']
        );
        break;
      case PolicyState.Ongoing:
      case PolicyState.Unpaid:
        this.setMessageContent(
          this.resource.signature['signature_msg_title'],
          this.resource.signature['ongoing_message']
        );
        break;
      case PolicyState.AutoTerm:
        this.setMessageContent(
          this.resource.signature['signature_msg_title'],
          this.resource.signature['auto_term_message']
        );
        break;
      case PolicyState.Suspended:
        this.setMessageContent(
          this.resource.signature['signature_msg_title'],
          this.resource.signature['suspended_message']
        );
        break;
      case PolicyState.Na:
      case PolicyState.Terminated:
      case PolicyState.Replaced:
      case PolicyState.Cancelled:
        this.setMessageContent(this.resource.signature['inactive_title']);
        break;
      default:
        this.setMessageContent('');
        break;
    }
  }

  private setMessageContent(title: string, message?: string): void {
    this.title = title;
    this.message = message;
  }
}
