//#region Imports
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from './shared.module';
//#endregion

//#region General Information
import { AddressComponent } from 'src/app/views/general-information/address/address.component';
import { DependencyComponent } from 'src/app/views/general-information/dependency/dependency.component';
import { HouseApartmentComponent } from 'src/app/views/general-information/house-apartment/house-apartment.component';
import { InsuredValueComponent } from 'src/app/views/general-information/insured-value/insured-value.component';
import { NumberPiecesComponent } from 'src/app/views/general-information/number-pieces/number-pieces.component';
import { OwnerTenantComponent } from 'src/app/views/general-information/owner-tenant/owner-tenant.component';
import { ParticularityComponent } from 'src/app/views/general-information/particularity/particularity.component';
import { PreferentialRateComponent } from 'src/app/views/general-information/preferential-rate/preferential-rate.component';
import { PreviousClaimComponent } from 'src/app/views/general-information/previous-claim/previous-claim.component';
import { PrimarySecondaryComponent } from 'src/app/views/general-information/primary-secondary/primary-secondary.component';
import { SolidarityAddressComponent } from 'src/app/views/general-information/solidarity-address/solidarity-address.component';
//#endregion

@NgModule({
  declarations: [
    SolidarityAddressComponent,
    AddressComponent,
    HouseApartmentComponent,
    OwnerTenantComponent,
    PrimarySecondaryComponent,
    PreferentialRateComponent,
    NumberPiecesComponent,
    PreviousClaimComponent,
    DependencyComponent,
    ParticularityComponent,
    InsuredValueComponent
  ],
  imports: [RouterModule, CommonModule, SharedModule],
  exports: [
    SolidarityAddressComponent,
    AddressComponent,
    HouseApartmentComponent,
    OwnerTenantComponent,
    PrimarySecondaryComponent,
    PreferentialRateComponent,
    NumberPiecesComponent,
    PreviousClaimComponent,
    DependencyComponent,
    ParticularityComponent,
    InsuredValueComponent
  ]
})
export class GeneralInformationModule {}
