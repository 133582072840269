export interface Handler {
  email: string;
  phone: string;
  roleType: HandlerRoleType;
}

export enum HandlerRoleType {
  ContractManagement = 'ContractManagement',
  QuotationManagement = 'QuotationManagement'
}

export type Handlers = Handler[];
