// src/app/core/state/menus/menus.state.ts

import {
  Etage,
  NatureResidence,
  QualiteOccupant,
  SaisieHabitation,
  SurfaceDependance,
  TypeHabitation
} from '../../models/form-state.model';

export interface HousingState {
  SaisieHabitation: SaisieHabitation;
  roomsValid: boolean;
}

export const initialState: HousingState = {
  SaisieHabitation: {
    NatureResidence: NatureResidence.Undefined,
    AntecedentSinistresInondables: undefined,
    AvecVeranda: undefined,
    CompositionFoyer: undefined,
    Etage: Etage.Undefined,
    InstallationDD: undefined,
    NombrePieces: 1,
    NombrePiecesSuperieures: 0,
    ObjetsValeurSuperieur2000: undefined,
    PiscineSpaJacuzzi: undefined,
    PlafondMobilier: undefined,
    QualiteOccupant: QualiteOccupant.Undefined,
    SurfaceDependance: undefined,
    TarifPreferentiel: false,
    TypeHabitation: TypeHabitation.Undefined,
    ConfirmationRFRInferieurPlafond: false,
    RFRInferieurPlafond: false
  },
  roomsValid: false
};
