<app-header [headerStep]="headerStep" [local]="true"></app-header>
<div class="mb-6">
  <ng-container *ngIf="customizeOfferVm">
    <div class="md:container md:mx-auto">
      <div *ngIf="iB_CP" class="container-OC flex">
        <div class="panelCPlusLogo-OC mx-5 my-auto flex-grow"></div>
        <div class="mr-5 my-5 text-right flex-1">
          <p class="rate-OC">{{ customizeOfferVm.rate }}</p>
          <p class="textMonth-OC">{{ resource.offercustomization.textMonth }}</p>
        </div>
      </div>

      <div *ngIf="iB_C" class="containerC-OC flex">
        <div class="panelCLogo-OC mx-5 my-auto flex-grow"></div>
        <div class="mr-5 my-5 text-right flex-1">
          <p class="rateC-OC">{{ customizeOfferVm.rate }}</p>
          <p class="textMonthC-OC">{{ resource.offercustomization.textMonth }}</p>
        </div>
      </div>

      <div *ngIf="iB_P" class="container-OC flex">
        <div *ngIf="isParis" class="panelPLogo-OC mx-5 my-auto flex-grow"></div>
        <div *ngIf="isTourcoing" class="panelTLogo-OC mx-5 my-auto flex-grow"></div>
        <div class="mr-5 my-5 text-right flex-1">
          <p class="rate-OC">{{ customizeOfferVm.rate }}</p>
          <p class="textMonth-OC">{{ resource.offercustomization.textMonth }}</p>
        </div>
      </div>

      <div *ngIf="iB_S" class="container-OC flex">
        <div class="panelSLogo-OC mx-5 my-auto flex-grow"></div>
        <div class="mr-5 my-5 text-right flex-1">
          <p class="rate-OC">{{ customizeOfferVm.rate }}</p>
          <p class="textMonth-OC">{{ resource.offercustomization.textMonth }}</p>
        </div>
      </div>
    </div>

    <div
      class="containerOption md:container md:mx-auto md:px-24 px-5 pt-8 pb-7 space-y-3"
      [ngStyle]="{ 'min-height': iB_CP ? '129px' : '89px' }">
      <div class="flex" *ngFor="let option of options">
        <p class="flex-3 textOption">{{ option.name }}</p>
        <p class="flex-1 textBOption text-right">{{ option.valueShow }}</p>
        <p class="pl-3" *ngIf="option.optional" (click)="deleteOption(option.id)">
          <span class="trash"></span>
        </p>
      </div>
    </div>
    <div class="container mx-auto">
      <app-question [question]="question" [local]="true"></app-question>

      <div *ngIf="!customizeOffer">
        <div class="my-4 space-y-5">
          <button
            pButton
            class="buttonOption p-4"
            [label]="resource.offercustomization.rate5k"
            (click)="selectValueInsured(offerCustomizationEnum.FiveK)"
            [ngClass]="setClassActive(equal5k)"></button>
          <button
            pButton
            class="mx-auto buttonOption p-4"
            [label]="customizeOfferVm.rate10K"
            (click)="selectValueInsured(offerCustomizationEnum.TenK)"
            [ngClass]="setClassActive(equal10k)"></button>
          <button
            pButton
            class="mx-auto buttonOption p-4"
            [label]="customizeOfferVm.rate20K"
            (click)="selectValueInsured(offerCustomizationEnum.TwentyK)"
            [ngClass]="setClassActive(equal20k)"></button>
        </div>

        <div class="mt-6 mb-4">
          <button
            pButton
            class="mx-auto my-4 button-NS width-max iconSideLabel"
            [label]="resource.nextstep.nextStep"
            icon="pi pi-arrow-right"
            iconPos="right"
            (click)="nextStepCO()"></button>
        </div>
      </div>

      <div *ngIf="customizeOffer" class="space-y-4 my-4">
        <div
          *ngIf="this.showBikeProtect"
          class="panelSV width-max"
          [ngClass]="setClassActive(bikeProtect)"
          (click)="
            $event.preventDefault();
            $event.stopPropagation();
            setInputSwitch(offerCustomizationEnum.BP)
          ">
          <div class="flex mx-2 my-3">
            <div class="basis-5/6 mr-1 ml-2 my-4">
              <p class="textPanelOpt">
                {{ resource.offercustomization.titleBP }}
                <app-popup-info [popUpInfo]="popupBP"></app-popup-info>
              </p>
              <p class="textBPanelOpt">
                {{ customizeOfferVm.subtitleBP }}
              </p>
            </div>
            <div class="basis-1/6 text-right">
              <p-inputSwitch
                [(ngModel)]="bikeProtect"
                (click)="
                  $event.preventDefault();
                  $event.stopPropagation();
                  updateOptionSwitch(offerCustomizationEnum.BP)
                "></p-inputSwitch>
            </div>
          </div>
        </div>
        <div
          class="panelSV width-max"
          [ngClass]="setClassActive(juridProtect)"
          (click)="
            $event.preventDefault();
            $event.stopPropagation();
            setInputSwitch(offerCustomizationEnum.JP)
          "
          *ngIf="currentFormule.PrimesOptions?.ProtectionJuridique">
          <div class="flex mx-2 my-3">
            <div class="basis-5/6 mr-1 ml-2 my-4">
              <p class="textPanelOpt">
                {{ resource.offercustomization.titleJP }}
                <app-popup-info [popUpInfo]="popupJP"></app-popup-info>
              </p>
              <p class="textBPanelOpt">
                {{ customizeOfferVm.subtitleJP }}
              </p>
            </div>
            <div class="basis-1/6 text-right">
              <p-inputSwitch
                [(ngModel)]="juridProtect"
                (click)="
                  $event.preventDefault();
                  $event.stopPropagation();
                  updateOptionSwitch(offerCustomizationEnum.JP)
                "></p-inputSwitch>
            </div>
          </div>
        </div>
        <div
          class="panelSV width-max"
          [ngClass]="setClassActive(nomadeProtect)"
          (click)="
            $event.preventDefault();
            $event.stopPropagation();
            setInputSwitch(offerCustomizationEnum.NP)
          "
          *ngIf="showNomadeProtect">
          <div class="flex mx-2 my-3">
            <div class="basis-5/6 mr-1 ml-2 my-4">
              <p class="textPanelOpt">
                {{ resource.offercustomization.titleMP }}
                <app-popup-info [popUpInfo]="popupMP"></app-popup-info>
              </p>
              <p class="textBPanelOpt">
                {{ customizeOfferVm.subtitleNP }}
              </p>
            </div>
            <div class="basis-1/6 text-right">
              <p-inputSwitch
                [(ngModel)]="nomadeProtect"
                (click)="
                  $event.preventDefault();
                  $event.stopPropagation();
                  updateOptionSwitch(offerCustomizationEnum.NP)
                "></p-inputSwitch>
            </div>
          </div>
        </div>
        <div
          class="panelSV width-max"
          [ngClass]="setClassActive(schoolInsurance)"
          (click)="
            $event.preventDefault();
            $event.stopPropagation();
            setInputSwitch(offerCustomizationEnum.SI)
          ">
          <div class="flex mx-2 my-3">
            <div class="basis-5/6 mr-1 ml-2 my-4">
              <p class="textPanelOpt">
                {{ resource.offercustomization.titleSI }}
                <span class="textDetailsPanelOpt">{{
                  resource.offercustomization.titledetailsSI
                }}</span>
              </p>
              <p class="textBPanelOpt">
                {{ customizeOfferVm.subtitleSI }}
                <app-popup-info [popUpInfo]="popupSI"></app-popup-info>
              </p>
            </div>
            <div class="basis-1/6 text-right">
              <p-inputSwitch
                [(ngModel)]="schoolInsurance"
                (click)="
                  $event.preventDefault();
                  $event.stopPropagation();
                  updateOptionSwitch(offerCustomizationEnum.SI)
                "></p-inputSwitch>
            </div>
          </div>
        </div>
        <div class="mt-6 mb-4">
          <button
            pButton
            class="mx-auto my-4 button-NS width-max iconSideLabel"
            [label]="resource.nextstep.nextStep"
            icon="pi pi-arrow-right"
            iconPos="right"
            (click)="nextStep()"
            [loading]="isLoading"></button>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="container mx-auto h-28 md:h-40">
  <app-footer class="footer"></app-footer>
</div>
<app-web-callback></app-web-callback>

<app-error-popup
  [show]="displayError"
  (cancel)="displayError = false"
  [title]="resource.error.oups"
  [text]="resource.error.api"></app-error-popup>
