import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatomoTracker } from 'ngx-matomo-client';
import { MemberService } from 'src/app/api/services/member.service';
import { AppResource } from 'src/app/app.resource';
import { State } from 'src/app/core/state/core.state';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component.component';
import { SEND_EMAIL_INIT_ESPACE_ADHERENT_PARAM } from 'src/app/shared/constants/url.constants';

@Component({
  selector: 'app-popup-init-account',
  templateUrl: './popup-init-account.component.html',
  styleUrls: ['./popup-init-account.component.scss']
})
export class PopupInitAccountComponent extends BaseComponent {
  @Input() visible: boolean = false;
  @Input() email: string = '';
  @Output() closePopup = new EventEmitter<boolean>();

  confirmMessage: boolean = false;

  constructor(
    public memberService: MemberService,
    store: Store<State>,
    resources: AppResource,
    tracker: MatomoTracker
  ) {
    super(store, resources, tracker);
  }

  closeDialog() {
    this.visible = false;
    this.closePopup.emit(true);
  }

  sendEmail() {
    this.memberService
      .sendEmailInitEspaceAdherent(this.email, SEND_EMAIL_INIT_ESPACE_ADHERENT_PARAM)
      .subscribe(() => {
        this.confirmMessage = true;
      });
  }

  backToConnection() {
    this.visible = false;
    this.closePopup.emit(true);
  }
}
