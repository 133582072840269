import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { Context } from '../models/context.model';

@Injectable({
  providedIn: 'root'
})
export class ContextService {
  public configuration = new Configuration();
  baseUrl: string;

  constructor(private http: HttpClient, configuration: Configuration) {
    this.baseUrl = configuration.apiBasePath ?? '';
  }

  public getContext(contextId: string): Observable<Context> {
    const requestUrl = `${this.baseUrl}Contexts/${contextId}`;
    return this.http.get<Context>(requestUrl);
  }

  public createContext(contextData: any): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}Contexts`, contextData);
  }
}
