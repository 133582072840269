import { SaisieSignature } from './../../models/form-state.model';
import { createAction, props } from '@ngrx/store';

export const patchSignature = createAction(
  '[Signature Page] Patch Signature info',
  props<{ payload: Partial<SaisieSignature> }>()
);

export const changeValidationStatus = createAction(
  '[Signature Page] Change Validation Status',
  props<{ isValid: boolean }>()
);

export const init = createAction('[Init]');
