// src/app/core/state/menus/menus.state.ts

import { Devis } from '../../models/form-state.model';

export interface DevisState {
  Devis: Devis;
}

export const initialState: DevisState = {
  Devis: {
    AgreementId: undefined,
    NumeroDevis: '',
    DevisId: undefined,
    DevisStatus: undefined,
    TarifId: undefined,
    PrimeMensuelle: undefined,
    PrimeAnnuelle: undefined,
    DocumentDevis: undefined,
    DocumentFIC: undefined,
    DocumentInfo: undefined,
    DocumentCG: undefined,
    GDAPositif: false,
    PPEPositif: false,
    ClientAdvisorFullName: ''
  }
};
