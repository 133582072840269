<p-dialog
  [modal]="true"
  [(visible)]="visible"
  [style]="{ width: '30rem' }"
  [draggable]="false"
  [closable]="false">
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-center gap-2 ml-auto mr-auto">
      <span class="font-bold white-space-nowrap mt-7 text-xl">
        {{ resource.loginPopup.title }}
      </span>
    </div>
    <div>
      <span class="cursor-pointer" (click)="closeDialog()"><i class="pi pi-times"></i></span>
    </div>
  </ng-template>
  <p>{{ resource.loginPopup.text1 }} {{ subscriberEmail }} {{ resource.loginPopup.text2 }}</p>
  <form [formGroup]="loginForm" class="p-4">
    <div class="email-form">
      <label for="email">
        {{ resource.loginPopup.labelEmail }} <span class="text-red-600">*</span></label
      >
      <input pInputText id="email" formControlName="email" [disabled]="true" />
    </div>
    <div class="password-form mt-5 flex flex-col">
      <label for="password">
        {{ resource.loginPopup.labelPassword }} <span class="text-red-600">*</span></label
      >
      <p-password
        id="password"
        class="w-full"
        formControlName="password"
        [toggleMask]="true"
        [feedback]="false"
        (onFocus)="onPasswordFocus()"></p-password>
      <small class="p-error" *ngIf="errorLoginMessage">{{ resource.error.errorLoginPopup }}</small>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <div class="flex justify-center">
      <button
        *ngIf="!resetPasswordBool"
        pButton
        label="Mot de passe oublié"
        class="bg-purple border-none p-3 mr-1 text-white"
        (click)="resetPassword()"></button>
      <p
        *ngIf="resetPasswordBool"
        class="text-green-500 p-3 w-2/4 justify-center flex items-center">
        {{ resource.loginPopup.passwordReset }}<i class="pi pi-check ml-1"></i>
      </p>
      <button
        pButton
        label="Connexion"
        class="bg-pink border-none text-white"
        (click)="login()"
        [disabled]="!loginForm.get('password')?.value"></button>
    </div>
  </ng-template>
</p-dialog>
<app-error-toast
  [summaryMessage]="summaryMessage"
  [detailMessage]="detailMessage"></app-error-toast>

<app-strong-authentication
  *ngIf="authPopup === true"
  [visible]="authPopup"
  (closeDialogEmitter)="closeDialogStrongAuth()"
  (closeValidMfa)="closeDialogValidMfa($event)"></app-strong-authentication>
