//#region Import Component
import { HeaderComponent } from 'src/app/shared/components/header/header.component';
import { InfoTopComponent } from 'src/app/shared/components/info-top/info-top.component';
import { QuestionComponent } from 'src/app/shared/components/question/question.component';
import { ResumeStepComponent } from 'src/app/shared/components/resume-step/resume-step.component';
import { WebCallBackComponent } from 'src/app/shared/components/web-callback/web-callback.component';
import { ErrorPopupComponent } from '../components/error-popup/error-popup.component';
import { InsuranceBenefitComponent } from '../components/insurance-benefit/insurance-benefit.component';
import { LayoutComponent } from '../components/layout/layout.component';
import { PhoneAndPrefixComponent } from '../components/phone-and-prefix/phone-and-prefix.component';
import { PopupInfoComponent } from '../components/popup-info/popup-info.component';
import { RedirectComponent } from '../components/redirection/redirect/redirect.component';
import { WebCallbackPopupComponent } from '../components/web-callback-popup/web-callback-popup.component';
import { FooterComponent } from './../components/footer/footer.component';
//#endregion

//#region Import Angular Module
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
//#endregion

//#region Import PrimeNG Module
import { MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PasswordModule } from 'primeng/password';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
//#endregion

// pdf.js
import { PdfViewerModule } from 'ng2-pdf-viewer';

//input otp
import { NgOtpInputModule } from 'ng-otp-input';

// loading spinner
import { MSAL_INSTANCE, MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ErrorToastComponent } from '../components/error-toast/error-toast.component';
import { MSALInstanceFactory } from '../config/msal-config';

@NgModule({
  declarations: [
    HeaderComponent,
    InfoTopComponent,
    QuestionComponent,
    ResumeStepComponent,
    WebCallBackComponent,
    PopupInfoComponent,
    RedirectComponent,
    InsuranceBenefitComponent,
    LayoutComponent,
    WebCallbackPopupComponent,
    FooterComponent,
    ErrorPopupComponent,
    PhoneAndPrefixComponent,
    ErrorToastComponent
  ],
  exports: [
    //#region Component
    HeaderComponent,
    InfoTopComponent,
    QuestionComponent,
    ResumeStepComponent,
    WebCallBackComponent,
    RedirectComponent,
    PopupInfoComponent,
    InsuranceBenefitComponent,
    LayoutComponent,
    WebCallbackPopupComponent,
    FooterComponent,
    ErrorPopupComponent,
    PhoneAndPrefixComponent,
    ErrorToastComponent,
    //#endregion

    //#region Module
    RouterModule,
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    AutoCompleteModule,
    InputTextModule,
    InputNumberModule,
    ScrollPanelModule,
    CheckboxModule,
    CalendarModule,
    TooltipModule,
    DialogModule,
    InputMaskModule,
    InputSwitchModule,
    ButtonModule,
    OverlayPanelModule,
    KeyFilterModule,
    PdfViewerModule,
    NgxSpinnerModule,
    RadioButtonModule,
    PasswordModule,
    NgOtpInputModule,
    ToastModule
  ],
  imports: [
    RouterModule,
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    AutoCompleteModule,
    InputNumberModule,
    ScrollPanelModule,
    CheckboxModule,
    CalendarModule,
    TooltipModule,
    DialogModule,
    InputMaskModule,
    InputSwitchModule,
    ButtonModule,
    OverlayPanelModule,
    KeyFilterModule,
    PdfViewerModule,
    NgxSpinnerModule,
    RadioButtonModule,
    PasswordModule,
    NgOtpInputModule,
    ToastModule
    //#endregion
  ],
  providers: [
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    provideHttpClient(withInterceptorsFromDi()),
    MsalService,
    MsalBroadcastService,
    MessageService
  ]
})
export class SharedModule {}
