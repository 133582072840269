<app-header [headerStep]="headerStep" [local]="true"></app-header>
<div class="container mx-auto mb-6">
  <app-question [question]="question" [local]="true"></app-question>
  <div class="my-6">
    <div class="flex mb-4">
      <p class="text-LC">{{ resource.lessorcertificate.titleLessor }}</p>
    </div>
    <div class="md:grid md:grid-cols-2 md:gap-4">
      <div class="flex mb-4">
        <button
          pButton
          class="mx-auto buttonToggleSM"
          [label]="resource.lessorcertificate.social"
          [ngClass]="setClassActive(social)"
          (click)="nextStepType(lessorCertificatEnum.Social)"></button>
      </div>
      <div class="flex my-4 md:my-0">
        <button
          pButton
          class="mx-auto buttonToggleSM"
          [label]="resource.lessorcertificate.private"
          [ngClass]="setClassActive(private)"
          (click)="nextStepType(lessorCertificatEnum.Private)"></button>
      </div>
    </div>
    <div *ngIf="private" [formGroup]="privateForm">
      <div class="md:grid md:grid-cols-2 md:gap-4">
        <div class="my-4 md:my-0 md:col-span-2">
          <span class="p-float-label mb-2">
            <input type="text" pInputText formControlName="email" (keyup)="ToLowerCase($event)" />
            <label for="float-input" class="inputtext">{{
              resource.lessorcertificate.emailLessor
            }}</label>
          </span>
          <small
            class="p-error"
            *ngIf="email?.touched && email?.invalid && (email?.errors)!['required']"
            >{{ resource.error.email }}</small
          >
          <small
            class="p-error"
            *ngIf="email?.touched && email?.invalid && (email?.errors)!['pattern']"
            >{{ resource.error.emailformat }}</small
          >
          <small class="textInfoBottom"
            ><p>{{ resource.lessorcertificate.infoEmail }}</p></small
          >
        </div>
      </div>
      <div class="mt-8">
        <button
          pButton
          [disabled]="!this.privateForm.valid"
          class="mx-auto my-4 button-NS width-max iconSideLabel"
          [label]="resource.nextstep.nextStep"
          icon="pi pi-arrow-right"
          iconPos="right"
          (click)="nextStep(resource.lessorcertificate.private)"></button>
      </div>
    </div>
    <div *ngIf="social" [formGroup]="socialForm">
      <div class="md:grid md:grid-cols-2 md:gap-4">
        <div class="my-4 md:my-0 md:col-span-2">
          <span class="p-float-label">
            <p-dropdown
              [options]="lessorList"
              [(ngModel)]="selectedLessor"
              (onChange)="onChangeLessor($event)"
              formControlName="lessor"
              optionLabel="text"
              [emptyFilterMessage]="resource.form.noResultFilterLessor"
              [placeholder]="resource.lessorcertificate.nameLessor"
              [filter]="true"
              [resetFilterOnHide]="true">
            </p-dropdown>
            <label for="float-input" class="dropdown">{{
              resource.lessorcertificate.nameLessor
            }}</label>
          </span>
          <small
            class="p-error"
            *ngIf="lessor?.touched && lessor?.invalid && (lessor?.errors)!['required']"
            >{{ resource.error.lessor }}</small
          >
          <p
            class="cursor-pointer underline"
            *ngIf="lessorNotFind"
            (click)="onNotFoundLessorClick()">
            {{ resource.lessorcertificate.notFind }}
          </p>
        </div>
        <div *ngIf="otherLessor" class="md:col-span-2 space-y-4">
          <div>
            <span class="p-float-label">
              <input type="text" pInputText formControlName="otherName" />
              <label for="float-input" class="inputtext">{{
                resource.lessorcertificate.otherName
              }}</label>
            </span>
            <small
              class="p-error"
              *ngIf="otherName?.touched && otherName?.invalid && (otherName?.errors)!['required']"
              >{{ resource.error.lessor }}</small
            >
          </div>
          <div>
            <span class="p-float-label mb-2">
              <input
                type="text"
                pInputText
                formControlName="otherEmail"
                (keyup)="ToLowerCase($event)" />
              <label for="float-input" class="inputtext">{{
                resource.lessorcertificate.otherEmail
              }}</label>
            </span>
            <small
              class="p-error"
              *ngIf="
                otherEmail?.touched && otherEmail?.invalid && (otherEmail?.errors)!['required']
              "
              >{{ resource.error.email }}</small
            >
            <small
              class="p-error"
              *ngIf="otherEmail?.touched && otherEmail?.invalid && (otherEmail?.errors)!['pattern']"
              >{{ resource.error.emailformat }}</small
            >
            <small class="textInfoBottom"
              ><p>{{ resource.lessorcertificate.infoEmail }}</p></small
            >
          </div>
        </div>
      </div>
      <div *ngIf="step1Social">
        <div class="flex mt-8 mb-6">
          <p class="text-LC">{{ resource.lessorcertificate.alreadyOccupied }}</p>
        </div>
        <div class="md:grid md:grid-cols-2 md:gap-4">
          <div class="flex my-4 md:my-0">
            <button
              pButton
              class="mx-auto buttonToggleSM"
              [label]="resource.form.yes"
              [ngClass]="setClassActive(alreadyOccupied)"
              (click)="nextStepSocial(lessorCertificatEnum.Yes)"></button>
          </div>
          <div class="flex my-4 md:my-0">
            <button
              pButton
              class="mx-auto buttonToggleSM"
              [label]="resource.form.no"
              [ngClass]="setClassActive(notAlreadyOccupied)"
              (click)="nextStepSocial(lessorCertificatEnum.No)"></button>
          </div>
        </div>
      </div>
      <div *ngIf="step2Social">
        <div class="md:grid md:grid-cols-2 md:gap-4">
          <div>
            <div class="flex mt-8 mb-6">
              <p class="text-LC">{{ placeholderRefClient }}</p>
              <app-popup-info [popUpInfo]="numberLocalInfo" class="popup-LC"></app-popup-info>
            </div>
            <div class="my-4">
              <span class="p-float-label">
                <input
                  type="text"
                  formControlName="principalLocal"
                  [placeholder]="resource.lessorcertificate.phPrincipalLocal"
                  pInputText
                  class="inputtext-placeholder" />
                <label for="float-input" class="inputtext">{{ placeholderRefClient }}</label>
              </span>
              <small
                class="p-error"
                *ngIf="
                  principalLocal?.touched &&
                  principalLocal?.invalid &&
                  (principalLocal?.errors)!['required']
                "
                >{{ errorRefClient }}</small
              >
            </div>
          </div>
          <div>
            <div class="flex mt-8 mb-6">
              <p class="text-LC">{{ placeholderCC }}</p>
              <app-popup-info [popUpInfo]="clientAccountInfo" class="popup-LC"></app-popup-info>
            </div>
            <div class="my-4">
              <span class="p-float-label">
                <input
                  type="text"
                  formControlName="clientAccount"
                  [placeholder]="resource.lessorcertificate.phClientAccount"
                  pInputText
                  class="inputtext-placeholder" />
                <label for="float-input" class="inputtext">{{ placeholderCC }}</label>
              </span>
              <small
                class="p-error"
                *ngIf="
                  clientAccount?.touched &&
                  clientAccount?.invalid &&
                  (clientAccount?.errors)!['required']
                "
                >{{ errorCC }}</small
              >
            </div>
          </div>
        </div>
      </div>
      <div class="mt-8">
        <button
          *ngIf="social && (step2Social || otherLessor)"
          pButton
          [disabled]="!this.socialForm.valid"
          class="mx-auto my-4 button-NS width-max iconSideLabel"
          [label]="resource.nextstep.nextStep"
          icon="pi pi-arrow-right"
          iconPos="right"
          (click)="nextStep(resource.lessorcertificate.social)"></button>
      </div>
    </div>
    <div class="flex justify-center">{{ resource.lessorcertificate.or }}</div>

    <div class="my-4">
      <button
        pButton
        class="mx-auto button-NS width-max iconSideLabel"
        [label]="skip"
        icon="pi pi-arrow-right"
        iconPos="right"
        (click)="skipStep()"></button>
    </div>
  </div>
</div>

<div class="container mx-auto h-28 md:h-40">
  <app-footer class="footer"></app-footer>
</div>
<app-web-callback></app-web-callback>

<p-dialog
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '330px', height: '350px', margin: '1rem' }"
  [closable]="false"
  [showHeader]="false"
  [baseZIndex]="10000">
  <div class="mt-8">
    <p class="title-P my-5">{{ resource.lessorcertificate.titleModal }}</p>
    <p class="text-P my-5">{{ resource.lessorcertificate.textModal1 }}</p>
    <p class="text-P my-5">{{ resource.lessorcertificate.textModal2 }}</p>
    <div class="flex justify-center mt-10">
      <button
        pButton
        type="button"
        class="button-NS w-full"
        [label]="resource.lessorcertificate.confirmModal"
        (click)="confirmModal()"></button>
    </div>
  </div>
</p-dialog>
