import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { LessorList } from '../models/lessor.model';

@Injectable({
  providedIn: 'root'
})
export class LessorService {
  public configuration = new Configuration();
  baseUrl: string;

  constructor(private http: HttpClient, configuration: Configuration) {
    this.baseUrl = configuration.apiBasePath ?? '';
  }

  public getLessors(productCode: string): Observable<LessorList> {
    const requestUrl = `${this.baseUrl}lessors`;
    let params = new HttpParams().set('productCode', productCode);
    return this.http.get<LessorList>(requestUrl, { params });
  }

  public GetLessorEmailByName(lessorName: string): Observable<string> {
    const requestUrl = `${this.baseUrl}lessors/${lessorName}/email`;
    return this.http.get<string>(requestUrl);
  }

  public GetZipCodeListByLessor(lessorId: number): Observable<string[]> {
    const requestUrl = `${this.baseUrl}lessors/${lessorId}/zipCode`;
    return this.http.get<string[]>(requestUrl);
  }

  public GetCitiesListFromZipCode(lessorId: number, zipCode: string): Observable<string[]> {
    const requestUrl = `${this.baseUrl}lessors/${lessorId}/cities?zipCode=${zipCode}`;
    return this.http.get<string[]>(requestUrl);
  }

  public GetAddressesListFromCity(
    lessorId: number,
    zipCode: string,
    city: string
  ): Observable<string[]> {
    const requestUrl = `${this.baseUrl}lessors/${lessorId}/addresses?zipCode=${zipCode}&city=${city}`;
    return this.http.get<string[]>(requestUrl);
  }
}
