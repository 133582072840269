import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TarificationState } from './tarifications.state';

export const selectTarification = createFeatureSelector<TarificationState>('tarification');

export const selectTarificationState = createSelector(
  selectTarification,
  (state: TarificationState) => state.Tarification
);

export const selectTarificationFormules = createSelector(
  selectTarification,
  (state: TarificationState) => state.Tarification.Formules
);

export const selectTarificationPromotion = createSelector(
  selectTarification,
  (state: TarificationState) => state.Tarification.Promotion
);

export const selectTarificationTarifId = createSelector(
  selectTarification,
  (state: TarificationState) => state.Tarification.TarifId
);
