<img
  [src]="imgSrc"
  class="popupPosition"
  (click)="$event.preventDefault(); $event.stopPropagation(); op.toggle($event)" />

<p-overlayPanel
  styleClass="containerPopUpInfo mx-5 md:-ml-10"
  (onShow)="showHide()"
  (onHide)="showHide()"
  #op>
  <ng-template pTemplate>
    <div *ngIf="popUpInfo" class="mx-2 my-2">
      <div class="my-2">
        <p class="text-P whitespace-pre-line" [innerHTML]="popUpInfo.textOne"></p>
      </div>
      <div class="my-2" *ngIf="popUpInfo.textTwo">
        <p class="text-Pmin">{{ popUpInfo.textTwo }}</p>
      </div>
      <div *ngIf="popUpInfo.imgPath" class="my-2">
        <img [src]="popUpInfo.imgPath" alt="image" />
      </div>
      <div *ngIf="popUpInfo.textThree" class="my-2">
        <p class="text-P">{{ popUpInfo.textThree }}</p>
      </div>
      <div *ngIf="popUpInfo.textFour" class="my-2">
        <p class="text-P">{{ popUpInfo.textFour }}</p>
      </div>
    </div>
    <div *ngIf="popUpInfoHA">
      <div class="mx-2 my-2">
        <div class="my-2">
          <p class="text-P">
            <b>{{ popUpInfoHA.textOne }}</b> : {{ popUpInfoHA.textTwo }}
          </p>
        </div>
        <div class="my-2">
          <p class="text-P">
            <b>{{ popUpInfoHA.textThree }}</b> : {{ popUpInfoHA.textFour }}
          </p>
        </div>
        <div class="my-2">
          <p class="text-P">
            <b>{{ popUpInfoHA.textFive }}</b> : {{ popUpInfoHA.textSix }}
          </p>
        </div>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>
