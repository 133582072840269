/**
 * VyV Conseil MRH Public API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { VyVContractIBAN } from './vyVContractIBAN';

/**
 * Informations de paiement
 */
export interface VyVContractSaisiePaiement {
  EcheancePrelevement: VyVContractSaisiePaiement.EcheancePrelevementEnum;
  DateDemarrage: string;
  JourPrelevement: VyVContractSaisiePaiement.JourPrelevementEnum;
  IBAN: VyVContractIBAN;
  Telephone: string;
  TitulaireCompte: boolean;
}
export namespace VyVContractSaisiePaiement {
  export type EcheancePrelevementEnum = 'Mensuel' | 'Trimestriel' | 'Annuel';
  export const EcheancePrelevementEnum = {
    Mensuel: 'Mensuel' as EcheancePrelevementEnum,
    Trimestriel: 'Trimestriel' as EcheancePrelevementEnum,
    Annuel: 'Annuel' as EcheancePrelevementEnum
  };
  export type JourPrelevementEnum = 'Le5DuMois' | 'Le10DuMois' | 'Le15DuMois';
  export const JourPrelevementEnum = {
    Le5DuMois: 'Le5DuMois' as JourPrelevementEnum,
    Le10DuMois: 'Le10DuMois' as JourPrelevementEnum,
    Le15DuMois: 'Le15DuMois' as JourPrelevementEnum
  };
}
