<p-dialog
  [modal]="true"
  [(visible)]="visible"
  [style]="{ width: '30rem' }"
  [draggable]="false"
  [closable]="false">
  <ng-template pTemplate="header">
    <div>
      <span class="cursor-pointer" (click)="closeDialog()"><i class="pi pi-times"></i></span>
    </div>
  </ng-template>
  <p class="font-bold text-center">
    {{ resource.initaccount.text1 }} {{ email }} {{ resource.initaccount.text2 }}
  </p>
  <p *ngIf="!confirmMessage" class="font-bold text-center mt-3">
    {{ resource.initaccount.initMessage }}
  </p>
  <p *ngIf="confirmMessage" class="font-bold text-center mt-3">
    {{ resource.initaccount.confirmMessage }}
  </p>
  <ng-template pTemplate="footer">
    <div class="flex justify-center" *ngIf="!confirmMessage">
      <button
        pButton
        label="Renvoyer un email"
        class="bg-pink border-none p-3 text-white"
        (click)="sendEmail()"></button>
    </div>
    <div class="flex justify-center" *ngIf="confirmMessage">
      <button
        pButton
        label="Revenir à l'étape de connexion"
        class="bg-pink border-none p-3 text-white"
        (click)="backToConnection()"></button>
    </div>
  </ng-template>
</p-dialog>
