export const AUTH_CALLBACK_PATH = 'callback';
export const ROOT_PATH = '/';
export const E_SIGN_MSG_ADVISOR_PATH = 'electronicsignature-advisor';
export const PRODUCT_PATH = 'product/:productCode';
export const CONTEXT_PATH = 'context/:id';
export const CONFIRM_CONTRACT_PATH = 'confirmcontract';
export const SIGNATURE_STATUS_PATH = 'signature/status/:status';
export const ELECTRONIC_SIGNATURE_PATH = 'electronicsignature';
export const PAYMENT_PATH = 'payment';
export const CANCELLATION_PATH = 'cancellation';
export const LESSOR_CERTIFICATE_PATH = 'lessorcertificate';
export const SERVICES_PATH = 'services';
export const CONFIRM_QUOTE_PATH = 'confirmquote';
export const SCHOOL_INSURANCE_PATH = 'schoolinsurance';
export const OFFER_CUSTOMIZARION_PATH = 'offercustomization';
export const RATE_PATH = 'rate';
export const INSURED_VALUE_PATH = 'insuredvalue';
export const PARTICULARITY_PATH = 'particularity';
export const SOLIDARITY_ADDRESS_PATH = 'solidarityaddress';
export const START_CONTEXT_PATH = 'start/:context';
export const START_CONTEXT_PRODUCT_PATH = 'start/:context/:product';
export const NUMBER_PIECES_PATH = 'numberpieces';
export const HOUSE_APARTMENT_PATH = 'houseapartment';
export const START_PATH = '/start';

export const ADDRESS_PATH = 'address';
export const OWNER_TENANT_PATH = 'ownertenant';
export const PRIMARY_SECONDARY_PATH = 'primarysecondary';
export const PREFERENTIAL_RATE_PATH = 'preferentialrate';
export const PREVIOUS_CLAIM_PATH = 'previousclaim';
export const DEPENDENCY_PATH = 'dependency';
export const SUBSCRIBER_PATH = 'subscriber';
