//#region Import Module
import { NgModule } from '@angular/core';
import { SharedModule } from './shared.module';
//#endregion

//#region Devis
import { OfferCustomizationComponent } from 'src/app/views/devis/offer-customization/offer-customization.component';
import { QuoteConfirmationComponent } from 'src/app/views/devis/quote-confirmation/quote-confirmation.component';
import { RateComponent } from 'src/app/views/devis/rate/rate.component';
import { SchoolInsuranceComponent } from 'src/app/views/devis/school-insurance/school-insurance.component';
import { SubscriberConsentComponent } from 'src/app/views/devis/subscriber/consent/subscriber-consent.component';
import { LoginPopupComponent } from 'src/app/views/devis/subscriber/login-popup/login-popup.component';
import { StrongAuthenticationComponent } from 'src/app/views/devis/subscriber/login-popup/strong-authentication/strong-authentication.component';
import { PopupInitAccountComponent } from 'src/app/views/devis/subscriber/popup-init-account/popup-init-account.component';
import { SubscriberComponent } from 'src/app/views/devis/subscriber/subscriber.component';
//#endregion

@NgModule({
  declarations: [
    SubscriberComponent,
    SubscriberConsentComponent,
    RateComponent,
    OfferCustomizationComponent,
    SchoolInsuranceComponent,
    QuoteConfirmationComponent,
    LoginPopupComponent,
    StrongAuthenticationComponent,
    PopupInitAccountComponent
  ],
  imports: [SharedModule],
  exports: [
    SubscriberComponent,
    SubscriberConsentComponent,
    RateComponent,
    OfferCustomizationComponent,
    SchoolInsuranceComponent,
    QuoteConfirmationComponent,
    LoginPopupComponent,
    StrongAuthenticationComponent,
    PopupInitAccountComponent
  ]
})
export class DevisModule {}
