import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { Policies } from '../models/policies.model';
import { PolicyDocument } from '../models/policy-document.model';
import { PolicyOverviewResponse } from '../models/policyOverviewResponse.model';
import { PolicySummaryResponse } from '../models/policySummaryResponse.model';

@Injectable({
  providedIn: 'root'
})
export class PoliciesService {
  public configuration = new Configuration();
  baseUrl: string;
  apiEndpoint: string = 'policies';

  constructor(private http: HttpClient, configuration: Configuration) {
    this.baseUrl = configuration.apiBasePath ?? '';
  }

  public createPolicy(body: Policies) {
    return this.http.post(`${this.baseUrl}${this.apiEndpoint}`, body);
  }

  public getPolicyDocument(id: Guid) {
    return this.http.get<PolicyDocument>(`${this.baseUrl}${this.apiEndpoint}/${id}/documents`);
  }

  public startSignature(id: Guid, phonePrefix: string, phoneNumber: string) {
    const body = {
      agreementId: id,
      phonePrefix: phonePrefix,
      phoneNumber: phoneNumber
    };
    return this.http.post(`${this.baseUrl}${this.apiEndpoint}/signature:start`, body);
  }

  public completeSignature(id: Guid, signatureNumber: string, pinCode: string) {
    const body = {
      agreementId: id,
      signatureProcessNumber: signatureNumber,
      pinCode: pinCode
    };
    return this.http.post(`${this.baseUrl}${this.apiEndpoint}/signature:complete`, body);
  }

  public resendSmsPinCode(id: Guid, signatureNumber: string) {
    const body = {
      agreementId: id,
      signatureProcessNumber: signatureNumber
    };
    return this.http.post(`${this.baseUrl}${this.apiEndpoint}/signature:resendSms`, body);
  }

  public GetPolicySummary(
    agreementId: Guid,
    context: Guid,
    email: string
  ): Observable<PolicySummaryResponse> {
    let filter = new HttpParams();

    if (context) {
      filter = filter.append('contextId', context.toString());
    }

    if (email) {
      filter = filter.append('email', email);
    }
    return this.http.get<PolicySummaryResponse>(
      `${this.baseUrl}${this.apiEndpoint}/${agreementId}/summary`,
      {
        params: filter
      }
    );
  }

  public GetPolicyOverview(
    policyReference: string,
    context: Guid,
    email: string
  ): Observable<PolicyOverviewResponse> {
    let filter = new HttpParams();

    if (context) {
      filter = filter.append('contextId', context.toString());
    }

    if (email) {
      filter = filter.append('email', email);
    }
    return this.http.get<PolicyOverviewResponse>(
      `${this.baseUrl}${this.apiEndpoint}/${policyReference}/overview`,
      {
        params: filter
      }
    );
  }
}
