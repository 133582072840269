export enum PolicyState {
  Wait4Sign = 1,
  Signed = 2,
  Na = 3,
  Ongoing = 4,
  Unpaid = 5,
  AutoTerm = 6,
  Suspended = 7,
  Terminated = 8,
  Replaced = 9,
  Cancelled = 10
}
