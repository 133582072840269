<div class="container mx-auto mb-6">
  <div
    class="grid gap-x-4 grid-cols-1 md:grid-cols-2"
    [formGroup]="addressForm"
    *ngIf="addressForm">
    <div class="w-full">
      <p-autoComplete
        class="my-2.5 grid w-full"
        [suggestions]="filteredLessors"
        [placeholder]="resource.form.lessor"
        [forceSelection]="true"
        (completeMethod)="filterLessors($event)"
        [dropdown]="true"
        formControlName="lessor"></p-autoComplete>
      <small
        class="p-error"
        *ngIf="lessor?.dirty && lessor?.invalid && (lessor?.errors)!['required']"
        >{{ resource.error.lessor }}</small
      >
      <p class="cursor-pointer underline" *ngIf="lessorNotFind" (click)="onRedirectAHCClick()">
        {{ resource.address.notFindOther }}
      </p>
    </div>
    <div class="w-full my-2.5">
      <p-dropdown
        class="width-max"
        [options]="countries"
        [(ngModel)]="selectedCountry"
        optionLabel="text"
        [emptyFilterMessage]="resource.form.noResultFilter"
        [placeholder]="resource.form.country"
        [disabled]="true"
        formControlName="country"></p-dropdown>
      <small
        id="username2-help"
        class="p-error"
        *ngIf="country?.dirty && country?.invalid && country?.hasError('required')"
        >{{ resource.error.country }}</small
      >
    </div>
    <div class="w-full">
      <p-autoComplete
        class="my-2.5 grid w-full"
        [suggestions]="filteredPostalCode"
        [placeholder]="resource.form.postalCode"
        [forceSelection]="true"
        [virtualScroll]="true"
        [itemSize]="50"
        [virtualScrollItemSize]="50"
        (completeMethod)="filterPostalCode($event)"
        [dropdown]="true"
        formControlName="postalCode"></p-autoComplete>
      <small
        class="p-error"
        *ngIf="postalCode?.dirty && postalCode?.invalid && (postalCode?.errors)!['required']"
        >{{ resource.error.postalCode }}</small
      >
    </div>
    <div class="w-full">
      <p-autoComplete
        class="my-2.5 grid w-full"
        [suggestions]="filteredCities"
        [placeholder]="resource.form.city"
        [forceSelection]="true"
        (completeMethod)="filterCities($event)"
        [dropdown]="true"
        formControlName="city"></p-autoComplete>
      <small class="p-error" *ngIf="city?.dirty && city?.invalid && (city?.errors)!['required']">{{
        resource.error.city
      }}</small>
    </div>
    <div class="w-full md:col-span-2">
      <p-autoComplete
        class="my-2.5 grid w-full"
        [suggestions]="filteredAddresses"
        [placeholder]="resource.form.address"
        [forceSelection]="true"
        [virtualScroll]="true"
        [itemSize]="50"
        [virtualScrollItemSize]="50"
        (completeMethod)="filterAddresses($event)"
        [dropdown]="true"
        formControlName="address"></p-autoComplete>
      <small
        class="p-error"
        *ngIf="address?.dirty && address?.invalid && (address?.errors)!['required']"
        >{{ resource.error.address }}</small
      >
      <p class="cursor-pointer underline" *ngIf="addressNotFind" (click)="onPopUpClick()">
        {{ resource.address.notFind }}
      </p>
    </div>
  </div>
  <div class="my-5">
    <button
      pButton
      [disabled]="!formOK"
      class="mx-auto button-NS width-max iconSideLabel"
      [label]="resource.nextstep.validate"
      icon="pi pi-arrow-right"
      iconPos="right"
      (click)="nextStep()"></button>
  </div>
</div>

<p-dialog
  [(visible)]="show"
  [modal]="true"
  [style]="{ width: '330px', height: '490px', margin: '1rem' }"
  [closable]="false"
  [showHeader]="false"
  [baseZIndex]="10000">
  <p class="title-P my-5">{{ resource.address.addressNotFound }}</p>
  <p class="text-P my-5">
    {{ resource.address.contactUs }}
  </p>
  <div>
    <div class="infoModal-WCB mb-8 px-5 py-9 border-none">
      <p class="title-WCB px-2">
        {{ resource.webcallback.title }} <b>{{ resource.webcallback.titleB }}</b>
      </p>
      <div class="mx-4 my-5">
        <a class="button-CB flex" [href]="'tel:' + resource.webcallback.phoneAHS">
          <img class="my-auto ml-6 mr-1" [src]="resource.webcallback.logo" />
          <span class="titleButton-CB my-auto ml-3 mr-2">{{ resource.webcallback.phoneAHS }}</span>
        </a>
      </div>
      <p class="title-WCB px-10">
        {{ resource.webcallback.subtitle }}
        <b> {{ resource.address.subtitleB }}</b>
      </p>
    </div>
  </div>

  <div class="flex justify-center mt-2">
    <button
      pButton
      type="button"
      class="button-C w-full"
      [label]="resource.nextstep.cancel"
      (click)="onCancel()"></button>
  </div>
</p-dialog>
