export const tourcoingPostalCode = [
  '59810',
  '59170',
  '59491',
  '59155',
  '59262',
  '59273',
  '59175',
  '59000',
  '59152',
  '59830',
  '59790',
  '59700',
  '59100',
  '59510',
  '59139',
  '59260',
  '59390',
  '59780',
  '59120',
  '59370',
  '59113',
  '59110',
  '59263',
  '59211',
  '59150',
  '59420',
  '59520',
  '59160',
  '59112',
  '59251',
  '59350',
  '59136',
  '59115',
  '59185',
  '59272',
  '59910',
  '59320',
  '59184',
  '59496',
  '59118',
  '59221',
  '59960',
  '59134',
  '59274',
  '59250',
  '59223',
  '59126',
  '59237',
  '59890',
  '59166',
  '59200',
  '59480',
  '59840',
  '59236',
  '59117',
  '59116',
  '59560',
  '59249',
  '59130',
  '59930',
  '59193',
  '59280',
  '59290'
];
