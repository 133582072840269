import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Injectable } from '@angular/core';
import { MemberService } from 'src/app/api/services/member.service';
import { Observable, map} from 'rxjs';

@Injectable({
    providedIn: 'root'
  })

export class MemberEmailValidator{
    constructor(private memberService: MemberService){}

    public validate(control: AbstractControl): Observable<ValidationErrors | null> {
        const email = control.value;

        return this.memberService.emailIsValid(email).pipe(
            map(isValid => isValid ? null : { pattern: 'Invalid email' })
          );
    }
}
