import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MatomoTracker } from 'ngx-matomo-client';
import { lastValueFrom, take } from 'rxjs';
import { ConsentService } from 'src/app/api/services/consent.service';
import { AppResource } from 'src/app/app.resource';
import { Consentement } from 'src/app/core/models/consent.model';
import { State } from 'src/app/core/state/core.state';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component.component';
import * as fromSubscriber from '../../../../core/state/subscriber';

@Component({
  selector: 'app-subscriber-consent',
  templateUrl: './subscriber-consent.component.html',
  styleUrls: ['./subscriber-consent.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubscriberConsentComponent extends BaseComponent implements OnInit {
  @Output() consentChange = new EventEmitter<Consentement>();

  subscriberConsentForm!: FormGroup;
  selectedCheck: any = null;
  consentement!: Consentement;
  constructor(
    store: Store<State>,
    resources: AppResource,
    tracker: MatomoTracker,
    private consentService: ConsentService
  ) {
    super(store, resources, tracker);
  }

  override async ngOnInit() {
    await this.loadConsent();
    this.initForm();
  }

  async loadConsent() {
    var consent = await lastValueFrom(this.consentService.GetConsentement().pipe(take(1)));
    this.consentement = {
      idLibelle: consent?.idLibelle,
      libelle: consent?.libelle,
      voies: consent?.voies
    };
  }

  public checkboxValues() {
    this.consentChange.emit(this.consentement);
    this.store.dispatch(
      fromSubscriber.patchSubscriber({
        payload: {
          Consentement: {
            IdLibelle: this.consentement.idLibelle,
            VoiesConsentement: this.consentement?.voies.map(canal => ({
              Id: canal.id,
              Libelle: canal.libelle,
              IsCheck: canal.isCheck
            }))
          }
        }
      })
    );
  }

  private initForm() {
    this.subscriberConsentForm = new FormGroup({});
  }
}
