<app-header></app-header>
<app-resume-step></app-resume-step>
<div class="container mx-auto mb-6">
  <app-question [question]="questionOne" [popupInfo]="popupInfoOne" [local]="true"></app-question>
  <div class="mb-8 space-y-4 md:grid md:gap-x-4 md:space-y-0 md:grid-cols-2">
    <button
      pButton
      class="buttonToggleSM"
      [label]="resource.form.yes"
      (click)="selectValueO(genericEnum.Yes)"
      [ngClass]="setClassActive(yesO)"></button>
    <button
      pButton
      class="buttonToggleSM"
      [label]="resource.form.no"
      (click)="selectValueO(genericEnum.No)"
      [ngClass]="setClassActive(noO)"></button>
  </div>
  <app-question [question]="questionTwo" [popupInfo]="popupInfoTwo" [local]="true"></app-question>
  <div class="space-y-4 md:grid md:gap-x-4 md:space-y-0 md:grid-cols-2">
    <button
      pButton
      class="buttonToggleSM"
      [label]="resource.form.yes"
      (click)="selectValueT(genericEnum.Yes)"
      [ngClass]="setClassActive(yesT)"></button>
    <button
      pButton
      class="buttonToggleSM"
      [label]="resource.form.no"
      (click)="selectValueT(genericEnum.No)"
      [ngClass]="setClassActive(noT)"></button>
  </div>
</div>

<div class="container mx-auto h-28 md:h-40">
  <app-footer class="footer"></app-footer>
</div>
<app-web-callback></app-web-callback>
