import { String } from 'typescript-string-operations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppResource } from 'src/app/app.resource';
import { PopUpInfoVM } from 'src/app/shared/models/components/popupinfoVm';
import { QuestionVM } from 'src/app/shared/models/components/questionVm';
import { GenericEnum } from 'src/app/shared/models/enum/generic.enum';
import { State } from 'src/app/core/state/core.state';
import { Store } from '@ngrx/store';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component.component';
import { Step } from 'src/app/core/models/step.model';
import * as fromHousing from '../../../core/state/housing';
import * as fromInfo from '../../../core/state/info';
import { lastValueFrom, take } from 'rxjs';
import { ResumeStep, cloneResumeStep } from 'src/app/core/models/resume-step.model';

import { MatomoTracker } from 'ngx-matomo-client';
import { MatomoConstants } from 'src/app/shared/constants/matomo.constants';
import { Stepper } from 'src/app/core/models/stepper.model';
@Component({
  selector: 'app-particularity',
  templateUrl: './particularity.component.html',
  styleUrls: ['./particularity.component.scss']
})
export class ParticularityComponent extends BaseComponent implements OnInit {
  questionOne!: QuestionVM;
  popupInfoOne!: PopUpInfoVM;
  questionTwo!: QuestionVM;
  popupInfoTwo!: PopUpInfoVM;
  genericEnum = GenericEnum;

  override step: Step = {
    step: this.resource.header.stepHousing,
    stepNumber: 1,
    subStep: this.resource.header.subStepParticularity,
    subStepNumber: 9
  };

  //Form
  yesO: boolean = false;
  noO: boolean = false;
  yesT: boolean = false;
  noT: boolean = false;

  public override currentPage = MatomoConstants.INSTALLATIONS;

  constructor(
    store: Store<State>,
    resources: AppResource,
    tracker: MatomoTracker,
    private router: Router
  ) {
    super(store, resources, tracker);

    this.questionOne = new QuestionVM(this.resource.question.particularity1);
    this.popupInfoOne = new PopUpInfoVM(
      this.resource.infobulle.durableDevelopment1,
      '',
      this.resource.infobulle.durableDevelopment2,
      '',
      ''
    );
    this.questionTwo = new QuestionVM(this.resource.question.particularity2);
    this.popupInfoTwo = new PopUpInfoVM(
      this.resource.infobulle.swimmingPool1,
      '',
      this.resource.infobulle.swimmingPool2,
      '',
      ''
    );
  }

  override async ngOnInit() {
    super.ngOnInit();
    this.updateResumeStepVisibility(true);
    this.loadStateData();
  }

  private async loadStateData() {
    var housingData = await lastValueFrom(
      this.store.select(fromHousing.selectHousing).pipe(take(1))
    );

    if (housingData.SaisieHabitation.InstallationDD != undefined) {
      if (housingData.SaisieHabitation.InstallationDD) this.yesO = true;
      else this.noO = true;
    }
    if (housingData.SaisieHabitation.PiscineSpaJacuzzi != undefined) {
      if (housingData.SaisieHabitation.PiscineSpaJacuzzi) this.yesT = true;
      else this.noT = true;
    }
  }

  selectValueO(value: string) {
    switch (value) {
      case GenericEnum.Yes:
        this.yesO = true;
        this.noO = false;
        break;
      case GenericEnum.No:
        this.yesO = false;
        this.noO = true;
        break;
    }

    if (this.yesT || this.noT) {
      this.setfromInfo();
      this.updateStepper();
      this.router.navigate(['/insuredvalue']);
    }
  }

  selectValueT(value: string) {
    switch (value) {
      case GenericEnum.Yes:
        this.yesT = true;
        this.noT = false;
        break;
      case GenericEnum.No:
        this.yesT = false;
        this.noT = true;
        break;
    }

    if (this.yesO || this.noO) {
      this.setfromInfo();
      this.updateStepper();
      this.router.navigate(['/insuredvalue']);
    }
  }

  async setfromInfo() {
    let installDD = false;
    let pSJ = false;

    if (this.noT && this.noO) {
      await this.updateResumeStep(
        this.resource.resumestep.particularity,
        this.resource.resumestep.noParticularity
      );
    } else if (this.yesO || this.yesT) {
      await this.updateResumeStep(
        this.resource.resumestep.particularity,
        this.resource.resumestep.oneOrMoreParticularity
      );

      if (this.yesO) installDD = true;
      if (this.yesT) pSJ = true;
    }

    this.store.dispatch(
      fromHousing.patchParticularity({
        payload: {
          InstallationDD: installDD,
          PiscineSpaJacuzzi: pSJ
        }
      })
    );
  }

  async updateStepper() {
    let stepper = await lastValueFrom(this.store.select(fromInfo.selectStepper).pipe(take(1)));
    var tmpStepper = JSON.parse(JSON.stringify(stepper)) as Stepper;

    let title = tmpStepper.devis.titles.find(
      (x: { libelle: string }) => x.libelle == this.resource.stepper.lodging
    );

    let subtitleS = title?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.particularity
    );

    if (subtitleS) subtitleS.isValid = true;

    let subtitleN;
    subtitleN = title?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.insuredvalue
    );

    if (subtitleN) {
      subtitleN.isActive = true;
      subtitleN.isVisible = true;
    }

    this.store.dispatch(fromInfo.updateStepper({ payload: tmpStepper }));
  }

  async updateResumeStep(id: string, libelle: string) {
    const resumeStep = await lastValueFrom(
      this.store.select(fromInfo.selectCurrentResumeStep).pipe(take(1))
    );
    const tmpResumeStep = cloneResumeStep(resumeStep);

    const stepResumeStep = tmpResumeStep.steps?.find(x => x.id == id);

    if (stepResumeStep) {
      stepResumeStep.step = libelle;
      stepResumeStep.isVisible = true;
    } else {
      tmpResumeStep.steps?.push({
        id: id,
        step: libelle,
        url: this.resource.resumestep.particularity,
        isVisible: true
      });
    }
    this.store.dispatch(fromInfo.updateResumeStep({ payload: tmpResumeStep }));
  }

  async hideResumeStep(id: String) {
    const resumeStep = await lastValueFrom(
      this.store.select(fromInfo.selectCurrentResumeStep).pipe(take(1))
    );

    const tmpResumeStep = cloneResumeStep(resumeStep);

    const stepResumeStep = tmpResumeStep.steps?.find(x => x.id == id);
    if (stepResumeStep) {
      stepResumeStep.isVisible = false;
      this.store.dispatch(fromInfo.updateResumeStep({ payload: tmpResumeStep }));
    }
  }

  setClassActive(value: boolean): string {
    return value ? 'active' : 'inactive';
  }
}
