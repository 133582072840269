import { createAction, props } from '@ngrx/store';

import { Info } from '../../models/info.model';
import { Question } from '../../models/question.model';
import { ResumeStep } from '../../models/resume-step.model';
import { Step } from '../../models/step.model';
import { Stepper, StepShow, Subtitle, Title } from '../../models/stepper.model';

export const updateResumeStep = createAction(
  '[Info] Change ResumeStep',
  props<{ payload: Partial<ResumeStep> }>()
);

export const changeValidationStatus = createAction(
  '[Info] change resume step visibility',
  props<{ isVisible: boolean }>()
);

export const updateInfo = createAction('[Info] Change Info', props<{ payload: Partial<Info> }>());

export const updateCurrentPage = createAction(
  '[Path] Change Current Page',
  props<{ currentPage: string }>()
);

export const updateStep = createAction('[Info] Change Step', props<{ payload: Partial<Step> }>());

export const updateQuestion = createAction(
  '[Info] Change Question',
  props<{ payload: Partial<Question> }>()
);

export const updateStepper = createAction(
  '[Info] Change Stepper',
  props<{ payload: Partial<Stepper> }>()
);

export const updateNextUrl = createAction('[Info] Change next URL', props<{ nextUrl: string }>());

export const resetStep = createAction('[Info] Reset Step');

export const resetQuestion = createAction('[Info] Reset Question');

export const resetInfo = createAction('[Info] Reset info');

export const resetResumeStep = createAction('[Info] Reset ResumeStep');

export const resetStepper = createAction('[Info] Reset Stepper');

export const init = createAction('[Init]');
