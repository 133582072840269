import { Civilite } from './form-state.model';

export interface Civility {
  id: number;
  value: Civilite;
  text: string;
}

export const civiliteObject: Civility[] = [
  { id: 1, text: Civilite.Monsieur, value: Civilite.Monsieur },
  { id: 2, text: Civilite.Madame, value: Civilite.Madame },
  { id: 3, text: Civilite.Autre, value: Civilite.Autre }
];
