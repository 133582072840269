import { Component, OnInit } from '@angular/core';
import { AppResource } from 'src/app/app.resource';
import { Resource } from 'src/app/resources/resource';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  resource: Resource;

  constructor(protected resources: AppResource) {
    this.resource = resources['resource'];
  }

  ngOnInit() {}

  goToLink(url: string) {
    window.open(url, '_blank');
  }
}
