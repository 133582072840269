import { Paiement } from './../../models/form-state.model';
import { AppResource } from 'src/app/app.resource';
import { Resource } from 'src/app/resources/resource';
// src/app/core/state/menus/menus.state.ts

import { Info } from '../../models/info.model';
import { Question } from '../../models/question.model';
import { ResumeStep } from '../../models/resume-step.model';
import { Step } from '../../models/step.model';
import { Stepper } from '../../models/stepper.model';

export interface InfoState {
  resumeStep: ResumeStep;
  info: Info;
  step: Step;
  stepper: Stepper;
  question: Question;
  nextUrl: string;
  currentPage: string;
}

//#region constantes
export const url = {
  solidarityaddress: 'solidarityaddress',
  address: 'address',
  houseapartment: 'houseapartment',
  ownertenant: 'ownertenant',
  primarysecondary: 'primarysecondary',
  preferentialrate: 'preferentialrate',
  numberpieces: 'numberpieces',
  previousclaim: 'previousclaim',
  dependency: 'dependency',
  particularity: 'particularity',
  insuredvalue: 'insuredvalue',
  subscriber: 'subscriber',
  rate: 'rate',
  offercustomization: 'offercustomization',
  schoolinsurance: 'schoolinsurance',
  confirmquote: 'confirmquote',
  services: 'services',
  lessorcertificate: 'lessorcertificate',
  cancellation: 'cancellation',
  payment: 'payment',
  electronicsignature: 'electronicsignature',
  confirmcontract: 'confirmcontract'
};

export const title = {
  lodging: 'Votre Logement',
  offer: 'Votre Offre',
  quote: 'Votre Devis',
  contract: 'Votre Contrat',
  sampling: 'Votre Prélèvement',
  signature: 'Votre Signature'
};

export const subtitle = {
  address: 'Adresse',
  houseapartment: 'Maison/Appartement',
  ownertenant: 'Propriétaire/Locataire',
  primarysecondary: 'Usage',
  preferentialrate: 'Tarif préférentiel',
  numberpieces: 'Nombre de pièces',
  previousclaim: 'Antécédent sinistres',
  dependency: 'Dépendances',
  particularity: 'Particularités',
  insuredvalue: 'Valeur Assurée',
  subscriber: 'Souscripteur',
  rate: 'Nos tarifs',
  offercustomization: 'Personnalisation',
  schoolinsurance: 'Assurance Scolaire',
  confirmquote: 'Confirmation du devis',
  services: 'Services Associés',
  lessorcertificate: 'Attestation Bailleur',
  cancellation: 'Motif de résiliation',
  payment: 'Paiement',
  electronicsignature: 'Documents contractuels',
  confirmcontract: 'Confirmation contrat'
};
//#endregion

export const initialState: InfoState = {
  resumeStep: {
    steps: [],
    isVisible: false
  },
  info: {
    logo: '',
    secondTitle: '',
    subTitle: '',
    title: '',
    isVisible: false
  },
  step: {
    step: '',
    stepNumber: -1,
    subStep: '',
    subStepNumber: -1
  },
  stepper: {
    devis: {
      titles: [
        {
          libelle: title.lodging,
          subtitles: [
            {
              libelle: subtitle.address,
              url: url.solidarityaddress,
              isValid: false,
              isActive: false,
              isVisible: false
            },
            {
              libelle: subtitle.address,
              url: url.address,
              isValid: false,
              isActive: false,
              isVisible: false
            },
            {
              libelle: subtitle.houseapartment,
              url: url.houseapartment,
              isValid: false,
              isActive: false,
              isVisible: true
            },
            {
              libelle: subtitle.ownertenant,
              url: url.ownertenant,
              isValid: false,
              isActive: false,
              isVisible: false
            },
            {
              libelle: subtitle.primarysecondary,
              url: url.primarysecondary,
              isValid: false,
              isActive: false,
              isVisible: true
            },
            {
              libelle: subtitle.preferentialrate,
              url: url.preferentialrate,
              isValid: false,
              isActive: false,
              isVisible: false
            },
            {
              libelle: subtitle.numberpieces,
              url: url.numberpieces,
              isValid: false,
              isActive: false,
              isVisible: true
            },
            {
              libelle: subtitle.previousclaim,
              url: url.previousclaim,
              isValid: false,
              isActive: false,
              isVisible: false
            },
            {
              libelle: subtitle.dependency,
              url: url.dependency,
              isValid: false,
              isActive: false,
              isVisible: false
            },
            {
              libelle: subtitle.particularity,
              url: url.particularity,
              isValid: false,
              isActive: false,
              isVisible: false
            },
            {
              libelle: subtitle.insuredvalue,
              url: url.insuredvalue,
              isValid: false,
              isActive: false,
              isVisible: false
            }
          ],
          isActive: true
        },
        {
          libelle: title.offer,
          subtitles: [
            {
              libelle: subtitle.subscriber,
              url: url.subscriber,
              isValid: false,
              isActive: false,
              isVisible: true
            },
            {
              libelle: subtitle.rate,
              url: url.rate,
              isValid: false,
              isActive: false,
              isVisible: true
            },
            {
              libelle: subtitle.offercustomization,
              url: url.offercustomization,
              isValid: false,
              isActive: false,
              isVisible: true
            },
            {
              libelle: subtitle.schoolinsurance,
              url: url.schoolinsurance,
              isValid: false,
              isActive: false,
              isVisible: false
            }
          ],
          isActive: false
        },
        {
          libelle: title.quote,
          subtitles: [
            {
              libelle: subtitle.confirmquote,
              url: url.confirmquote,
              isValid: false,
              isActive: false,
              isVisible: true
            }
          ],
          isActive: false
        }
      ]
    },
    contract: {
      titles: [
        {
          libelle: title.contract,
          subtitles: [
            {
              libelle: subtitle.services,
              url: url.services,
              isValid: false,
              isActive: false,
              isVisible: true
            },
            {
              libelle: subtitle.lessorcertificate,
              url: url.lessorcertificate,
              isValid: false,
              isActive: false,
              isVisible: true
            },
            {
              libelle: subtitle.cancellation,
              url: url.cancellation,
              isValid: false,
              isActive: false,
              isVisible: true
            }
          ],
          isActive: true
        },
        {
          libelle: title.sampling,
          subtitles: [
            {
              libelle: subtitle.payment,
              url: url.payment,
              isValid: false,
              isActive: false,
              isVisible: true
            }
          ],
          isActive: false
        },
        {
          libelle: title.signature,
          subtitles: [
            {
              libelle: subtitle.electronicsignature,
              url: url.electronicsignature,
              isValid: false,
              isActive: false,
              isVisible: true
            },
            {
              libelle: subtitle.confirmcontract,
              url: url.confirmcontract,
              isValid: false,
              isActive: false,
              isVisible: true
            }
          ],
          isActive: false
        }
      ]
    }
  },
  question: {
    libelle: '',
    popupInfo: undefined
  },
  nextUrl: '',
  currentPage: ''
};
