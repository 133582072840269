export interface ResumeStep {
  steps: StepResumeStep[];
  isVisible: boolean;
}

export interface StepResumeStep {
  id: string;
  step?: string;
  url: string;
  isVisible: boolean;
  current?: boolean;
}

export function cloneResumeStep(step: ResumeStep): ResumeStep {
  const newStep: ResumeStep = {
    isVisible: step.isVisible,
    steps: step.steps?.map((stepResumeStep: StepResumeStep) => ({ ...stepResumeStep })) || []
  };
  return newStep;
}
