<app-header [headerStep]="headerStep" [local]="true"></app-header>

<div class="container mx-auto mb-6">
  <div *ngIf="!stepB">
    <app-question [question]="question" [local]="true"></app-question>
    <div class="flex my-5">
      <div class="backgroundButton-ES flex px-1 py-0.5">
        <button
          class="my-auto flex-1"
          [ngClass]="getClass(mensuel)"
          (click)="updateClickButton(this.paymentEnum.Mensuel)">
          <b>{{ resource.payment.mensuel }}</b>
        </button>
        <button
          class="mx-0.5 my-auto px-1 flex-1"
          [ngClass]="getClass(trimestriel)"
          [disabled]="promoMonthOffered"
          (click)="updateClickButton(this.paymentEnum.Trimestriel)">
          <b>{{ resource.payment.trimestriel }}</b>
        </button>
        <button
          class="my-auto flex-1"
          [ngClass]="getClass(annuel)"
          [disabled]="promoMonthOffered"
          (click)="updateClickButton(this.paymentEnum.Annuel)">
          <b>{{ resource.payment.annuel }}</b>
        </button>
      </div>
    </div>

    <div
      class="space-y-4 mt-6 mb-4 md:grid md:grid-cols-2 md:gap-4 md:space-y-0"
      [formGroup]="paymentForm"
      *ngIf="paymentForm">
      <div class="w-full">
        <span class="p-float-label flex">
          <p-calendar
            [(ngModel)]="dateStarting"
            (ngModelChange)="onDateChange($event)"
            dataType="string"
            dateFormat="dd/mm/yy"
            [touchUI]="true"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [defaultDate]="dateDemarrage && dateDemarrage.value ? dateDemarrage.value : minDate"
            [disabled]="disabledDate"
            class="w-screen"
            formControlName="dateDemarrage">
          </p-calendar>
          <label for="float-input" class="calendar">{{ resource.payment.dateStartContract }}</label>
          <img [src]="resource.payment.logo" class="calendarSrc" />
        </span>
        <small
          class="p-error"
          *ngIf="
            dateDemarrage?.touched && dateDemarrage?.invalid && (dateDemarrage?.errors)!['required']
          "
          >{{ resource.error.monthStarting }}</small
        >
      </div>

      <div class="w-full" *ngIf="!annuel">
        <span class="p-float-label flex">
          <p-dropdown
            class="width-max"
            [options]="prelevements"
            [(ngModel)]="selectedPrelevement"
            optionLabel="text"
            formControlName="prelevement"
            [placeholder]="resource.payment.samplingDate"></p-dropdown>
          <label for="float-input" class="dropdown">{{ resource.payment.samplingDate }}</label>
        </span>
        <small
          class="p-error"
          *ngIf="prelevement?.touched && prelevement?.invalid && (prelevement?.errors)!['required']"
          >{{ resource.error.prelevement }}</small
        >
      </div>

      <div class="w-full md:col-span-2">
        <span class="p-float-label flex">
          <p-inputMask
            [(ngModel)]="valueIBAN"
            class="w-screen"
            formControlName="iban"
            [autoClear]="false"
            [unmask]="true"
            mask="**** **** **** **** **** **** ***"
            [placeholder]="resource.payment.iban">
          </p-inputMask>
          <label for="float-input" class="mask">{{ resource.payment.iban }}</label>
        </span>
        <small
          class="p-error"
          *ngIf="iban?.touched && iban?.invalid && (iban?.errors)!['required']"
          >{{ resource.error.iban }}</small
        >
      </div>

      <div class="w-full md:col-span-2">
        <span class="p-float-label">
          <p-dropdown
            formControlName="accountOwner"
            class="width-max"
            [options]="accountOwners"
            [(ngModel)]="selectedAccountOwner"
            optionLabel="text"
            [placeholder]="resource.form.accountowner"></p-dropdown>
          <label for="float-input" class="dropdown">{{ resource.form.accountowner }}</label>
        </span>

        <small
          class="p-error"
          *ngIf="
            accountOwner?.touched && accountOwner?.invalid && (accountOwner?.errors)!['required']
          ">
          {{ resource.error.accountOwner }}
        </small>
      </div>
      <app-phone-and-prefix
        (phoneControlsAdded)="addPhoneControls($event)"
        [displayConsent]="true"
        [displayPaymentInfos]="true"
        class="w-full md:col-span-2"></app-phone-and-prefix>
    </div>

    <div class="flex mt-8">
      <button
        pButton
        type="button"
        class="button-NS w-full"
        [disabled]="!paymentForm.valid"
        style="padding: 12px 18px"
        [label]="resource.payment.contractSign"
        (click)="showModalDialog()"></button>
    </div>
  </div>

  <div *ngIf="stepB" class="space-y-6 mt-10 mb-6">
    <p class="title-P">{{ resource.payment.titleModal }}</p>
    <p class="text-P">{{ resource.payment.textAdvisor1 }}</p>
    <p class="text-P">{{ resource.payment.textAdvisor2 }}</p>
    <p class="textB-P">{{ resource.payment.textAdvisor3 }}</p>
  </div>
</div>

<div class="container mx-auto h-28 md:h-40">
  <app-footer class="footer"></app-footer>
</div>
<app-web-callback></app-web-callback>

<p-dialog
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '330px', height: '430px', margin: '1rem' }"
  [closable]="false"
  [showHeader]="false"
  [baseZIndex]="10000">
  <p class="title-P my-5">{{ resource.payment.titleModal }}</p>
  <div class="my-5 space-y-2">
    <p class="text-P">{{ validationMessage }}</p>
  </div>
  <div class="flex justify-center mt-5">
    <button
      pButton
      type="button"
      class="button-NS w-full"
      [label]="resource.nextstep.validate"
      (click)="validPayment()"
      [loading]="isLoading"></button>
  </div>
  <div class="flex justify-center mt-2">
    <button
      pButton
      type="button"
      class="button-C w-full"
      [label]="resource.nextstep.cancel"
      (click)="cancelPayment()"></button>
  </div>
</p-dialog>

<p-dialog
  [(visible)]="displayModalOwner"
  [modal]="true"
  [style]="{ width: '330px', height: '540px', margin: '1rem' }"
  [closable]="false"
  [showHeader]="false"
  [baseZIndex]="10000">
  <p class="title-P my-5">{{ resource.payment.sorry }}</p>
  <p class="text-P my-5">
    {{ resource.payment.callUs }}
  </p>
  <div>
    <div class="infoModal-WCB mb-8 px-5 py-9 border-none">
      <p class="title-WCB px-2">
        {{ resource.payment.title }} <b>{{ resource.payment.titleB }}</b>
      </p>
      <div class="mx-4 my-7">
        <a class="button-CB flex" [href]="'tel:' + buttonTextTel">
          <img class="my-auto ml-6 mr-1" [src]="resource.webcallback.logo" />
          <span class="titleButton-CB my-auto ml-3 mr-2">{{ buttonTextTel }}</span>
        </a>
      </div>
    </div>
  </div>

  <div class="flex justify-center mt-2">
    <button
      pButton
      type="button"
      class="button-C w-full"
      [label]="resource.nextstep.cancel"
      (click)="onCancel()"></button>
  </div>
</p-dialog>

<app-error-popup
  [show]="displayErrorPhoneNumber"
  (cancel)="displayErrorPhoneNumber = false"
  [title]="resource.error.oups"
  [text]="resource.error.missingPhoneNumber"></app-error-popup>

<app-error-popup
  [show]="displayError"
  (cancel)="displayError = false"
  [title]="resource.error.oups"
  [text]="resource.error.api"></app-error-popup>

<app-error-popup
  [show]="displayErrorIBAN"
  (cancel)="displayErrorIBAN = false"
  [title]="resource.error.ibanErrorTitle"
  [text]="resource.error.ibanErrorText"></app-error-popup>

<app-error-popup
  [show]="contractAlreadyExist"
  (cancel)="contractAlreadyExist = false"
  [title]="resource.error.oups"
  [text]="resource.payment.contractAlreadyExist"></app-error-popup>
