<div class="flex my-6 items-center clickable-card-parent">
  <div
    class="mx-auto clickable-card flex flex-col mb-4 content-center items-center w-full cursor-pointer"
    (click)="onPrimarySelected()"
    [ngClass]="setClassActive(primarySelected)">
    <div class="primary"></div>
    <div class="flex flex-row items-center pb-8 place-content-between">
      <p class="mr-2">{{ resource.primarysecondary.primary }}</p>
    </div>
  </div>

  <div
    class="mx-auto clickable-card flex flex-col mb-4 content-center items-center w-full cursor-pointer"
    (click)="onSecondarySelected()"
    [ngClass]="setClassActive(secondarySelected)">
    <div class="secondary"></div>
    <div class="flex flex-row items-center pb-8 place-content-between">
      <p class="mr-2">{{ resource.primarysecondary.secondary }}</p>
    </div>
  </div>
</div>
