import { CurrencyPipe, DatePipe } from '@angular/common';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppResource } from './app.resource';
import { AppRoutingModule } from './shared/modules/app-routing.module';

import { AppComponent } from './app.component';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { RippleModule } from 'primeng/ripple';
import { environment } from 'src/environments/environment';
import { Configuration } from './api/configuration';
import { ApiModule } from './api/modules/api.module';
import { extModules } from './app.imports';
import { metaReducers, reducers } from './core/state/core.reducer';
import { HydrationEffects } from './core/state/hydration/hydration.effects';
import { ContextResolver } from './shared/components/redirection/context/context.resolver';
import { SignatureGuard } from './shared/guards/signature.guard';
import { ContractModule } from './shared/modules/contract.module';
import { DevisModule } from './shared/modules/devis.module';
import { GeneralInformationModule } from './shared/modules/general-information.module';
import { SharedModule } from './shared/modules/shared.module';
import { AppInsightsService } from './shared/services/app-insight.service';
import { ReferenceDataService } from './shared/services/reference-data-service';
import { WINDOW_PROVIDERS } from './shared/services/window.service';

import {
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalModule,
  MsalService
} from '@azure/msal-angular';
import { provideMatomo } from 'ngx-matomo-client';
import { PathEffects } from './core/state/path/path.effects';
import { MSALInstanceFactory } from './shared/config/msal-config';
import { MSALInterceptorConfigFactory } from './shared/interceptor/msal.interceptor';

export function initResource(resource: AppResource) {
  return () => resource.load(environment.brand);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    MsalModule,
    SharedModule,
    GeneralInformationModule,
    DevisModule,
    ContractModule,
    StoreModule.forRoot(reducers, {
      metaReducers
    }),
    EffectsModule.forRoot([HydrationEffects]),
    EffectsModule.forRoot([PathEffects]),
    ...extModules,
    AppRoutingModule,
    RippleModule,
    ApiModule.forRoot(
      () =>
        new Configuration({
          basePath: environment.baseUrl,
          apiBasePath: environment.apiV2RootUrl
        })
    )
  ],
  providers: [
    provideMatomo({
      siteId: environment.matomoSiteId,
      trackerUrl: environment.matomoHostUrl,
      disabled: !environment.production
    }),
    ContextResolver,
    AppResource,
    {
      provide: APP_INITIALIZER,
      useFactory: initResource,
      deps: [AppResource],
      multi: true
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'EUR'
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    ...WINDOW_PROVIDERS,
    ReferenceDataService,
    AppInsightsService,
    CurrencyPipe,
    DatePipe,
    SignatureGuard,
    MsalService,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
