// src/app/core/state/menus/menus.state.ts

import { Handlers } from 'src/app/api/models/handler.model';
import { PathType } from '../../enums/path-type.enum';

export interface PathState {
  pathType: PathType;
  previousPathType: PathType;
  handlers: Handlers;
}

export const initialState: PathState = {
  pathType: PathType.UNDEFINED,
  previousPathType: PathType.UNDEFINED,
  handlers: []
};
