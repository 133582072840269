// src/app/core/state/menus/menus.reducer.ts

import { Action, createReducer, on } from '@ngrx/store';
import * as PathActions from './path.actions';
import { PathState, initialState } from './path.state';

const pathReducer = createReducer(
  initialState,
  on(PathActions.updateType, (state, { pathType }: ReturnType<typeof PathActions.updateType>) => ({
    ...state,
    pathType
  })),
  on(
    PathActions.updatePreviousType,
    (state, { previousPathType }: ReturnType<typeof PathActions.updatePreviousType>) => ({
      ...state,
      previousPathType
    })
  ),
  on(
    PathActions.updateHandlers,
    (state, { handlers }: ReturnType<typeof PathActions.updateHandlers>) => ({
      ...state,
      handlers
    })
  ),
  on(PathActions.init, state => ({
    ...state,
    ...initialState
  }))
);

export function reducer(state: PathState | undefined, action: Action) {
  return pathReducer(state, action);
}
