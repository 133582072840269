<ng-container *ngIf="!preferentialRateChosen && !noPreferentialRateChosen">
  <div class="flex mt-6 mb-6 items-center clickable-card-parent">
    <div
      class="mx-auto clickable-card flex flex-col mb-4 content-center items-center w-full cursor-pointer"
      (click)="onPreferentialRateChosen()"
      [ngClass]="setClassActive(preferentialRateChosen)">
      <div
        class="flex flex-row items-center place-content-between text-center py-5 px-5"
        style="height: 143px">
        <span>
          {{ resource.preferentialrate.yes }}
        </span>
      </div>
    </div>

    <div
      class="mx-auto clickable-card flex flex-col mb-4 content-center items-center w-full cursor-pointer"
      (click)="onNoPreferentialRateChosen()"
      [ngClass]="setClassActive(noPreferentialRateChosen)">
      <div
        class="flex flex-row items-center place-content-between text-center py-5 px-5"
        style="height: 143px">
        <span>
          {{ resource.preferentialrate.no }}
        </span>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="preferentialRateChosen && !revenue">
  <div class="flex flex-row mt-6 mb-4 items-center w-full place-content-evenly">
    <button
      pButton
      class="my-4 button-icon iconSideLabel"
      icon="pi pi-minus"
      iconPos="right"
      [disabled]="numberOfPeople === 0"
      (click)="removePerson($event)"></button>
    <p-inputNumber
      [(ngModel)]="numberOfPeople"
      (onInput)="triggerChange()"
      [min]="0"
      [max]="100"
      class="input-number"
      type="text"></p-inputNumber>
    <button
      pButton
      class="my-4 button-icon iconSideLabel"
      icon="pi pi-plus"
      iconPos="right"
      [disabled]="numberOfPeople === 10"
      (click)="addPerson($event)"></button>
  </div>
  <div *ngIf="numberOfPeople > 0" class="flex my-5">
    <button
      pButton
      type="button"
      class="button-NS hover:button-NS focus:button-NS active:button-NS w-full"
      [label]="resource.nextstep.validate"
      icon="pi pi-arrow-right"
      iconPos="right"
      (click)="switchToRevenue()"></button>
  </div>
  <button
    pButton
    class="mx-auto mt-4 mb-20 button-outline-call-SV width-max iconSideLabel"
    (click)="onNoPreferentialRate()">
    {{ resource.preferentialrate.noP1 }} {{ cityName }}
  </button>
</ng-container>

<ng-container *ngIf="revenue">
  <div class="flex flex-col mx-8 mt-6 mb-16 items-center">
    <div
      class="mx-auto clickable-card flex flex-col mb-4 content-center items-center w-full"
      (click)="onRfrInferiorChosen()"
      [ngClass]="setClassActive(rfrInferiorChosen)">
      <div class="flex flex-row items-center place-content-between">
        <p class="two-lines px-6 py-12">
          {{ resource.preferentialrate.inferiorAt }}
          {{ plafondResources | currency : undefined : undefined : '1.0-0' }}
        </p>
      </div>
    </div>

    <div
      class="mx-auto clickable-card flex flex-col mb-4 content-center items-center w-full"
      (click)="onRfrSuperiorChosen()"
      [ngClass]="setClassActive(rfrSuperiorChosen)">
      <div class="flex flex-row items-center place-content-between">
        <p class="two-lines px-6 py-12">
          {{ resource.preferentialrate.superiorAt }}
          {{ plafondResources | currency : undefined : undefined : '1.0-0' }}
        </p>
      </div>
    </div>

    <div
      class="mx-auto clickable-card flex flex-col mb-4 content-center items-center w-full"
      (click)="onRfrNoAnswerChosen()"
      [ngClass]="setClassActive(rfrNoAnswerChosen)">
      <div class="flex flex-row items-center place-content-between">
        <p class="two-lines px-6 py-10 text-center">
          {{ resource.preferentialrate.dontwish }}<br />
          {{ resource.preferentialrate.response }}
        </p>
      </div>
    </div>
  </div>
</ng-container>

<p-dialog
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '330px', height: '435px', margin: '1rem' }"
  [closable]="false"
  appendTo="body"
  [showHeader]="false"
  [baseZIndex]="10000">
  <p class="title-P my-5">{{ resource.preferentialrate.preferentialrate }}</p>
  <p class="text-P my-5">{{ resource.preferentialrate.preferentialrate1 }}</p>
  <p class="text-P my-5">{{ resource.preferentialrate.preferentialrate2 }}</p>
  <div class="flex justify-center mt-10">
    <button
      pButton
      type="button"
      class="button-NS w-full"
      [label]="resource.nextstep.confirmYourChoice"
      (click)="validatePreferentialRate()"></button>
  </div>
  <div class="flex justify-center mt-2">
    <button
      pButton
      type="button"
      class="button-C w-full"
      [label]="resource.nextstep.cancel"
      (click)="cancelPreferentialRate()"></button>
  </div>
</p-dialog>
