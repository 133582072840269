import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MatomoTracker } from 'ngx-matomo-client';
import { switchMap } from 'rxjs';
import { AuthenticationService } from 'src/app/api/services/authentication.service';
import { AppResource } from 'src/app/app.resource';
import { State } from 'src/app/core/state/core.state';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component.component';
import { FAILED } from 'src/app/shared/constants/error.constants';

@Component({
  selector: 'app-strong-authentication',
  templateUrl: './strong-authentication.component.html',
  styleUrls: ['./strong-authentication.component.scss']
})
export class StrongAuthenticationComponent extends BaseComponent implements OnInit {
  readonly LOCKEDOUT = 'LockedOut';

  @Input() visible: boolean = false;
  @Output() closeDialogEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() closeValidMfa: EventEmitter<boolean> = new EventEmitter<boolean>();

  authForm: FormGroup = new FormGroup({
    otpControl: new FormControl<string | null>(null, [Validators.required])
  });

  email: string = '';
  password: string = '';

  summaryMessage: string = '';
  detailMessage: string = '';

  get otpFormControl(): FormControl {
    return this.authForm.get('otpControl') as FormControl;
  }
  constructor(
    private authenticationService: AuthenticationService,
    store: Store<State>,
    resources: AppResource,
    tracker: MatomoTracker
  ) {
    super(store, resources, tracker);
  }

  override async ngOnInit() {
    this.authenticationService.currentEmail.subscribe(email => (this.email = email));
  }

  onSubmit() {
    if (this.authForm.valid) {
      this.authenticationService.currentPassword.subscribe(password => (this.password = password));
    }
    this.authenticationService
      .sendLogin(this.email, this.password, this.otpFormControl?.value, true)
      .pipe(
        switchMap(() =>
          this.authenticationService.verifyToken(this.email, this.otpFormControl?.value)
        ),
        switchMap(() =>
          this.authenticationService.sendLogin(
            this.email,
            this.password,
            this.otpFormControl?.value,
            false
          )
        )
      )
      .subscribe({
        next: () => {
          this.visible = false;
          this.closeValidMfa.emit(true);
        },
        error: error => {
          if (error.error.detail === this.LOCKEDOUT) {
            this.summaryMessage = this.resource.error.connexionError;
            this.detailMessage = this.resource.error.lockedOutMessage;
          } else if (error.error.detail === FAILED) {
            this.summaryMessage = this.resource.error.connexionError;
            this.detailMessage = this.resource.error.errorMfaBadCode;
          }
        }
      });
  }

  closeDialog() {
    this.closeDialogEmitter.emit(true);
  }
}
