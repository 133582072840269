import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { ProductVersion } from '../models/product-version.model';

@Injectable({
  providedIn: 'root'
})
export class ProductVersionService {
  basePath: string;
  constructor(private http: HttpClient, configuration: Configuration) {
    this.basePath = configuration.apiBasePath ?? '';
  }

  public getByProductVersion(quotationReference: string): Observable<ProductVersion> {
    const url = `${this.basePath}productversions?quotationReference=${quotationReference}`;
    return this.http.get<ProductVersion>(url);
  }
}
