import { inject, Injectable } from '@angular/core';
import { PathType } from 'src/app/core/enums/path-type.enum';
import { WINDOW, WindowRef } from './window.service';

export interface ReferenceScale {
  Id: number;
  NombrePersonnes: number;
  Montant: number;
}

export interface BaremeValeurAssureeElement {
  Id: number;
  NombrePieces: number;
  F1: number;
  F2: number;
}

export type BaremeValeurAssuree = BaremeValeurAssureeElement[];

export type Countries = string[];

export interface BailleurElement {
  Id: number;
  Email: string;
  Name: string;
}

export type Bailleurs = BailleurElement[];
export type Assureurs = AssureurElement[];

export interface AssureurElement {
  Id: number;
  NomAssureur: string;
  AdressePostale: string;
  CodePostal: string;
  Ville: string;
}

@Injectable({
  providedIn: 'root'
})
export class ReferenceDataService {
  private windowRef = inject<WindowRef>(WINDOW);
  constructor() {}

  GetReferenceScale(pathType: PathType): ReferenceScale[] {
    switch (pathType) {
      case PathType.HOUSING_PARIS:
        return (this.windowRef as any).vyvBaremeAHP;
      case PathType.HOUSING_TOURCOING:
        return (this.windowRef as any).vyvBaremeAHT;
      default:
        return [];
    }
  }

  get baremeValeurAssuree(): BaremeValeurAssuree {
    return (this.windowRef as any).vyvBaremeValeurAssuree;
  }
}
