/**
 * VyV Conseil MRH Public API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { VyVContractResiliationAncienneAssuranceInfoAdresseAssureur } from './vyVContractResiliationAncienneAssuranceInfoAdresseAssureur';

export interface VyVContractResiliationAncienneAssuranceInfo {
  Raison: VyVContractResiliationAncienneAssuranceInfo.RaisonEnum;
  AdresseAssureur: VyVContractResiliationAncienneAssuranceInfoAdresseAssureur | null;
  NomAssureur: string | null;
  NumeroContrat: string | null;
  MoisDemarrageContrat: string | null;
  DateEnvoiLR: string | null;
}
export namespace VyVContractResiliationAncienneAssuranceInfo {
  export type RaisonEnum = 'ChangementAssureur' | 'Emmenagement';
  export const RaisonEnum = {
    ChangementAssureur: 'ChangementAssureur' as RaisonEnum,
    Emmenagement: 'Emmenagement' as RaisonEnum
  };
}
