import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { InsurerModel } from '../models/insurer.model';

@Injectable({
  providedIn: 'root'
})
export class InsurerService {
  public configuration = new Configuration();
  baseUrl: string;
  apiEndpoint: string = 'insurers';

  constructor(private http: HttpClient, configuration: Configuration) {
    this.baseUrl = configuration.apiBasePath ?? '';
  }

  public GetInsurers(): Observable<Array<InsurerModel>> {
    const requestUrl = `${this.baseUrl}${this.apiEndpoint}`;
    return this.http.get<Array<InsurerModel>>(requestUrl);
  }
}
