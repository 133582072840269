/**
 * VyV Conseil MRH Public API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type VyVSharedProductCode = 'HC' | 'HP' | 'HS' | 'HM_HC' | 'HT';

export const VyVSharedProductCode = {
  HM_HC: 'HM_HC' as VyVSharedProductCode,
  HC: 'HC' as VyVSharedProductCode,
  HP: 'HP' as VyVSharedProductCode,
  HS: 'HS' as VyVSharedProductCode,
  HT: 'HT' as VyVSharedProductCode
};
