import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Guid } from 'guid-typescript';
import { MatomoTracker } from 'ngx-matomo-client';
import { lastValueFrom, take } from 'rxjs';
import { PolicyDocumentType } from 'src/app/api/models/policyDocumentType';
import { DocumentService } from 'src/app/api/services/document.service';
import { MimeType } from 'src/app/core/enums/mime-type.enum';
import { Contract } from 'src/app/core/models/form-state.model';
import * as fromContext from 'src/app/core/state/context';
import { State } from 'src/app/core/state/core.state';
import * as fromSubscriber from 'src/app/core/state/subscriber';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component.component';
import { ConfirmContractVM } from 'src/app/shared/models/components/confirmcontractVm';
import { addDownloadParam } from 'src/app/shared/utils/urlUtils';
import { environment } from 'src/environments/environment';
import { String } from 'typescript-string-operations';
import * as fromContract from '../../../core/state/contract';
import { AppResource } from './../../../app.resource';

@Component({
  selector: 'app-contract-confirmation',
  templateUrl: './contract-confirmation.component.html',
  styleUrls: ['./contract-confirmation.component.scss']
})
export class ContractConfirmationComponent extends BaseComponent implements OnInit {
  confirmContractVm!: ConfirmContractVM;
  contrat!: Contract;
  subscriber!: fromSubscriber.SubscriberState;
  context!: fromContext.ContextState;
  PolicyDocumentType = PolicyDocumentType;
  get brand() {
    return environment.brand;
  }

  constructor(
    store: Store<State>,
    resources: AppResource,
    tracker: MatomoTracker,
    private documentService: DocumentService
  ) {
    super(store, resources, tracker);
  }

  override async ngOnInit() {
    super.ngOnInit();

    await this.loadStateData();

    this.initVm();
  }

  downloadDoc(uri: string) {
    window.open(uri, '_blank');
  }

  private async loadStateData() {
    this.contrat = await lastValueFrom(
      this.store.select(fromContract.selectContractState).pipe(take(1))
    );
    this.subscriber = await lastValueFrom(
      this.store.select(fromSubscriber.selectSubscriber).pipe(take(1))
    );
  }

  private initVm() {
    if (this.contrat) {
      const subtitleTemplate = String.format(
        this.resource.confirmquote.subtitle,
        this.contrat.RefContrat
      );
      this.confirmContractVm = new ConfirmContractVM(
        subtitleTemplate,
        this.contrat.NumeroContrat,
        addDownloadParam(this.contrat.RefDocumentCP),
        addDownloadParam(this.contrat.RefDocumentFIC),
        addDownloadParam(this.contrat.RefDocumentMandatSEPA),
        addDownloadParam(this.contrat.RefDocumentCG),
        addDownloadParam(this.contrat.RefDocumentAttestation),
        []
      );

      if (this.contrat.RefDocumentsSI.length > 0)
        this.contrat.RefDocumentsSI.forEach(doc => {
          this.confirmContractVm.attestationScolaire.push(addDownloadParam(doc));
        });
    } else {
      this.confirmContractVm = new ConfirmContractVM('', '', '', '', '', '', '', []);
    }
  }
  DownloadDocumentApi(documentId: string) {
    this.documentService
      .getAgreementDocument(
        Guid.parse(documentId),
        Guid.parse(this.confirmContractVm.numberContract),
        this.subscriber.subscriber?.Email!
      )
      .subscribe(data => {
        const blob = this.documentService.convertBase64ToBlob(
          atob(data.fileContents),
          MimeType.OCTET_STREAM
        );
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = data.fileDownloadName;
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }
  DownloadDocument(documentType: PolicyDocumentType, index?: number) {
    const documentId = this.getFileByDocumentType(documentType);
    if (!documentId) return;
    if (typeof documentId === 'string') {
      this.DownloadDocumentApi(documentId);
    } else if (index !== undefined) {
      this.DownloadDocumentApi(documentId[index]);
    }
  }
  private getFileByDocumentType(documentType: PolicyDocumentType): string | string[] | undefined {
    switch (documentType) {
      case PolicyDocumentType.CP:
        return this.confirmContractVm.contractDoc;
      case PolicyDocumentType.FIC:
        return this.confirmContractVm.ficDoc;
      case PolicyDocumentType.CG:
        return this.confirmContractVm.cgDoc;
      case PolicyDocumentType.SEPA:
        return this.confirmContractVm.mandatSepaDoc;
      case PolicyDocumentType.ATTASSU:
        return this.confirmContractVm.attestationDoc;
      case PolicyDocumentType.ATTSCO:
        return this.confirmContractVm.attestationScolaire;
      default:
        return undefined;
    }
  }
}
