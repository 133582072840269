import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatomoTracker } from 'ngx-matomo-client';
import { AppResource } from 'src/app/app.resource';
import { Info } from 'src/app/core/models/info.model';
import { Question } from 'src/app/core/models/question.model';
import { ResumeStep } from 'src/app/core/models/resume-step.model';
import { Step } from 'src/app/core/models/step.model';
import { Resource } from 'src/app/resources/resource';
import * as fromRoot from '../../../core/state/core.state';
import * as fromInfo from '../../../core/state/info';

@Component({
  selector: 'app-base-component',
  templateUrl: './base-component.component.html',
  styleUrls: ['./base-component.component.scss']
})
export abstract class BaseComponent implements OnInit {
  public step!: Step | null;
  public question!: Question | null;
  public info!: Info | null;
  public resource!: Resource;

  public currentPage!: string;

  constructor(
    protected store: Store<fromRoot.State>,
    protected resources: AppResource,
    protected readonly tracker: MatomoTracker
  ) {
    this.resource = resources['resource'];
  }

  async ngOnInit() {
    this.initTracking();
    this.scrollToTop();
    this.dispatchHeaderInfo();
    this.dispatchHeaderQuestion();
    this.dispatchHeaderStep();
  }

  initTracking() {
    this.tracker.enableHeartBeatTimer(15);
    this.tracker.setDocumentTitle(location.href.toLowerCase());
    this.tracker.trackPageView();
  }

  scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  protected dispatchHeaderStep() {
    if (this.step !== null && this.step !== undefined) {
      const payload = this.step as Partial<Step>;
      this.store.dispatch(fromInfo.updateStep({ payload }));
    } else {
      this.store.dispatch(fromInfo.resetStep());
    }
  }

  protected dispatchHeaderQuestion() {
    if (this.question && this.question.libelle) {
      const payload = this.question as Partial<Question>;
      this.store.dispatch(fromInfo.updateQuestion({ payload }));
    } else {
      this.store.dispatch(fromInfo.resetQuestion());
    }
  }

  protected dispatchHeaderInfo() {
    if (this.info && this.info.isVisible) {
      const payload = this.info as Partial<Info>;
      this.store.dispatch(fromInfo.updateInfo({ payload }));
    } else {
      this.store.dispatch(fromInfo.resetInfo());
    }
  }

  protected updateResumeStepVisibility(isVisible: boolean) {
    this.store.dispatch(
      fromInfo.updateResumeStep({ payload: { isVisible } as Partial<ResumeStep> })
    );
  }
}
