import { createAction, props } from '@ngrx/store';
import { Handlers } from '../../../api/models/handler.model';
import { PathType } from '../../enums/path-type.enum';

export const updateType = createAction('[Path] Change Path Type', props<{ pathType: PathType }>());
export const updatePreviousType = createAction(
  '[Path] Change Previous Path Type',
  props<{ previousPathType: PathType }>()
);

export const init = createAction('[Init]');

export const updateHandlers = createAction(
  '[Path] Update Handlers',
  props<{ handlers: Handlers }>()
);
