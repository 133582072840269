export class HeaderVM {
  step: string;
  stepNumber: number;
  subStep: string;
  subStepNumber: number;

  constructor(
    step: string,
    stepNumber: number,
    subStep: string,
    subStepNumber: number
  ) {
    this.step = step;
    this.stepNumber = stepNumber;
    this.subStep = subStep;
    this.subStepNumber = subStepNumber;
  }
}
