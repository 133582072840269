import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { lastValueFrom, map, take } from 'rxjs';
import * as fromContract from 'src/app/core/state/contract';

@Injectable({
  providedIn: 'root'
})
export class ClientAdvisorService {
  constructor(private store: Store) {}

  async getClientAdvisorFullName(): Promise<string> {
    return await lastValueFrom(
      this.store.select(fromContract.selectContract).pipe(
        map(x => x.Contract.ClientAdvisorFullName),
        take(1)
      )
    );
  }
}
