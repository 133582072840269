import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { MatomoTracker } from 'ngx-matomo-client';
import { AuthenticationService } from 'src/app/api/services/authentication.service';
import { MemberService } from 'src/app/api/services/member.service';
import { AppResource } from 'src/app/app.resource';
import { State } from 'src/app/core/state/core.state';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component.component';
import { TOKEN_BACKOFFICE } from 'src/app/shared/constants/cookies.constants';
import {
  ERROR_401,
  LOCKEDOUT,
  REQUIRES_TWO_FACTOR
} from 'src/app/shared/constants/error.constants';
import { EMAIL, PASSWORD } from 'src/app/shared/constants/form-controls.constants';
import { EMAIL_REGEX } from 'src/app/shared/constants/validators.constants';

@Component({
  selector: 'app-login-popup',
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.scss']
})
export class LoginPopupComponent extends BaseComponent implements OnInit {
  @Input() visible: boolean = false;
  @Input() subscriberEmail: string = '';

  @Output() closeDialogEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() closeValidMfa: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() closeValidLogin: EventEmitter<boolean> = new EventEmitter<boolean>();

  public loginForm: FormGroup = new FormGroup({
    email: new FormControl<string | null>(
      { value: this.subscriberEmail, disabled: this.subscriberEmail !== null },
      { validators: [Validators.required, Validators.pattern(EMAIL_REGEX)] }
    ),
    password: new FormControl<string | null>(null, [Validators.required, Validators.minLength(8)])
  });

  authPopup: boolean = false;
  summaryMessage: string = '';
  detailMessage: string = '';
  errorLoginMessage: boolean = false;
  resetPasswordBool: boolean = false;

  get email() {
    return this.loginForm.get(EMAIL);
  }

  get password() {
    return this.loginForm.get(PASSWORD);
  }

  constructor(
    private authenticationService: AuthenticationService,
    private cookieService: CookieService,
    private memberService: MemberService,
    store: Store<State>,
    resources: AppResource,
    tracker: MatomoTracker
  ) {
    super(store, resources, tracker);
  }

  override async ngOnInit() {
    this.email?.setValue(this.subscriberEmail);
  }

  login() {
    this.errorLoginMessage = false;
    if (this.cookieService.check(TOKEN_BACKOFFICE)) {
      this.authenticationService
        .sendLogin(
          this.email?.value,
          this.password?.value,
          this.cookieService.get(TOKEN_BACKOFFICE),
          false
        )
        .subscribe({
          next: () => {
            this.visible = false;
            this.closeValidLogin.emit(true);
          },
          error: error => {
            if (error.error.status === 401) {
              this.summaryMessage = this.resource.error.connexionError;
              this.detailMessage = this.resource.error.passwordError;
              this.errorLoginMessage = true;
            }
          }
        });
    } else {
      this.authenticationService
        .sendLogin(this.email?.value, this.password?.value, '', false)
        .subscribe({
          next: () => {
            this.visible = false;
            this.closeValidLogin.emit(true);
          },
          error: error => {
            if (error.status === ERROR_401 && error.error.detail === REQUIRES_TWO_FACTOR) {
              this.authenticationService.sendToken(this.email?.value).subscribe({
                next: () => {
                  this.authenticationService.sharedEmail(this.email?.value);
                  this.authenticationService.sharedPassword(this.password?.value);
                  this.visible = false;
                  this.authPopup = true;
                },
                error: error => {
                  this.summaryMessage = this.resource.error.connexionError;
                  this.detailMessage = this.resource.error.unableToConnect;
                  this.errorLoginMessage = false;
                  return error;
                }
              });
            } else if (error.status === ERROR_401 && error.error.detail === LOCKEDOUT) {
              this.summaryMessage = this.resource?.error?.connexionError;
              this.detailMessage = this.resource?.error?.lockedOutMessage;
              this.errorLoginMessage = false;
              return error;
            } else {
              this.summaryMessage = this.resource.error.connexionError;
              this.detailMessage = this.resource.error.unableToConnect;
              this.errorLoginMessage = true;
              return error;
            }
          }
        });
    }
  }

  closeDialog() {
    this.closeDialogEmitter.emit(true);
  }

  closeDialogStrongAuth() {
    this.closeDialogEmitter.emit(true);
  }

  closeDialogValidMfa(event: boolean) {
    this.closeValidMfa.emit(event);
  }

  onPasswordFocus() {
    this.errorLoginMessage = false;
  }

  resetPassword() {
    this.memberService.passwordReset(this.email?.value).subscribe({
      next: () => {
        this.resetPasswordBool = true;
      }
    });
  }
}
