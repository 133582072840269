export enum OfferCustomizationEnum {
  FiveK = '5k',
  TenK = '10k',
  TwentyK = '20k',
  Base = 'Base',
  Object = 'Object',
  BP = 'BP',
  JP = 'JP',
  NP = 'NP',
  SI = 'SI'
}
