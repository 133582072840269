import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextService } from 'src/app/api/services/context.service';
import { CreateContextRequestBuilder } from './builders/create-context-request.builder';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html'
})
export class ProductComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contextService: ContextService
  ) {}
  async ngOnInit(): Promise<void> {
    const productCode = this.route.snapshot.paramMap.get('productCode');
    const requestBody = new CreateContextRequestBuilder().withProduit(productCode).build();
    this.contextService.createContext(requestBody).subscribe(result => {
      const url = new URL(result);
      this.router.navigate([url.pathname]);
    });
  }
}
