// src/app/core/state/menus/menus.reducer.ts

import { Action, createReducer, on } from '@ngrx/store';
import * as PaymentActions from './payment.actions';
import { initialState, PaymentState } from './payment.state';

const paymentReducer = createReducer(
  initialState,
  on(
    PaymentActions.patchPayment,
    (state, action: ReturnType<typeof PaymentActions.patchPayment>) => ({
      ...state,
      payment: { ...state.payment!, ...action.payload }
    })
  ),
  on(
    PaymentActions.changeValidationStatus,
    (state, { isValid }: ReturnType<typeof PaymentActions.changeValidationStatus>) => ({
      ...state,
      isValid
    })
  ),
  on(PaymentActions.init, state => ({
    ...state,
    ...initialState
  }))
);

export function reducer(state: PaymentState | undefined, action: Action) {
  return paymentReducer(state, action);
}
