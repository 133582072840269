import { addDownloadParam } from '../../utils/urlUtils';

export class SignatureVM {
  condPUrlEmbed!: string;
  infoUrlEmbed!: string;
  sepaUrlEmbed!: string;

  condPUrlDl!: string;
  infoUrlDl!: string;
  sepaUrlDl!: string;

  condPLoaded: boolean;
  infoLoaded: boolean;
  sepaLoaded: boolean;
  constructor(condPUrl: string, infoUrl: string, sepaUrl: string) {
    this.condPUrlEmbed = condPUrl;
    this.infoUrlEmbed = infoUrl;
    this.sepaUrlEmbed = sepaUrl;

    this.condPLoaded = false;
    this.infoLoaded = false;
    this.sepaLoaded = false;

    this.condPUrlDl = addDownloadParam(condPUrl);
    this.infoUrlDl = addDownloadParam(infoUrl);
    this.sepaUrlDl = addDownloadParam(sepaUrl);
  }
}
