export class PopUpInfoVM {
  textOne!: string;
  textTwo: string | undefined;
  textThree: string | undefined;
  textFour: string | undefined;
  imgPath: string | undefined;

  constructor(
    textOne: string,
    textTwo: string,
    textThree: string,
    textFour: string,
    imgPath: string
  ) {
    this.textOne = textOne;
    this.textTwo = textTwo;
    this.textThree = textThree;
    this.textFour = textFour;
    this.imgPath = imgPath;
  }
}

export class PopUpInfoHAVM {
  textOne!: string;
  textTwo: string | undefined;
  textThree: string | undefined;
  textFour: string | undefined;
  textFive: string | undefined;
  textSix: string | undefined;

  constructor(
    textOne: string,
    textTwo: string,
    textThree: string,
    textFour: string,
    textFive: string,
    textSix: string
  ) {
    this.textOne = textOne;
    this.textTwo = textTwo;
    this.textThree = textThree;
    this.textFour = textFour;
    this.textFive = textFive;
    this.textSix = textSix;
  }
}
