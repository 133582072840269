<div class="subscriber-consent mt-3" *ngIf="consentement">
  <div class="text-consent">
    <p>{{ consentement.libelle }}</p>
  </div>
  <br />
  <div
    class="user-choice grid grid-cols-1 md:grid-cols-3 md:gap-x-3 gap-y-3"
    *ngFor="let c of consentement?.voies">
    <div class="label-txt">{{ c.libelle }}</div>
    <div class="checkbox-choice">
      <p-radioButton
        [value]="true"
        [(ngModel)]="c.isCheck"
        label="Oui"
        (onClick)="checkboxValues()"></p-radioButton>
    </div>
    <div class="checkbox-choice">
      <p-radioButton
        [value]="false"
        [(ngModel)]="c.isCheck"
        label="Non"
        (onClick)="checkboxValues()"></p-radioButton>
    </div>
  </div>
</div>
