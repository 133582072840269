import { Router } from '@angular/router';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {
  private appInsights!: ApplicationInsights;

  constructor(private router: Router) {}

  public initAppInsights() {
    if (!environment.development && !this.appInsights) {
      var angularPlugin = new AngularPlugin();
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: environment.appInsightsKey,
          extensions: [angularPlugin],
          extensionConfig: {
            [angularPlugin.identifier]: { router: this.router }
          },
          enableAjaxErrorStatusText: true,
          enableDebugExceptions: true,
          enableAjaxPerfTracking: true,
          enableUnhandledPromiseRejectionTracking: true,
          enablePerfMgr: true,
          enableAutoRouteTracking: true,
          enableRequestHeaderTracking: true,
          enableResponseHeaderTracking: true
        }
      });
      this.appInsights.loadAppInsights();
    }
  }

  disableAppInsights() {
    if (this.appInsights != null) {
      this.appInsights.getCookieMgr().setEnabled(false);
      this.appInsights.unload();
    }
  }
}
