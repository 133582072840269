<app-header></app-header>
<app-resume-step></app-resume-step>
<div class="container mx-auto px-4 mb-6">
  <app-info-top></app-info-top>
  <app-question
    [question]="question"
    [popupInfoHA]="question.popupInfoHA"
    [local]="true"></app-question>

  <div class="flex mt-6 items-center clickable-card-parent">
    <div
      class="mx-auto clickable-card flex flex-col mb-4 content-center items-center w-full text-center justify-center cursor-pointer"
      (click)="onHouseSelected()"
      [ngClass]="setClassActive(houseSelected)">
      <div class="house"></div>
      <div class="flex flex-row items-center pb-8 place-content-between">
        <p class="mr-2">{{ resource.houseapartment.house }}</p>
      </div>
    </div>

    <div
      class="mx-auto clickable-card flex flex-col mb-4 content-center items-center w-full text-center justify-center cursor-pointer"
      (click)="onFlatSelected()"
      [ngClass]="setClassActive(flatSelected)">
      <div class="appartment"></div>
      <div class="flex flex-row items-center pb-8 place-content-between">
        <p class="mr-2">{{ resource.houseapartment.apartment }}</p>
      </div>
    </div>

    <div
      class="mx-auto clickable-card flex flex-col mb-4 content-center items-center w-full text-center cursor-pointer"
      (click)="onOtherSelected()"
      [ngClass]="setClassActive(otherSelected)">
      <div class="other"></div>
      <div class="flex flex-row items-center pb-4 place-content-between">
        <p class="two-lines">{{ resource.houseapartment.other }}</p>
      </div>
    </div>
  </div>
</div>

<div class="container mx-auto h-28 md:h-40">
  <app-footer class="footer"></app-footer>
</div>
<app-web-callback></app-web-callback>

<app-web-callback-popup [show]="displayModal" (cancel)="cancel()"></app-web-callback-popup>
