export const CIVILITY = 'civility';
export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const BIRTHDAY = 'birthday';
export const BIRTHDAY_PLACE = 'birthdayPlace';
export const BIRTHDAY_COUNTRY = 'birthdayCountry';
export const EMAIL = 'email';
export const CONFIRM_EMAIL = 'confirmEmail';
export const PHONE_GROUP = 'phoneGroup';
export const PHONE = 'phone';
export const PHONE_PREFIX = 'phonePrefix';
export const BRAND = 'brand';
