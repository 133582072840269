<div
  class="mt-6 mb-4"
  [formGroup]="subscriberForm"
  *ngIf="subscriberForm"
  [hidden]="!emailStepConfirm">
  <div class="grid grid-cols-1 md:grid-cols-4 md:gap-x-4 gap-y-4">
    <div class="md:col-span-4">
      <span class="p-float-label">
        <input type="text" pInputText formControlName="email" (keyup)="ToLowerCase($event)" />
        <label for="float-input" class="inputtext">{{ resource.form.emailPersonnel }}</label>
      </span>
      <small
        class="p-error"
        *ngIf="email?.touched && email?.invalid && (email?.errors)!['required']"
        >{{ resource.error.email }}</small
      >
      <small
        class="p-error"
        *ngIf="email?.touched && email?.invalid && (email?.errors)!['pattern']"
        >{{ resource.error.emailformat }}</small
      >
      <small *ngIf="email?.value && estClient" [innerHTML]="resource.form.wrongEmail"></small>
    </div>

    <div class="md:col-span-2">
      <span class="p-float-label">
        <p-dropdown
          formControlName="civility"
          class="width-max"
          [options]="civilities"
          optionLabel="text"
          [placeholder]="resource.form.civility">
        </p-dropdown>
        <label for="float-input" class="dropdown">{{ resource.form.civility }}</label>
      </span>
      <small
        class="p-error"
        *ngIf="civility?.touched && civility?.invalid && (civility?.errors)!['required']"
        >{{ resource.error.civility }}</small
      >
    </div>

    <div class="md:col-span-2">
      <span class="p-float-label">
        <input type="text" pInputText formControlName="lastName" (keyup)="ToCapitalize($event)" />
        <label for="float-input" class="inputtext">{{ resource.form.lastName }}</label>
      </span>
      <small
        class="p-error"
        *ngIf="lastName?.touched && lastName?.invalid && (lastName?.errors)!['required']"
        >{{ resource.error.lastName }}</small
      >
      <small
        class="p-error"
        *ngIf="lastName?.touched && lastName?.value?.length > 50 && lastName?.invalid"
        >{{ resource.error.lastNameMaxLength }}</small
      >
      <small
        class="p-error"
        *ngIf="lastName?.touched && lastName?.invalid && (lastName?.errors)!['pattern']"
        >{{ resource.error.lastNameInvalid }}</small
      >
    </div>

    <div class="md:col-span-2">
      <span class="p-float-label">
        <input type="text" pInputText formControlName="firstName" (keyup)="ToCapitalize($event)" />
        <label for="float-input" class="inputtext">{{ resource.form.firstName }}</label>
      </span>
      <small
        class="p-error"
        *ngIf="firstName?.touched && firstName?.invalid && (firstName?.errors)!['required']"
        >{{ resource.error.firstName }}</small
      >
      <small
        class="p-error"
        *ngIf="firstName?.touched && firstName?.value?.length > 50 && firstName?.invalid"
        >{{ resource.error.firstNameMaxLength }}</small
      >
      <small
        class="p-error"
        *ngIf="firstName?.touched && firstName?.invalid && (firstName?.errors)!['pattern']"
        >{{ resource.error.firstNameInvalid }}</small
      >
    </div>

    <div class="md:col-span-2">
      <span class="p-float-label flex">
        <p-calendar
          [touchUI]="true"
          class="w-screen"
          formControlName="birthday"
          dataType="string"
          dateFormat="dd/mm/yy"
          [defaultDate]="maxDate"
          [maxDate]="maxDate"></p-calendar>
        <label for="float-input" class="calendar birthday"
          >{{ resource.form.birthday }}
          <span
            class="textInfoBottom"
            [ngClass]="{
              textInfoBottomError: birthday?.touched && birthday?.invalid && !birthday?.value
            }">
            {{ resource.subscriber.superior18 }}</span
          ></label
        >
        <img [src]="resource.payment.logo" class="calendarSrc" />
      </span>
      <small
        class="p-error"
        *ngIf="birthday?.touched && birthday?.invalid && (birthday?.errors)!['required']"
        >{{ resource.error.birthday }}</small
      >
    </div>

    <div id="country" class="md:col-span-2">
      <span class="p-float-label">
        <p-autoComplete
          class="w-full grid"
          (completeMethod)="filterCountries($event)"
          formControlName="birthdayCountry"
          [forceSelection]="true"
          [suggestions]="filteredCountries"
          [dropdown]="true"
          [placeholder]="resource.form.birthdayCountry"
          field="countryNameFr"
          [minLength]="3"></p-autoComplete>
        <label for="float-input" class="dropdown">{{ resource.form.birthdayCountry }}</label>
      </span>
      <small
        class="p-error"
        *ngIf="
          birthdayCountry?.touched &&
          birthdayCountry?.invalid &&
          (birthdayCountry?.errors)!['required']
        "
        >{{ resource.error.birthdayCountry }}</small
      >
    </div>

    <div class="md:col-span-2">
      <span class="p-float-label">
        <input type="text" pInputText formControlName="birthdayPlace" />
        <label for="float-input" class="inputtext">{{ resource.form.birthdayPlace }}</label>
      </span>
      <small
        class="p-error"
        *ngIf="
          birthdayPlace?.touched && birthdayPlace?.invalid && (birthdayPlace?.errors)!['required']
        "
        >{{ resource.error.birthdayPlace }}</small
      >
    </div>

    <app-phone-and-prefix
      (phoneControlsAdded)="addPhoneControls($event)"
      (phonePrefixesValues)="getPhonePrefixes($event)"
      [displayConsent]="false"
      class="w-full md:col-span-4"></app-phone-and-prefix>

    <div class="containerInfoPhone flex mx-auto md:col-span-4">
      <div class="containerLeftInfoPhone flex-none"></div>
      <div class="flex mt-4 mb-3">
        <div class="iconInfoPhone ml-3 mr-4 mt-1.5 md:mt-2.5"></div>
        <div class="grow pr-7">
          <p class="textInfoPhone">
            {{ resource.subscriber.infoPhone }}
          </p>
        </div>
      </div>
    </div>

    <div class="md:col-span-4" *ngIf="brand === 'HM' && !estClient">
      <app-subscriber-consent (consentChange)="validCheckboxes($event)"></app-subscriber-consent>
    </div>
  </div>

  <div class="mt-6 mb-5">
    <button
      pButton
      [disabled]="!this.subscriberForm.valid"
      class="mx-auto button-NS width-max iconSideLabel"
      [label]="resource.subscriber.buttonOffer"
      icon="pi pi-arrow-right"
      iconPos="right"
      (click)="confirmStep()"
      [loading]="isLoading"></button>
  </div>
</div>

<div class="mb-6" [hidden]="!emailStepConfirm">
  <div class="space-y-5" *ngIf="subscriberCGVm">
    <p class="textInfoBottom">{{ subscriberCGVm.text1CG }}</p>
    <p class="textInfoBottom">
      {{ subscriberCGVm.text2CG }}
      <span class="textBInfoBottom">{{ subscriberCGVm.text3CG }}</span>
    </p>
    <p class="textInfoBottom">
      {{ subscriberCGVm.text4CG }}
      <span class="textBInfoBottom"
        ><a style="cursor: pointer" (click)="goToLink(resource.subscriber.urlPersonalData)">{{
          subscriberCGVm.text5CG
        }}</a></span
      >.
    </p>
  </div>
</div>

<!--Email step verification-->
<div class="email-step-validation" [hidden]="emailStepConfirm">
  <div class="md:col-span-4">
    <span class="p-float-label">
      <input type="email" pInputText [formControl]="emailStep" (keyup)="ToLowerCase($event)" />
      <label for="float-input" class="inputtext">{{ resource.form.emailPersonnel }}</label>
    </span>
    <small
      class="p-error"
      *ngIf="emailStep?.touched && emailStep?.invalid && (emailStep?.errors)!['required']"
      >{{ resource.error.email }}</small
    >
    <small
      class="p-error"
      *ngIf="emailStep?.touched && emailStep?.invalid && (emailStep?.errors)!['pattern']"
      >{{ resource.error.emailformat }}</small
    >
    <small *ngIf="emailStep?.value && estClient" [innerHTML]="resource.form.wrongEmail"></small>
  </div>
  <div class="mt-6 mb-5">
    <button
      pButton
      [disabled]="!this.emailStep.valid"
      class="mx-auto button-NS width-max iconSideLabel"
      [label]="resource.subscriber.buttonEmailStep"
      icon="pi pi-arrow-right"
      iconPos="right"
      (click)="confirmEmailStep()"
      [loading]="isLoading"></button>
  </div>
</div>
<app-login-popup
  *ngIf="memberLoginPopup"
  [visible]="memberLoginPopup"
  [subscriberEmail]="emailStep.value"
  (closeDialogEmitter)="closeDialogLogin()"
  (closeAuthPopup)="closeAuthPopup()"
  (closeValidMfa)="closeValidLoginAndMfa($event)"
  (closeValidLogin)="closeValidLoginAndMfa($event)"></app-login-popup>

<app-popup-init-account
  *ngIf="initAccountPopup"
  [email]="emailStep.value"
  [visible]="initAccountPopup"
  (closePopup)="closeInitPopup()"></app-popup-init-account>
