import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OfferCustomizationState } from './offer-customization.state';

export const selectOfferCustomization =
  createFeatureSelector<OfferCustomizationState>('offerCustomization');

export const selectSaisieFormule = createSelector(
  selectOfferCustomization,
  (state: OfferCustomizationState) => state.SaisieFormule
);

export const selectSaisiePersonnalisation = createSelector(
  selectOfferCustomization,
  (state: OfferCustomizationState) => state.saisiePersonnalisation
);
