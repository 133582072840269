import { Component, HostListener, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatomoTracker } from 'ngx-matomo-client';
import { lastValueFrom, take } from 'rxjs';
import { AppResource } from 'src/app/app.resource';
import { PathType } from 'src/app/core/enums/path-type.enum';
import { State } from 'src/app/core/state/core.state';
import * as fromInfo from 'src/app/core/state/info';
import * as fromPath from 'src/app/core/state/path';
import { Resource } from 'src/app/resources/resource';
import { environment } from 'src/environments/environment';
import { MatomoConstants } from '../../constants/matomo.constants';
import { isMobile } from '../../utils/deviceUtils';

@Component({
  selector: 'app-web-callback',
  templateUrl: './web-callback.component.html',
  styleUrls: ['./web-callback.component.scss']
})
export class WebCallBackComponent implements OnInit {
  show!: boolean;
  resource: Resource;
  currentPage!: string;

  url!: string;
  path!: PathType;
  phoneNumber!: string;

  get brand() {
    return environment.brand;
  }

  constructor(
    private resources: AppResource,
    private store: Store<State>,
    private tracker: MatomoTracker
  ) {
    this.resource = resources['resource'];
    this.show = false;
    this.show = false;
  }

  async ngOnInit() {
    this.store.select(fromInfo.selectCurrentPage).subscribe(res => {
      this.currentPage = res;
    });
    this.path = await (
      await lastValueFrom(this.store.select(fromPath.selectPath).pipe(take(1)))!
    ).pathType;

    this.phoneNumber = this.getPhoneNumber(this.path);
  }

  clickFunction() {
    this.show = !this.show;
    if (this.show === true) {
      this.tracker.trackEvent(MatomoConstants.WEB_CALLBACK_TYPE, this.currentPage);
    }
  }

  callClientService(): void {
    if (isMobile()) {
      window.location.href = `tel:${this.phoneNumber}`;
    }
  }

  @HostListener('document:click', ['$event'])
  closeInfoModal() {
    this.show = false;
  }

  private getPhoneNumber(pathType: PathType) {
    switch (pathType) {
      case PathType.HOUSING_SOLIDARITY:
        return this.resource.webcallback['phoneAHS'];
      case PathType.HOUSING_PARIS:
        return this.resource.webcallback['phoneAHP'];
      case PathType.HOUSING_TOURCOING:
        return this.resource.webcallback['phoneAHT'];
      case PathType.HOUSING:
      default:
        return this.resource.webcallback['phoneAHC'];
    }
  }
}
