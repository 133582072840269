import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { lastValueFrom, take } from 'rxjs';
import { State } from 'src/app/core/state/core.state';
import * as fromSignature from 'src/app/core/state/electronic-signature';
import * as fromPayment from 'src/app/core/state/payment';
import * as fromContext from 'src/app/core/state/context';
import { CANAL_VENTE_C } from '../constants/context.constants';

@Injectable({
  providedIn: 'root'
})
export class SignatureGuard {
  constructor(private store: Store<State>, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var isPaymentValid = await (
      await lastValueFrom(this.store.select(fromPayment.selectPayment).pipe(take(1)))
    ).isValid;

    var isSignatureValid = await (
      await lastValueFrom(this.store.select(fromSignature.selectElectronicSignature).pipe(take(1)))
    ).isValid;

    const stateContextData = await lastValueFrom(
      this.store.select(fromContext.selectContextData).pipe(take(1))
    );

    if (isSignatureValid) {
      if (!state.url.includes('/confirmcontract')) {
        this.router.navigate(['/confirmcontract']);
        return false;
      }
      return true;
    }
    if (isPaymentValid && !state.url.includes('/electronicsignature')) {
      if (stateContextData?.canalVente !== CANAL_VENTE_C)
        this.router.navigate(['/electronicsignature']);
      return false;
    } else {
      return true;
    }
  }

  async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var isPaymentValid = await (
      await lastValueFrom(this.store.select(fromPayment.selectPayment).pipe(take(1)))
    ).isValid;

    var isSignatureValid = await (
      await lastValueFrom(this.store.select(fromSignature.selectElectronicSignature).pipe(take(1)))
    ).isValid;

    if (isSignatureValid) {
      if (!state.url.includes('/confirmcontract')) {
        this.router.navigate(['/confirmcontract']);
        return false;
      }
      return true;
    }
    if (isPaymentValid && !state.url.includes('/electronicsignature')) {
      this.router.navigate(['/electronicsignature']);
      return false;
    } else {
      return true;
    }
  }
}
