<app-header [headerStep]="headerStep" [local]="true"></app-header>
<div class="container mx-auto mb-6">
  <app-question [question]="question" [local]="true"></app-question>
  <div *ngIf="step1" class="my-6 space-y-4 md:grid md:grid-cols-2 md:gap-4 md:space-y-0">
    <div class="flex">
      <button
        pButton
        class="mx-auto buttonToggleSM"
        [label]="resource.cancellation.insurerChange"
        [ngClass]="setClassActive(insurerChange)"
        (click)="nextStep1(cancellationEnum.InsurerChange)"></button>
    </div>
    <div class="flex">
      <button
        pButton
        class="mx-auto buttonToggleSM"
        [label]="resource.cancellation.moveIn"
        [ngClass]="setClassActive(moveIn)"
        (click)="nextStep1(cancellationEnum.MoveIn)"></button>
    </div>
  </div>

  <div *ngIf="step2" class="my-6 space-y-4 md:grid md:grid-cols-2 md:gap-4 md:space-y-0">
    <div class="flex">
      <button
        pButton
        class="mx-auto buttonToggleSM"
        [label]="resource.cancellation.lessOneYear"
        [ngClass]="setClassActive(lessOneYear)"
        (click)="nextStep2(cancellationEnum.LessOneYear)"></button>
    </div>
    <div class="flex">
      <button
        pButton
        class="mx-auto buttonToggleSM"
        [label]="resource.cancellation.moreOneYear"
        [ngClass]="setClassActive(moreOneYear)"
        (click)="nextStep2(cancellationEnum.MoreOneYear)"></button>
    </div>
  </div>

  <div *ngIf="!step3">
    <div class="flex my-4">
      <button
        pButton
        class="mx-auto button-outline-call-SV width-max iconSideLabel"
        [label]="resource.nextstep.skip"
        iconPos="right"
        icon="pi pi-arrow-right"
        (click)="skipStep()"></button>
    </div>
  </div>

  <div *ngIf="step3" [formGroup]="cancellationForm" class="my-6">
    <div class="space-y-4">
      <div class="">
        <span class="p-float-label">
          <p-dropdown
            class="width-max"
            (onChange)="autoSetAdress($event)"
            [options]="insurances"
            [(ngModel)]="selectedInsurance"
            optionLabel="text"
            [emptyFilterMessage]="resource.form.noResultFilterInsurer"
            [placeholder]="resource.cancellation.name"
            [filter]="true"
            [resetFilterOnHide]="true"
            formControlName="insurer"></p-dropdown>
          <label for="float-input" class="dropdown">{{ resource.cancellation.name }}</label>
        </span>
        <small
          class="p-error"
          *ngIf="insurer?.touched && insurer?.invalid && (insurer?.errors)!['required']"
          >{{ resource.error.insurer }}</small
        >
        <p
          class="cursor-pointer underline"
          *ngIf="insurerNotFind"
          (click)="onNotFoundInsurerClick()">
          {{ resource.cancellation.notFind }}
        </p>
      </div>
      <div *ngIf="otherInsurer">
        <span class="p-float-label">
          <input type="text" pInputText formControlName="other" />
          <label for="float-input" class="inputtext">{{ resource.cancellation.other }}</label>
        </span>
        <small
          class="p-error"
          *ngIf="other?.touched && other?.invalid && (other?.errors)!['required']"
          >{{ resource.error.other }}</small
        >
      </div>
      <div class="">
        <span class="p-float-label">
          <input type="text" pInputText formControlName="address" [readonly]="!otherInsurer" />
          <label for="float-input" class="inputtext">{{ resource.cancellation.address }}</label>
        </span>
        <small
          class="p-error"
          *ngIf="address?.touched && address?.invalid && (address?.errors)!['required']"
          >{{ resource.error.address }}</small
        >
      </div>
      <div class="">
        <span class="p-float-label">
          <input type="text" pInputText formControlName="postalCode" [readonly]="!otherInsurer" />
          <label for="float-input" class="inputtext">{{ resource.cancellation.postalCode }}</label>
        </span>
        <small
          class="p-error"
          *ngIf="postalCode?.touched && postalCode?.invalid && (postalCode?.errors)!['required']"
          >{{ resource.error.postalCode }}</small
        >
        <small
          class="p-error"
          *ngIf="postalCode?.touched && postalCode?.invalid && (postalCode?.errors)!['pattern']"
          >{{ resource.error.postalCodeWrong }}</small
        >
      </div>
      <div class="">
        <span class="p-float-label">
          <input type="text" pInputText formControlName="city" [readonly]="!otherInsurer" />
          <label for="float-input" class="inputtext">{{ resource.cancellation.city }}</label>
        </span>
        <small
          class="p-error"
          *ngIf="city?.touched && city?.invalid && (city?.errors)!['required']"
          >{{ resource.error.city }}</small
        >
      </div>
      <div class="">
        <span class="p-float-label">
          <input type="text" pInputText formControlName="contractNumber" />
          <label for="float-input" class="inputtext">{{
            resource.cancellation.contractNumber
          }}</label>
        </span>
        <small
          class="p-error"
          *ngIf="
            contractNumber?.touched &&
            contractNumber?.invalid &&
            (contractNumber?.errors)!['required']
          "
          >{{ resource.error.contractNumber }}</small
        >
      </div>
      <div *ngIf="lessOneYear">
        <span class="p-float-label">
          <p-calendar
            [(ngModel)]="monthStarting"
            view="month"
            formControlName="startingContract"
            dateFormat="mm/yy"></p-calendar>
          <label for="float-input" class="mask">{{ resource.cancellation.startingContract }}</label>
        </span>
        <small
          class="p-error"
          *ngIf="
            startingContract?.touched &&
            startingContract?.invalid &&
            (startingContract?.errors)!['required']
          "
          >{{ resource.error.monthStarting }}</small
        >
      </div>
    </div>
    <div class="my-4">
      <div class="flex">
        <button
          pButton
          [disabled]="!cancellationForm.valid"
          type="button"
          class="button-NS w-full"
          [label]="resource.nextstep.validate"
          (click)="nextStep3()"></button>
      </div>
      <div class="flex my-4">
        <button
          pButton
          class="mx-auto button-outline-call-SV width-max iconSideLabel"
          [label]="resource.nextstep.skip"
          iconPos="right"
          icon="pi pi-arrow-right"
          (click)="skipStep()"></button>
      </div>
    </div>
  </div>
</div>

<div class="container mx-auto h-28 md:h-40">
  <app-footer class="footer"></app-footer>
</div>
<app-web-callback></app-web-callback>

<p-dialog
  [(visible)]="displayMoveInModal"
  [modal]="true"
  [style]="{ width: '330px', height: '455px', margin: '1rem' }"
  [closable]="false"
  [showHeader]="false"
  [baseZIndex]="10000">
  <p class="title-P my-5">{{ resource.cancellation.importantModal }}</p>
  <p class="text-P my-5">{{ resource.cancellation.moveIn1 }}</p>
  <p class="text-P">{{ resource.cancellation.moveIn2 }}</p>
  <p class="text-P my-1" [innerHTML]="resource.cancellation.moveIn3"></p>
  <p class="text-P my-1">
    {{ resource.cancellation.moveIn4 }}
    <span class="textBModalStep"
      ><a style="cursor: pointer" (click)="goToLink(urlModeleCourrier)">{{
        resource.cancellation.YearHref
      }}</a></span
    >
  </p>
  <div class="flex justify-center mt-8">
    <button
      pButton
      type="button"
      class="button-outline-call-SV w-full"
      [label]="resource.cancellation.skipButton"
      (click)="nextStepMoveInModal()"></button>
  </div>
</p-dialog>

<p-dialog
  [(visible)]="displayLessOneYearModal"
  [modal]="true"
  [style]="{ width: '330px', height: '540px', margin: '1rem' }"
  [closable]="false"
  [showHeader]="false"
  [baseZIndex]="10000">
  <p class="title-P my-5">{{ resource.cancellation.importantModal }}</p>
  <p class="text-P my-5">{{ resource.cancellation.lessOneYear1 }}</p>
  <p class="text-P">{{ resource.cancellation.lessOneYear2 }}</p>
  <p class="text-P my-2">{{ resource.cancellation.lessOneYear3 }}</p>
  <p class="text-P my-2">
    {{ resource.cancellation.lessOneYear4 }}
    <span class="textBModalStep">
      <a style="cursor: pointer" (click)="goToLink(urlModeleCourrier)">{{
        resource.cancellation.YearHref
      }}</a>
    </span>
  </p>
  <div class="flex justify-center mt-8">
    <button
      pButton
      type="button"
      class="button-outline-call-SV w-full"
      [label]="resource.cancellation.skipButton"
      (click)="endOfWay()"></button>
  </div>
</p-dialog>

<p-dialog
  [(visible)]="displayMoreOneYearModal"
  [modal]="true"
  [style]="{ width: '330px', height: '650px', margin: '1rem' }"
  [closable]="false"
  [showHeader]="false"
  [baseZIndex]="10000">
  <p class="title-P my-5">{{ resource.cancellation.importantModal }}</p>
  <p class="text-P my-4">{{ resource.cancellation.textModal1 }}</p>
  <div class="flex my-4">
    <div class="flex-none w-8 step1Enable background-norepeat mt-[15px]"></div>
    <div class="grow textModalStep mx-3">
      {{ resource.cancellation.textModal2 }}
    </div>
  </div>
  <div class="flex my-4">
    <div class="flex-none w-8 step2Enable background-norepeat mt-[15px]"></div>
    <div class="grow textModalStep mx-3">
      {{ resource.cancellation.textModal3 }}
    </div>
  </div>
  <div class="flex justify-center mt-6">
    <button
      pButton
      type="button"
      class="button-SV w-full mb-4"
      [label]="resource.cancellation.confirmNextStepT"
      (click)="confirmNextStep()"></button>
  </div>
  <div class="flex justify-center mt-2">
    <button
      pButton
      type="button"
      class="button-outline-call-SV w-full"
      style="font-size: 14px; padding: 8px 12px"
      [label]="resource.cancellation.skipStepT"
      (click)="skipStep()"></button>
  </div>

  <div class="mt-6 mb-4">
    <p class="text-P">
      <span class="textBModalStep">
        <a style="cursor: pointer" (click)="goToLink(urlModeleCourrier)">{{
          resource.cancellation.textModal5
        }}</a>
      </span>
      {{ resource.cancellation.textModal6 }}
    </p>
  </div>
  <p class="text-P" [innerHTML]="resource.cancellation.textModal4"></p>
</p-dialog>

<p-dialog
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '330px', height: '400px', margin: '1rem' }"
  [closable]="false"
  [showHeader]="false"
  [baseZIndex]="10000">
  <p class="title-P text-center my-5">{{ resource.cancellation.cancellationModal }}</p>
  <p class="text-P my-5">{{ resource.cancellation.textModalC1 }}</p>
  <p class="textDate text-center my-5">{{ cancellationVm.dateModal }}</p>
  <div class="flex justify-center mt-8">
    <button
      pButton
      type="button"
      class="button-NS w-full"
      [label]="resource.cancellation.validResiliationC"
      (click)="validResiliation()"></button>
  </div>
</p-dialog>
