//#region Imports
import { NgModule } from '@angular/core';
import { SharedModule } from './shared.module';
//#endregion

//#region Contract
import { CancellationComponent } from 'src/app/views/contract/cancellation/cancellation.component';
import { ContractConfirmationComponent } from 'src/app/views/contract/contract-confirmation/contract-confirmation.component';
import { ESignMsgAdvisorComponent } from 'src/app/views/contract/e-sign-msg-advisor/e-sign-msg-advisor.component';
import { ESignMsgComponent } from 'src/app/views/contract/e-sign-msg/e-sign-msg.component';
import { ElectronicSignatureComponent } from 'src/app/views/contract/electronic-signature/electronic-signature.component';
import { LessorCertificateComponent } from 'src/app/views/contract/lessor-certificate/lessor-certificate.component';
import { PaymentComponent } from 'src/app/views/contract/payment/payment.component';
import { ServicesComponent } from 'src/app/views/contract/services/services.component';
//#endregion

@NgModule({
  declarations: [
    ServicesComponent,
    LessorCertificateComponent,
    CancellationComponent,
    PaymentComponent,
    ElectronicSignatureComponent,
    ContractConfirmationComponent,
    ESignMsgComponent,
    ESignMsgAdvisorComponent
  ],

  imports: [SharedModule],

  exports: [
    ServicesComponent,
    CancellationComponent,
    PaymentComponent,
    ElectronicSignatureComponent,
    ContractConfirmationComponent,
    ESignMsgComponent,
    ESignMsgAdvisorComponent
  ]
})
export class ContractModule {}
