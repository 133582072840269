// src/app/core/state/menus/menus.reducer.ts

import { Action, createReducer, on } from '@ngrx/store';
import * as OfferCustomizationActions from './offer-customization.actions';
import { initialState, OfferCustomizationState } from './offer-customization.state';

const offerCustomizationReducer = createReducer(
  initialState,
  on(
    OfferCustomizationActions.patchOfferCustomization,
    (state, action: ReturnType<typeof OfferCustomizationActions.patchOfferCustomization>) => ({
      ...state,
      saisiePersonnalisation: { ...state.saisiePersonnalisation!, ...action.payload }
    })
  ),
  on(
    OfferCustomizationActions.patchSaisieFormule,
    (state, action: ReturnType<typeof OfferCustomizationActions.patchSaisieFormule>) => ({
      ...state,
      SaisieFormule: { ...state.SaisieFormule!, ...action.payload }
    })
  ),
  on(
    OfferCustomizationActions.changeValidationStatus,
    (state, { isValid }: ReturnType<typeof OfferCustomizationActions.changeValidationStatus>) => ({
      ...state,
      isValid
    })
  ),
  on(
    OfferCustomizationActions.patchFormuleRecommandee,
    (
      state,
      { FormuleRecommandee }: ReturnType<typeof OfferCustomizationActions.patchFormuleRecommandee>
    ) => ({
      ...state,
      FormuleRecommandee
    })
  ),
  on(OfferCustomizationActions.init, state => ({
    ...state,
    ...initialState
  }))
);

export function reducer(state: OfferCustomizationState | undefined, action: Action) {
  return offerCustomizationReducer(state, action);
}
