<ng-container [formGroup]="phoneGroup">
  <div>
    <div class="w-full md:col-span-2">
      <div class="flex items-center mb-2 mt-3">
        <p-dropdown
          formControlName="phonePrefix"
          [options]="phonePrefixes"
          (onChange)="onPhonePrefixChange($event)"
          optionLabel="prefix"
          (keydown)="onDropdownKeyDown($event)"
          class="w-1/4 mr-2">
          <ng-template let-phonePrefixes pTemplate="item">
            <div class="flex justify-between items-center font-bold">
              <div class="mr-4">{{ phonePrefixes.region }}</div>
              <div>{{ phonePrefixes.prefix }}</div>
            </div>
          </ng-template>
        </p-dropdown>
        <span class="p-float-label w-3/4 ml-2">
          <p-inputMask
            #phoneInput
            type="text"
            [placeholder]="phonePattern"
            formControlName="phone"
            pattern="[0-9]*"
            pKeyFilter="int"
            [autoClear]="false"
            [unmask]="true"
            [mask]="phoneMask"
            (onBlur)="onPhoneBlur()"
            (onInput)="onInput()"
            (onFocus)="onPhoneFocus()">
          </p-inputMask>
          <label for="float-input" class="inputtext">{{
            isPhoneMandatory ? resource.form.phoneMandatory : resource.form.phone
          }}</label>
        </span>
      </div>
      <small class="p-error" *ngIf="showPhoneError && phone?.errors?.['required']">
        {{ resource.error.phoneMandatory }}</small
      >
      <div *ngIf="displayPaymentInfos && phone?.dirty">
        <small for="float-input" class="inputtext">{{ resource.payment.phoneInfo }}</small>
      </div>
      <div *ngIf="displayPaymentInfos && isPhonePrefilled">
        <label for="float-input" class="inputtext">{{ resource.payment.phonePrefilled }}</label>
      </div>
      <small class="p-error" *ngIf="showPhoneError && phone?.errors?.['invalidPhoneNumber']">
        {{ resource.error.phone }} ({{ phonePattern }})
      </small>
      <small
        class="p-error"
        *ngIf="showPhoneError && phone?.errors?.['invalidPhoneNumberFrance'] && !phone?.errors?.['invalidPhoneNumber']">
        {{ resource.error.phone }}
      </small>
    </div>
    <div class="w-full md:col-span-2" *ngIf="!isPhonePrefilled && displayConsent">
      <div class="panelSV width-max inactive" [ngClass]="setClassActive(isPhoneConsent)">
        <div class="flex mx-2.5 my-3">
          <div class="basis-5/6 mr-1">
            <p class="textPanel-SV" [innerHTML]="resource.payment.textPhoneConsent"></p>
          </div>
          <div class="basis-1/6 text-right">
            <p-inputSwitch
              (click)="setIsPhoneConsent()"
              formControlName="isPhoneConsent"></p-inputSwitch>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
