<app-header [headerStep]="headerStep" [local]="true"></app-header>
<div class="p-6">
  <div class="title">
    <h1 class="text-2xl text-black font-bold">{{ resource.eSignMessageAdvisor.title }}</h1>
  </div>
  <div class="body">
    <p class="mt-4">
      {{ resource.eSignMessageAdvisor.text1 }}
    </p>
    <p class="mt-4">
      {{ resource.eSignMessageAdvisor.text2 }}
    </p>
    <p class="mt-4 font-bold">
      {{ resource.eSignMessageAdvisor.text3 }}
    </p>
  </div>
</div>
