<div class="container-infotop my-5" *ngIf="infoTop && infoTop.isVisible">
  <div class="flex md:items-center md:flex-col md:mx-auto md:content-center">
    <div class="flex">
      <img class="mx-4 my-4" [src]="infoTop.logo" />
      <div class="text-left my-4">
        <p class="title-infotop">{{ infoTop.title }}</p>
        <p class="title-infotop">
          {{ infoTop.secondTitle }}
          <span class="subtitle-infotop">{{ infoTop.subTitle }}</span>
        </p>
      </div>
    </div>
  </div>
</div>
