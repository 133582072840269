export class DateUtils {
  private static isoDatePattern =
    /^(?<year>\d{4})-(?<month>\d{2})-(?<day>\d{2})T\d{2}:\d{2}:\d{2}.\d{3}Z$/m;
  private static dayMonthYearDatePattern = /^(?<day>\d{2})\/(?<month>\d{2})\/(?<year>\d{4})$/m;

  static IsoToDayMonthYear(isoDate: string | undefined): string | null {
    if (isoDate === undefined) return null;

    const match = isoDate.match(DateUtils.isoDatePattern);
    if (match === null) return null;
    return `${match.groups!['day']}/${match.groups!['month']}/${match.groups!['year']}`;
  }

  static DayMonthYearToIso(dayMonthYearDate: string | undefined): string | null {
    if (dayMonthYearDate === undefined) return null;

    const match = dayMonthYearDate.match(DateUtils.dayMonthYearDatePattern);
    if (match === null) return null;
    return `${match.groups!['year']}-${match.groups!['month']}-${
      match.groups!['day']
    }T00:00:00.000Z`;
  }

  static DateToDayMonthYear(date: Date | undefined): string | null {
    if (date === undefined || !date) return null;

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
  }
}
