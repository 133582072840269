import { createAction, props } from '@ngrx/store';
import { Esh, Selection, Selectionne } from '../../models/form-state.model';

export const patchEsh = createAction(
  '[Address Page] Patch Value Esh',
  props<{ payload: Partial<Esh> }>()
);

export const patchManuelle = createAction(
  '[Address Page] Patch Value Manuelle',
  props<{ payload: Partial<Esh> }>()
);

export const patchSelection = createAction(
  '[Address Page] Patch Value Selection',
  props<{ payload: Partial<Selection> }>()
);

export const patchSelectedAddress = createAction(
  '[Address Page] Patch Selected Address',
  props<{ adresseType: Partial<Selectionne> }>()
);

export const changeValidationStatus = createAction(
  '[Address Page] Change Validation Status',
  props<{ isValid: boolean }>()
);

export const changeSpecificCityAddressStatus = createAction(
  '[Address Page] Change Paris Address Status',
  props<{ isParis: boolean; isTourcoing: boolean }>()
);

export const changeZIEligibleAHPStatus = createAction(
  '[Address Page] Change Zone Inondable & Eligible AHP Status',
  props<{ isZoneInondable: boolean; isEligibleAHP: boolean }>()
);

export const init = createAction('[Init]');
