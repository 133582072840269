import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MatomoTracker } from 'ngx-matomo-client';
import { lastValueFrom, take } from 'rxjs';
import { AppResource } from 'src/app/app.resource';
import { PathType } from 'src/app/core/enums/path-type.enum';
import { QualiteOccupant } from 'src/app/core/models/form-state.model';
import { Question } from 'src/app/core/models/question.model';
import { cloneResumeStep } from 'src/app/core/models/resume-step.model';
import { Step } from 'src/app/core/models/step.model';
import { Stepper } from 'src/app/core/models/stepper.model';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component.component';
import { MatomoConstants } from 'src/app/shared/constants/matomo.constants';
import * as fromAddress from '../../../core/state/address';
import { State } from '../../../core/state/core.state';
import * as fromHousing from '../../../core/state/housing';
import * as fromInfo from '../../../core/state/info';
import * as fromPath from '../../../core/state/path';

@Component({
  selector: 'app-owner-tenant',
  templateUrl: './owner-tenant.component.html',
  styleUrls: ['./owner-tenant.component.scss']
})
export class OwnerTenantComponent extends BaseComponent implements OnInit {
  mouseOwnerOvered = false;
  mouseTenantOvered = false;

  tenantSelected = false;
  ownerSelected = false;

  path!: PathType;
  isParis!: boolean;
  isTourcoing!: boolean;

  override step: Step = {
    step: this.resource.header.stepHousing,
    stepNumber: 1,
    subStep: this.resource.header.subStepOwnerTenant,
    subStepNumber: 3
  };

  override question: Question = {
    libelle: this.resource.question.ownerTenant,
    popupInfo: undefined
  };

  public override currentPage = MatomoConstants.OCCUPATION;

  constructor(
    store: Store<State>,
    resources: AppResource,
    tracker: MatomoTracker,
    private router: Router
  ) {
    super(store, resources, tracker);
  }

  override async ngOnInit() {
    super.ngOnInit();
    this.updateResumeStepVisibility(true);
    this.loadStateData();
  }

  private async loadStateData() {
    var housingData = await lastValueFrom(
      this.store.select(fromHousing.selectHousing).pipe(take(1))
    );

    switch (housingData.SaisieHabitation.QualiteOccupant) {
      case QualiteOccupant.Proprietaire:
        this.ownerSelected = true;
        break;
      case QualiteOccupant.Locataire:
        this.tenantSelected = true;
        break;
    }

    this.path = await lastValueFrom(this.store.select(fromPath.selectPathType).pipe(take(1)));
    this.isParis = await lastValueFrom(this.store.select(fromAddress.selectIsParis).pipe(take(1)));
    this.isTourcoing = await lastValueFrom(
      this.store.select(fromAddress.selectIsTourcoing).pipe(take(1))
    );
  }

  async onTenantSelected() {
    this.tenantSelected = !this.tenantSelected;
    if (this.tenantSelected) {
      this.ownerSelected = false;
    }

    this.store.dispatch(
      fromHousing.patchOccupantType({
        payload: { QualiteOccupant: QualiteOccupant.Locataire }
      })
    );

    if (this.isParis)
      this.store.dispatch(
        fromPath.updateType({
          pathType: PathType.HOUSING_PARIS
        })
      );
    else if (this.isTourcoing)
      this.store.dispatch(
        fromPath.updateType({
          pathType: PathType.HOUSING_TOURCOING
        })
      );
    this.nextStep();
  }

  async onOwnerSelected() {
    this.ownerSelected = !this.ownerSelected;
    if (this.ownerSelected) {
      this.tenantSelected = false;
    }

    this.store.dispatch(
      fromHousing.patchOccupantType({
        payload: { QualiteOccupant: QualiteOccupant.Proprietaire }
      })
    );

    if (this.ownerSelected && this.path == PathType.HOUSING) {
      this.tracker.trackEvent(
        MatomoConstants.AHP_TO_AHC,
        MatomoConstants.OCCUPATION,
        MatomoConstants.OCCUPATION_AHC
      );
    }
    this.store.dispatch(
      fromPath.updateType({
        pathType: PathType.HOUSING
      })
    );
    this.nextStep();
  }

  async nextStep() {
    await this.updateStepper();
    await this.updateResumeStep();

    this.router.navigate(['/primarysecondary']);
  }

  async updateStepper() {
    let stepper = await lastValueFrom(this.store.select(fromInfo.selectStepper).pipe(take(1)));
    var tmpStepper = JSON.parse(JSON.stringify(stepper)) as Stepper;

    let title = tmpStepper.devis.titles.find(
      (x: { libelle: string }) => x.libelle == this.resource.stepper.lodging
    );

    let subtitleS = title?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.ownertenant
    );

    if (subtitleS) subtitleS.isValid = true;

    let subtitleN;
    subtitleN = title?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.primarysecondary
    );

    if (subtitleN) {
      subtitleN.isActive = true;
      subtitleN.isVisible = true;
    }

    this.store.dispatch(fromInfo.updateStepper({ payload: tmpStepper }));
  }

  async updateResumeStep() {
    const resumeStep = await lastValueFrom(
      this.store.select(fromInfo.selectCurrentResumeStep).pipe(take(1))
    );

    const tmpResumeStep = cloneResumeStep(resumeStep);

    let stepResumeStep = tmpResumeStep.steps?.find(
      x => x.id == this.resource.resumestep.ownertenant
    );
    let libelle = '';

    if (this.ownerSelected) libelle = this.resource.resumestep.owner;
    else if (this.tenantSelected) libelle = this.resource.resumestep.tenant;

    if (stepResumeStep && libelle != '') {
      stepResumeStep.step = libelle;
      stepResumeStep.isVisible = true;
    } else {
      tmpResumeStep.steps?.push({
        id: this.resource.resumestep.ownertenant,
        step: libelle,
        url: this.resource.resumestep.ownertenant,
        isVisible: true
      });
    }

    this.store.dispatch(fromInfo.updateResumeStep({ payload: tmpResumeStep }));
  }

  setClassActive(value: boolean): string {
    return value ? 'active' : 'inactive';
  }
}
