<div *ngIf="confirmContractVm">
  <div class="md:container md:grid md:grid-cols-2 md:gap-4 md:mb-6 md:mx-auto">
    <div class="containerPanel-CC mx-auto mt-6">
      <div class="containerText-CC pt-24 px-14">
        <p class="title-CC text-center mt-3">
          {{ resource.confirmcontract.title }}
        </p>
        <p class="subtitle-CC mt-3">{{ resource.confirmcontract.subtitle }}</p>
      </div>
      <div class="containerTextSB-CC text-left mt-3 mx-4">
        <p *ngIf="brand === 'HM'; else textOtherBrand">
          {{ resource.confirmcontract.text1 }}
          <span class="textB-CC">{{ confirmContractVm.reference }}</span>
          {{ resource.confirmcontract.text2 }}
          <a href="https://harmonie-et-moi.fr/" class="confirm-text-3">{{
            resource.confirmcontract.text3
          }}</a>
          {{ resource.confirmcontract.text4 }}
        </p>
        <ng-template #textOtherBrand>
          <p>
            {{ resource.confirmcontract.text1 }}
            <span class="textB-CC">{{ confirmContractVm.reference }}</span>
            {{ resource.confirmcontract.text2 }}
          </p>
        </ng-template>
      </div>
    </div>

    <div class="flex flex-row justify-center items-center mb-6 md:mb-0">
      <div class="mt-8">
        <div class="titleDoc-CC mb-4 md:text-center">
          <p>{{ resource.confirmcontract.docDownload }}</p>
        </div>
        <div class="space-y-3">
          <div class="containerSubtitleDoc-CC">
            <div class="mx-auto my-2">
              <a (click)="DownloadDocument(PolicyDocumentType.CP)" class="flex py-4 px-4">
                <div class="flex-grow">
                  <p class="subtitleDoc-CC">
                    {{ resource.confirmcontract.contractDocDescription }}
                  </p>
                </div>
                <div class="flex-none w-5">
                  <img [src]="resource.confirmcontract.logoPdf" /></div
              ></a>
            </div>
          </div>
          <div class="containerSubtitleDoc-CC">
            <div class="mx-auto my-2">
              <a (click)="DownloadDocument(PolicyDocumentType.FIC)" class="flex py-4 px-4">
                <div class="flex-grow">
                  <p class="subtitleDoc-CC">
                    {{ resource.confirmcontract.ficDocDescription }}
                  </p>
                </div>
                <div class="flex-none w-5">
                  <img [src]="resource.confirmcontract.logoPdf" /></div
              ></a>
            </div>
          </div>
          <div class="containerSubtitleDoc-CC">
            <div class="mx-auto my-2">
              <a (click)="DownloadDocument(PolicyDocumentType.SEPA)" class="flex py-4 px-4">
                <div class="flex-grow">
                  <p class="subtitleDoc-CC">
                    {{ resource.confirmcontract.mandatSepaDescription }}
                  </p>
                </div>
                <div class="flex-none w-5">
                  <img [src]="resource.confirmcontract.logoPdf" /></div
              ></a>
            </div>
          </div>
          <div class="containerSubtitleDoc-CC">
            <div class="mx-auto my-2">
              <a (click)="DownloadDocument(PolicyDocumentType.CG)" class="flex py-4 px-4">
                <div class="flex-grow">
                  <p class="subtitleDoc-CC">
                    {{ resource.confirmcontract.cgDocDescription }}
                  </p>
                </div>
                <div class="flex-none w-5">
                  <img [src]="resource.confirmcontract.logoPdf" /></div
              ></a>
            </div>
          </div>
          <div class="containerSubtitleDoc-CC">
            <div class="mx-auto my-2">
              <a (click)="DownloadDocument(PolicyDocumentType.ATTASSU)" class="flex py-4 px-4">
                <div class="flex-grow">
                  <p class="subtitleDoc-CC">
                    {{ resource.confirmcontract.attestationDocDescription }}
                  </p>
                </div>
                <div class="flex-none w-5">
                  <img [src]="resource.confirmcontract.logoPdf" /></div
              ></a>
            </div>
          </div>
          <div
            *ngFor="let attScol of confirmContractVm.attestationScolaire; let index = index"
            class="containerSubtitleDoc-CC">
            <div class="mx-auto my-2">
              <a
                (click)="DownloadDocument(PolicyDocumentType.ATTSCO, index)"
                class="flex py-4 px-4">
                <div class="flex-grow">
                  <p class="subtitleDoc-CC">
                    {{ resource.confirmcontract.attestationDocScolaire }} {{ index + 1 }}
                  </p>
                </div>
                <div class="flex-none w-5">
                  <img [src]="resource.confirmcontract.logoPdf" /></div
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="brand === 'HM'">
  <button type="button" class="button-NS mx-auto mt-16">
    <a href="https://www.harmonie-mutuelle.fr/">{{ resource.confirmcontract.quitContract }}</a>
  </button>
</div>

<div class="container mx-auto h-28 md:h-40">
  <app-footer class="footer"></app-footer>
</div>

<app-web-callback></app-web-callback>
