import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, shareReplay, tap } from 'rxjs';
import { REFRESH_TOKEN, TOKEN } from 'src/app/shared/constants/localstorage.constants';
import { USE_COOKIES_FALSE, USE_COOKIES_TRUE } from 'src/app/shared/constants/url.constants';
import { Configuration } from '../configuration';
import { LoginResponse } from '../models/login-response.model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  private emailSource = new BehaviorSubject<string>('');
  currentEmail = this.emailSource.asObservable();

  private passwordSource = new BehaviorSubject<string>('');
  currentPassword = this.passwordSource.asObservable();

  public configuration = new Configuration();
  url: string;
  apiEndpoint: string = 'authentication/';

  constructor(private http: HttpClient, configuration: Configuration) {
    this.url = configuration.apiBasePath ?? '';
  }

  sendLogin(
    email: string,
    password: string,
    twoFactorCode: string,
    useCookies: boolean
  ): Observable<LoginResponse> {
    const body = {
      email: email,
      password: password,
      twoFactorCode: twoFactorCode
    };

    let url = this.url + this.apiEndpoint + 'login';
    if (useCookies) {
      url += USE_COOKIES_TRUE;
    } else {
      url += USE_COOKIES_FALSE;
    }

    return this.http.post<LoginResponse>(url, body, { withCredentials: true }).pipe(
      //Pour éviter de faire plusieurs appels à l'API
      shareReplay(),
      tap(response => this.loginUser(response, useCookies))
    );
  }

  sendToken(email: string) {
    let query = new HttpParams();
    if (email) {
      query = query.append('email', email);
    }
    return this.http.post(
      this.url + this.apiEndpoint + 'twoFactor/sendToken',
      {},
      { params: query }
    );
  }

  verifyToken(email: string, token: string) {
    let query = new HttpParams();
    if (email) {
      query = query.append('email', email);
      query = query.append('token', token);
    }
    return this.http.post(
      this.url + this.apiEndpoint + 'twoFactor/verifyToken',
      {},
      { params: query }
    );
  }

  sharedEmail(email: string) {
    this.emailSource.next(email);
  }

  sharedPassword(password: string) {
    this.passwordSource.next(password);
  }

  private loginUser(token: LoginResponse, useCookies: boolean) {
    if (!useCookies) {
      localStorage.setItem(TOKEN, JSON.stringify(token.accessToken));
      localStorage.setItem(REFRESH_TOKEN, JSON.stringify(token.refreshToken));
      this.isAuthenticatedSubject.next(true);
    }
  }
}
