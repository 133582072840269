/**
 * VyV Conseil MRH Public API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type VyVSharedCodeFormule = 'F1' | 'F2' | 'LM';

export const VyVSharedCodeFormule = {
  F1: 'F1' as VyVSharedCodeFormule,
  F2: 'F2' as VyVSharedCodeFormule,
  LM: 'LM' as VyVSharedCodeFormule
};
