export type PolicyDocumentType = 'CP' | 'FIC' | 'SEPA' | 'CG' | 'ATTSCO' | 'ATTASSU';

export const PolicyDocumentType = {
  CP: 'CP' as PolicyDocumentType,
  FIC: 'FIC' as PolicyDocumentType,
  SEPA: 'SEPA' as PolicyDocumentType,
  CG: 'CG' as PolicyDocumentType,
  ATTSCO: 'ATTSCO' as PolicyDocumentType,
  ATTASSU: 'ATTASSU' as PolicyDocumentType
};
