/**
 * VyV Conseil MRH Public API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { VyVTarifEnfantInfo } from './vyVTarifEnfantInfo';

/**
 * Saisie des informations sur la personalisation de l'offre
 */
export interface VyVTarifSaisiePersonnalisation {
  MontantValeurObjets2000: VyVTarifSaisiePersonnalisation.MontantValeurObjets2000Enum;
  ProtectionJuridique: boolean;
  ProtectionVelos: boolean;
  ProtectionNomades: boolean;
  AssuranceScolaire: Array<VyVTarifEnfantInfo>;
}
export namespace VyVTarifSaisiePersonnalisation {
  export type MontantValeurObjets2000Enum = 'Montant5000' | 'Montant10000' | 'Montant15000';
  export const MontantValeurObjets2000Enum = {
    Montant5000: 'Montant5000' as MontantValeurObjets2000Enum,
    Montant10000: 'Montant10000' as MontantValeurObjets2000Enum,
    Montant15000: 'Montant15000' as MontantValeurObjets2000Enum
  };
}
