import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HousingState } from './housing.state';

export const selectHousing = createFeatureSelector<HousingState>('housing');

export const selectHousingType = createSelector(
  selectHousing,
  (state: HousingState) => state.SaisieHabitation.TypeHabitation
);

export const selectHousingNature = createSelector(
  selectHousing,
  (state: HousingState) => state.SaisieHabitation.NatureResidence
);

export const selectHousingOccupantType = createSelector(
  selectHousing,
  (state: HousingState) => state.SaisieHabitation.QualiteOccupant
);

export const selectRoomsNumberValidity = createSelector(
  selectHousing,
  (state: HousingState) => state.roomsValid
);
