import { ActionReducerMap, INIT, MetaReducer } from '@ngrx/store';
import { State } from './core.state';
import * as AddressReducer from './address/address.reducer';
import * as PathReducer from './path/path.reducer';
import * as SubscriberReducer from './subscriber/subscriber.reducer';
import * as OfferCustomizationReducer from './offer-customization/offer-customization.reducer';
import * as ServiceReducer from './service/service.reducer';
import * as PaymentReducer from './payment/payment.reducer';
import * as ElectronicSignatureReducer from './electronic-signature/electronic-signature.reducer';
import * as HousingReducer from './housing/housing.reducer';
import * as InfoReducer from './info/info.reducer';
import * as ContextReducer from './context/context.reducer';
import * as TarificationReducer from './tarification/tarification.reducer';
import * as DevisReducer from './devis/devis.reducer';
import * as ContractReducer from './contract/contract.reducer';
import { hydrationMetaReducer } from './hydration/hydration.reducer';
import { resetState } from './core.actions';

export const reducers: ActionReducerMap<State> = {
  address: AddressReducer.reducer,
  path: PathReducer.reducer,
  housing: HousingReducer.reducer,
  info: InfoReducer.reducer,
  service: ServiceReducer.reducer,
  payment: PaymentReducer.reducer,
  electronicSignature: ElectronicSignatureReducer.reducer,
  subscriber: SubscriberReducer.reducer,
  context: ContextReducer.reducer,
  offerCustomization: OfferCustomizationReducer.reducer,
  tarification: TarificationReducer.reducer,
  devis: DevisReducer.reducer,
  contract: ContractReducer.reducer
};

export const metaReducers: MetaReducer[] = [hydrationMetaReducer, clearState];

export function clearState(reducer: any) {
  return (state: any, action: any) => {
    if (action != null && action.type === '[Init]') {
      return reducer(undefined, { type: INIT });
    }
    return reducer(state, action);
  };
}
