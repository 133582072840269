export class InfoTopVM {
  title: string;
  secondTitle: string;
  subTitle: string;
  logo: string;
  isVisible: boolean;

  constructor(
    title: string,
    secondTitle: string,
    subTitle: string,
    logo: string,
    isVisible: boolean
  ) {
    this.title = title;
    this.secondTitle = secondTitle;
    this.subTitle = subTitle;
    this.logo = logo;
    this.isVisible = isVisible;
  }
}
