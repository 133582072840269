import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { DocumentBlob } from '../models/document-blob';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  private documentUrl = 'documents';
  public configuration = new Configuration();
  url: string;
  constructor(private http: HttpClient, configuration: Configuration) {
    this.url = configuration.apiBasePath ?? '';
  }

  getAgreementDocument(id: Guid, agreementId: Guid, email: string): Observable<DocumentBlob> {
    let filter = new HttpParams();
    if (agreementId) {
      filter = filter.append('agreementId', agreementId.toString());
    }
    if (email) {
      filter = filter.append('email', email);
    }
    return this.http.get<DocumentBlob>(this.url + this.documentUrl + '/agreement/' + id, {
      params: filter
    });
  }

  convertBase64ToBlob(byteString: any, mimeType: string) {
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeType });
  }
}
