/**
 * VyV Conseil MRH Public API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Saisie des informations sur le logement
 */
export interface VyVTarifSaisieHabitation {
  TypeHabitation: VyVTarifSaisieHabitation.TypeHabitationEnum;
  QualiteOccupant: VyVTarifSaisieHabitation.QualiteOccupantEnum;
  NatureResidence: VyVTarifSaisieHabitation.NatureResidenceEnum;
  TarifPreferentiel: boolean;
  CompositionFoyer: number | null;
  RFRInferieurPlafond: boolean | null;
  ConfirmationRFRInferieurPlafond: boolean | null;
  NombrePieces: number;
  NombrePiecesSuperieures: number | null;
  Etage: VyVTarifSaisieHabitation.EtageEnum | null;
  AvecVeranda: boolean | null;
  AntecedentSinistresInondables: boolean | null;
  SurfaceDependance: VyVTarifSaisieHabitation.SurfaceDependanceEnum | null;
  InstallationDD: boolean | null;
  PiscineSpaJacuzzi: boolean | null;
  ObjetsValeurSuperieur2000: boolean | null;
  PlafondMobilier: VyVTarifSaisieHabitation.PlafondMobilierEnum;
}
export namespace VyVTarifSaisieHabitation {
  export type TypeHabitationEnum = 'Appartement' | 'Maison' | 'Autre';
  export const TypeHabitationEnum = {
    Appartement: 'Appartement' as TypeHabitationEnum,
    Maison: 'Maison' as TypeHabitationEnum,
    Autre: 'Autre' as TypeHabitationEnum
  };
  export type QualiteOccupantEnum = 'Proprietaire' | 'Locataire';
  export const QualiteOccupantEnum = {
    Proprietaire: 'Proprietaire' as QualiteOccupantEnum,
    Locataire: 'Locataire' as QualiteOccupantEnum
  };
  export type NatureResidenceEnum = 'Principale' | 'Secondaire';
  export const NatureResidenceEnum = {
    Principale: 'Principale' as NatureResidenceEnum,
    Secondaire: 'Secondaire' as NatureResidenceEnum
  };
  export type EtageEnum = 'RezDeChaussee' | 'Intermediaire' | 'DernierEtage';
  export const EtageEnum = {
    RezDeChaussee: 'RezDeChaussee' as EtageEnum,
    Intermediaire: 'Intermediaire' as EtageEnum,
    DernierEtage: 'DernierEtage' as EtageEnum
  };
  export type SurfaceDependanceEnum = 'Aucune' | 'MoinsDe50m2' | 'De50a100m2' | 'PlusDe100m2';
  export const SurfaceDependanceEnum = {
    Aucune: 'Aucune' as SurfaceDependanceEnum,
    MoinsDe50m2: 'MoinsDe50m2' as SurfaceDependanceEnum,
    De50a100m2: 'De50a100m2' as SurfaceDependanceEnum,
    PlusDe100m2: 'PlusDe100m2' as SurfaceDependanceEnum
  };
  export type PlafondMobilierEnum = 'F1' | 'F2';
  export const PlafondMobilierEnum = {
    F1: 'F1' as PlafondMobilierEnum,
    F2: 'F2' as PlafondMobilierEnum
  };
}
