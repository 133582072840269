// src/app/core/state/menus/menus.reducer.ts

import { Action, createReducer, on } from '@ngrx/store';
import * as DevisActions from './devis.actions';
import { initialState, DevisState } from './devis.state';

const devisReducer = createReducer(
  initialState,
  on(DevisActions.patchDevis, (state, action: ReturnType<typeof DevisActions.patchDevis>) => ({
    ...state,
    Devis: { ...state.Devis, ...action.payload }
  })),
  on(DevisActions.init, state => ({
    ...state,
    ...initialState
  }))
);

export function reducer(state: DevisState | undefined, action: Action) {
  return devisReducer(state, action);
}
