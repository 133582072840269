import { SaisieInfosSouscripteur } from './../../models/form-state.model';

export interface SubscriberState {
  subscriber?: SaisieInfosSouscripteur;
  isValid: boolean;
}

export const initialState: SubscriberState = {
  isValid: false
};
