// src/app/core/state/menus/menus.reducer.ts

import { Action, createReducer, on } from '@ngrx/store';
import * as SubscriberActions from './subscriber.actions';
import { initialState, SubscriberState } from './subscriber.state';

const subscriberReducer = createReducer(
  initialState,
  on(
    SubscriberActions.patchSubscriber,
    (state, action: ReturnType<typeof SubscriberActions.patchSubscriber>) => ({
      ...state,
      subscriber: { ...state.subscriber!, ...action.payload }
    })
  ),
  on(SubscriberActions.resetSubscriber, state => ({
    ...state,
    subscriber: initialState.subscriber
  })),
  on(
    SubscriberActions.changeValidationStatus,
    (state, { isValid }: ReturnType<typeof SubscriberActions.changeValidationStatus>) => ({
      ...state,
      isValid
    })
  ),
  on(SubscriberActions.init, state => ({
    ...state,
    ...initialState
  }))
);

export function reducer(state: SubscriberState | undefined, action: Action) {
  return subscriberReducer(state, action);
}
