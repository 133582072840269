// src/app/core/state/menus/menus.state.ts

import { Contract } from '../../models/form-state.model';

export interface ContractState {
  Contract: Contract;
}

export const initialState: ContractState = {
  Contract: {
    EstSansEffet: false,
    EstSigne: false,
    RefDocumentFIC: '',
    NumeroContrat: '',
    RefContrat: '',
    RefDocumentCP: '',
    RefDocumentMandatSEPA: '',
    RefDocumentAttestation: '',
    RefDocumentCG: '',
    RefDocumentsSI: [],
    ClientAdvisorFullName: ''
  }
};
