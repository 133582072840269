import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DistributorCode } from 'src/app/shared/models/enum/distributorCode.enum';
import { Configuration } from '../configuration';
import { Price } from '../models/price.model';
import { PriceRequestModel } from '../models/priceRequest.model';
import { VyVTarifCreateRequest } from '../models/vyVTarifCreateRequest';

@Injectable({
  providedIn: 'root'
})
export class PriceService {
  public configuration = new Configuration();
  baseUrl: string;
  apiEndpoint: string = 'prices/';

  constructor(private http: HttpClient, configuration: Configuration) {
    this.baseUrl = configuration.apiBasePath ?? '';
  }

  public GetProductPrices(body: VyVTarifCreateRequest): Observable<Price> {
    return this.http.post<Price>(
      `${this.baseUrl}${this.apiEndpoint}`,
      PriceService.ToPriceRequestModel(body)
    );
  }

  // TODO : Refacto / clean code
  static ToPriceRequestModel(body: VyVTarifCreateRequest): PriceRequestModel {
    let address = body.SaisieAdresse;

    let dependencyTypes: string | undefined;

    if (body.SaisieHabitation.SurfaceDependance === 'MoinsDe50m2') {
      dependencyTypes = '_0_50m2';
    } else if (body.SaisieHabitation.SurfaceDependance === 'De50a100m2') {
      dependencyTypes = '_50_100m2';
    } else if (body.SaisieHabitation.SurfaceDependance === 'PlusDe100m2') {
      dependencyTypes = '_100_250m2';
    }

    const priceRequestModel: PriceRequestModel = {
      ProductCode: body.SaisieProduit.IdProduit,
      ContextId: body.Context,
      DistributorCode: DistributorCode.VY,
      PromotionCode: body.SaisieProduit.CodePromo ? body.SaisieProduit.CodePromo : undefined,
      Address: {
        ZipCode: address?.CodePostal ? address.CodePostal : '',
        City: address?.Ville ? address.Ville : '',
        Country: 'France',
        StreetNumberAndName: address?.NumeroEtRue
          ? address?.NumeroEtRue
          : address.NumeroDeRue + ' ' + address.NomDeRue,
        Housing: body.SaisieHabitation.TypeHabitation === 'Appartement' ? 'Apartment' : 'House',
        Floor:
          body.SaisieHabitation.Etage === 'RezDeChaussee'
            ? 'GroundFloor'
            : body.SaisieHabitation.Etage === 'Intermediaire'
            ? 'Intermediate'
            : body.SaisieHabitation.Etage === 'DernierEtage'
            ? 'TopFloor'
            : undefined
      },
      SubscriptionInformation: {
        ResidenceType:
          body.SaisieHabitation.NatureResidence === 'Principale' ? 'Principal' : 'Secondary',
        OccupationStatus:
          body.SaisieHabitation.QualiteOccupant == 'Locataire' ? 'Tenant' : 'OwnerOccupier',
        RoomCount: body.SaisieHabitation.NombrePieces,
        RoomCountGt40M2: body.SaisieHabitation.NombrePiecesSuperieures!,
        HasClaims: body.SaisieHabitation.AntecedentSinistresInondables ? true : false,
        HasSwimmingPool: body.SaisieHabitation.PiscineSpaJacuzzi ? true : false,
        HasVeranda: body.SaisieHabitation.AvecVeranda ? true : false,
        DependencyType: dependencyTypes,
        HasGreenEnergyProductionInstallation: body.SaisieHabitation.InstallationDD ? true : false
      }
    };
    return priceRequestModel;
  }
}
