//#region Imports
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//#endregion

//#region General Information
import { AddressComponent } from 'src/app/views/general-information/address/address.component';
import { DependencyComponent } from 'src/app/views/general-information/dependency/dependency.component';
import { HouseApartmentComponent } from 'src/app/views/general-information/house-apartment/house-apartment.component';
import { InsuredValueComponent } from 'src/app/views/general-information/insured-value/insured-value.component';
import { NumberPiecesComponent } from 'src/app/views/general-information/number-pieces/number-pieces.component';
import { OwnerTenantComponent } from 'src/app/views/general-information/owner-tenant/owner-tenant.component';
import { ParticularityComponent } from 'src/app/views/general-information/particularity/particularity.component';
import { PreferentialRateComponent } from 'src/app/views/general-information/preferential-rate/preferential-rate.component';
import { PreviousClaimComponent } from 'src/app/views/general-information/previous-claim/previous-claim.component';
import { PrimarySecondaryComponent } from 'src/app/views/general-information/primary-secondary/primary-secondary.component';
import { SolidarityAddressComponent } from 'src/app/views/general-information/solidarity-address/solidarity-address.component';
//#endregion

//#region Devis
import { OfferCustomizationComponent } from 'src/app/views/devis/offer-customization/offer-customization.component';
import { QuoteConfirmationComponent } from 'src/app/views/devis/quote-confirmation/quote-confirmation.component';
import { RateComponent } from 'src/app/views/devis/rate/rate.component';
import { SchoolInsuranceComponent } from 'src/app/views/devis/school-insurance/school-insurance.component';
import { SubscriberComponent } from 'src/app/views/devis/subscriber/subscriber.component';
//#endregion

//#region Contract
import { CancellationComponent } from 'src/app/views/contract/cancellation/cancellation.component';
import { ContractConfirmationComponent } from 'src/app/views/contract/contract-confirmation/contract-confirmation.component';
import { ESignMsgAdvisorComponent } from 'src/app/views/contract/e-sign-msg-advisor/e-sign-msg-advisor.component';
import { ESignMsgComponent } from 'src/app/views/contract/e-sign-msg/e-sign-msg.component';
import { ElectronicSignatureComponent } from 'src/app/views/contract/electronic-signature/electronic-signature.component';
import { LessorCertificateComponent } from 'src/app/views/contract/lessor-certificate/lessor-certificate.component';
import { PaymentComponent } from 'src/app/views/contract/payment/payment.component';
import { ServicesComponent } from 'src/app/views/contract/services/services.component';
import { LayoutComponent } from '../components/layout/layout.component';
import { ContextComponent } from '../components/redirection/context/context.component';
import { ContextResolver } from '../components/redirection/context/context.resolver';
import { ProductComponent } from '../components/redirection/product/product.component';
import { RedirectComponent } from '../components/redirection/redirect/redirect.component';
import {
  ADDRESS_PATH,
  CANCELLATION_PATH,
  CONFIRM_CONTRACT_PATH,
  CONFIRM_QUOTE_PATH,
  CONTEXT_PATH,
  DEPENDENCY_PATH,
  ELECTRONIC_SIGNATURE_PATH,
  E_SIGN_MSG_ADVISOR_PATH,
  HOUSE_APARTMENT_PATH,
  INSURED_VALUE_PATH,
  LESSOR_CERTIFICATE_PATH,
  NUMBER_PIECES_PATH,
  OFFER_CUSTOMIZARION_PATH,
  OWNER_TENANT_PATH,
  PARTICULARITY_PATH,
  PAYMENT_PATH,
  PREFERENTIAL_RATE_PATH,
  PREVIOUS_CLAIM_PATH,
  PRIMARY_SECONDARY_PATH,
  PRODUCT_PATH,
  RATE_PATH,
  SCHOOL_INSURANCE_PATH,
  SERVICES_PATH,
  SIGNATURE_STATUS_PATH,
  SOLIDARITY_ADDRESS_PATH,
  START_CONTEXT_PATH,
  START_CONTEXT_PRODUCT_PATH,
  SUBSCRIBER_PATH
} from '../constants/route.constants';
import { SignatureGuard } from '../guards/signature.guard';

//#endregion

const routes: Routes = [
  {
    path: '',
    canActivate: [SignatureGuard],
    canActivateChild: [SignatureGuard],
    component: LayoutComponent,
    children: [
      { path: SOLIDARITY_ADDRESS_PATH, component: SolidarityAddressComponent },
      { path: ADDRESS_PATH, component: AddressComponent },
      { path: OWNER_TENANT_PATH, component: OwnerTenantComponent },
      { path: PRIMARY_SECONDARY_PATH, component: PrimarySecondaryComponent },
      { path: PREFERENTIAL_RATE_PATH, component: PreferentialRateComponent },
      { path: PREVIOUS_CLAIM_PATH, component: PreviousClaimComponent },
      { path: DEPENDENCY_PATH, component: DependencyComponent },
      { path: SUBSCRIBER_PATH, component: SubscriberComponent },
      { path: RATE_PATH, component: RateComponent },
      { path: SERVICES_PATH, component: ServicesComponent }
    ]
  },
  {
    path: HOUSE_APARTMENT_PATH,
    component: HouseApartmentComponent,
    canActivate: [SignatureGuard],
    canActivateChild: [SignatureGuard]
  },
  {
    path: NUMBER_PIECES_PATH,
    component: NumberPiecesComponent,
    canActivate: [SignatureGuard],
    canActivateChild: [SignatureGuard]
  },
  {
    path: START_CONTEXT_PRODUCT_PATH,
    component: RedirectComponent,
    canActivate: [SignatureGuard],
    canActivateChild: [SignatureGuard]
  },
  {
    path: START_CONTEXT_PATH,
    component: RedirectComponent,
    canActivate: [SignatureGuard],
    canActivateChild: [SignatureGuard]
  },
  {
    path: SOLIDARITY_ADDRESS_PATH,
    component: SolidarityAddressComponent,
    canActivate: [SignatureGuard],
    canActivateChild: [SignatureGuard]
  },
  {
    path: PARTICULARITY_PATH,
    component: ParticularityComponent,
    canActivate: [SignatureGuard],
    canActivateChild: [SignatureGuard]
  },
  {
    path: INSURED_VALUE_PATH,
    component: InsuredValueComponent,
    canActivate: [SignatureGuard],
    canActivateChild: [SignatureGuard]
  },
  {
    path: RATE_PATH,
    component: RateComponent,
    canActivate: [SignatureGuard],
    canActivateChild: [SignatureGuard]
  },
  {
    path: OFFER_CUSTOMIZARION_PATH,
    component: OfferCustomizationComponent,
    canActivate: [SignatureGuard],
    canActivateChild: [SignatureGuard]
  },
  {
    path: SCHOOL_INSURANCE_PATH,
    component: SchoolInsuranceComponent,
    canActivate: [SignatureGuard],
    canActivateChild: [SignatureGuard]
  },
  {
    path: CONFIRM_QUOTE_PATH,
    component: QuoteConfirmationComponent,
    canActivate: [SignatureGuard],
    canActivateChild: [SignatureGuard]
  },
  {
    path: SERVICES_PATH,
    component: ServicesComponent,
    canActivate: [SignatureGuard],
    canActivateChild: [SignatureGuard]
  },
  {
    path: LESSOR_CERTIFICATE_PATH,
    component: LessorCertificateComponent,
    canActivate: [SignatureGuard],
    canActivateChild: [SignatureGuard]
  },
  {
    path: CANCELLATION_PATH,
    component: CancellationComponent,
    canActivate: [SignatureGuard],
    canActivateChild: [SignatureGuard]
  },
  {
    path: PAYMENT_PATH,
    component: PaymentComponent,
    canActivate: [SignatureGuard],
    canActivateChild: [SignatureGuard]
  },
  {
    path: ELECTRONIC_SIGNATURE_PATH,
    component: ElectronicSignatureComponent,
    canActivate: [SignatureGuard],
    canActivateChild: [SignatureGuard]
  },
  {
    path: SIGNATURE_STATUS_PATH,
    component: ESignMsgComponent,
    canActivate: [SignatureGuard],
    canActivateChild: [SignatureGuard]
  },
  {
    path: CONFIRM_CONTRACT_PATH,
    component: ContractConfirmationComponent,
    canActivate: [SignatureGuard],
    canActivateChild: [SignatureGuard]
  },
  {
    path: CONTEXT_PATH,
    component: ContextComponent,
    resolve: { context: ContextResolver }
  },
  {
    path: PRODUCT_PATH,
    component: ProductComponent
  },
  {
    path: E_SIGN_MSG_ADVISOR_PATH,
    component: ESignMsgAdvisorComponent
  },
  { path: '**', redirectTo: 'error404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
