<app-header></app-header>
<app-resume-step></app-resume-step>
<div class="container mx-auto px-4 mb-6">
  <app-info-top></app-info-top>
  <app-question
    [question]="questionFloor"
    [popupInfo]="popupInfoFloor"
    [local]="true"></app-question>
  <ng-container *ngIf="roomsForm">
    <div class="number-pieces">
      <div class="grid gap-x-4 space-y-4" *ngIf="ready && roomsForm" [formGroup]="roomsForm">
        <div class="w-full">
          <span class="p-float-label">
            <p-dropdown
              class="width-max"
              id="numberOfRooms"
              [options]="rooms"
              [(ngModel)]="selectedRoom"
              optionLabel="text"
              [emptyFilterMessage]="resource.form.noResultFilter"
              [placeholder]="resource.numberpieces.numberOfRooms"
              formControlName="numberOfRooms"></p-dropdown>
            <label for="float-input" class="inputtext">{{
              resource.numberpieces.numberOfRooms
            }}</label>
          </span>
          <small
            class="p-error"
            *ngIf="
              numberOfRooms?.touched &&
              numberOfRooms?.invalid &&
              (numberOfRooms?.errors)!['required']
            "
            >{{ resource.error.numberOfRooms }}</small
          >
        </div>
        <div *ngIf="numberOfRoomsMoreThan">
          <span class="p-float-label">
            <p-dropdown
              class="width-max"
              id="smallerThan"
              [options]="roomsMoreThan"
              [(ngModel)]="selectedRoomMoreThan"
              optionLabel="text"
              [emptyFilterMessage]="resource.form.noResultFilter"
              [placeholder]="resource.numberpieces.numberOfRoomsMoreThan"
              formControlName="numberOfRoomsMoreThan"></p-dropdown>
            <label for="float-input" class="inputtext">{{
              resource.numberpieces.numberOfRoomsMoreThan
            }}</label>
          </span>
          <small
            class="p-error"
            *ngIf="
              numberOfRoomsMoreThan?.touched &&
              numberOfRoomsMoreThan?.invalid &&
              (numberOfRoomsMoreThan?.errors)!['required']
            "
            >{{ resource.error.numberOfRoomsMoreThan }}</small
          >
          <small class="p-error" *ngIf="roomsForm.hasError('numberCheck')">{{
            resource.error.numberOfRoomsInferior
          }}</small>
        </div>
        <div class="w-full" *ngIf="floor">
          <span class="p-float-label">
            <p-dropdown
              class="width-max"
              id="whichFloor"
              [options]="floors"
              [(ngModel)]="selectedFloor"
              optionLabel="name"
              [emptyFilterMessage]="resource.form.noResultFilter"
              [placeholder]="resource.numberpieces.floor"
              formControlName="floor"></p-dropdown>
            <label for="float-input" class="inputtext">{{ resource.numberpieces.floor }}</label>
          </span>
          <small
            class="p-error"
            *ngIf="floor?.touched && floor?.invalid && (floor?.errors)!['required']"
            >{{ resource.error.floor }}</small
          >
        </div>
      </div>
      <ng-container *ngIf="path === PathType.HOUSING">
        <app-question
          [question]="questionVeranda"
          [popupInfo]="popupInfoVeranda"
          [local]="true"></app-question>
        <div class="flex flex-col w-full mt-6 mb-4 md:grid md:gap-x-4 md:grid-cols-2 items-center">
          <div
            class="mx-auto clickable-card flex flex-col mb-4 content-center items-center w-full"
            (click)="onVerandaSelected()"
            [ngClass]="setClassActive(yesSelected)">
            <div class="flex flex-row items-center place-content-between">
              <p class="two-lines py-4">{{ resource.form.yes }}</p>
            </div>
          </div>

          <div
            class="mx-auto clickable-card flex flex-col mb-4 content-center items-center w-full"
            (click)="onVerandaUnselected()"
            [ngClass]="setClassActive(noSelected)">
            <div class="flex flex-row items-center place-content-between">
              <p class="two-lines py-4">{{ resource.form.no }}</p>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="flex my-5">
        <button
          pButton
          type="button"
          [disabled]="
            (path !== PathType.HOUSING && !roomsForm.valid) ||
            (path === PathType.HOUSING && (!roomsForm.valid || (!yesSelected && !noSelected)))
          "
          class="button-NS hover:button-NS focus:button-NS active:button-NS w-full"
          [label]="resource.nextstep.keepGoing"
          icon="pi pi-arrow-right"
          iconPos="right"
          (click)="nextStep()"></button>
      </div>
    </div>
  </ng-container>
</div>

<div class="container mx-auto h-28 md:h-40">
  <app-footer class="footer"></app-footer>
</div>
<app-web-callback></app-web-callback>

<app-web-callback-popup
  (cancel)="cancel()"
  (validate)="nextStep()"
  [show]="displayModal"></app-web-callback-popup>
