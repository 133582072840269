import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppResource } from 'src/app/app.resource';
import { State } from 'src/app/core/state/core.state';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component.component';
import { HeaderVM } from 'src/app/shared/models/components/headerVm';

import { distinctUntilChanged, lastValueFrom, map, merge, take } from 'rxjs';
import { Esh, Selectionne } from 'src/app/core/models/form-state.model';
import * as fromContext from 'src/app/core/state/context';
import * as fromAddress from '../../../core/state/address';
import * as fromInfo from '../../../core/state/info';
import * as fromPath from '../../../core/state/path';

import { Router } from '@angular/router';
import { MatomoTracker } from 'ngx-matomo-client';
import { LessorList } from 'src/app/api/models/lessor.model';
import { LessorService } from 'src/app/api/services/lessor.service';
import { PathType } from 'src/app/core/enums/path-type.enum';
import { cloneResumeStep } from 'src/app/core/models/resume-step.model';
import { Stepper } from 'src/app/core/models/stepper.model';
import { MatomoConstants } from 'src/app/shared/constants/matomo.constants';

interface Country {
  text: string;
  value: string;
}

@Component({
  selector: 'app-solidarity-address',
  templateUrl: './solidarity-address.component.html',
  styleUrls: ['./solidarity-address.component.scss']
})
export class SolidarityAddressComponent extends BaseComponent implements OnInit {
  private productCode: string = PathType.HOUSING_SOLIDARITY;
  context!: string;

  headerStep!: HeaderVM;

  lessorList: LessorList = [];

  postalCodes: string[] = [];
  filteredPostalCode: string[] = [];

  cities: string[] = [];
  filteredCities: string[] = [];

  addresses: string[] = [];
  filteredAddresses: string[] = [];

  filteredLessors: string[] = [];

  countries: Country[] = [];
  selectedCountry!: Country | undefined;

  show: boolean = false;
  lessorNotFind: boolean = true;
  addressNotFind: boolean = false;

  selectedLessorId: number = 0;

  addressForm!: FormGroup;
  formOK = false;

  override step = {
    step: this.resource.header.stepHousing,
    stepNumber: 1,
    subStep: this.resource.header.subStepAddress,
    subStepNumber: 1
  };

  override info = {
    title: this.resource.infotop.firstStep,
    secondTitle: '',
    subTitle: this.resource.infotop.firstStepB,
    logo: this.resource.infotop.logoTime,
    isVisible: true,
    currentPage: MatomoConstants.ADDRESS_ESH
  };

  override question = {
    libelle: this.resource.question.address,
    popupInfo: undefined
  };

  public override currentPage = MatomoConstants.NOMBRE_PIECES;

  get address(): AbstractControl<any, any> {
    return this.addressForm.get('address')!;
  }

  get city(): AbstractControl<any, any> {
    return this.addressForm.get('city')!;
  }

  get postalCode(): AbstractControl<any, any> {
    return this.addressForm.get('postalCode')!;
  }

  get lessor(): AbstractControl<any, any> {
    return this.addressForm.get('lessor')!;
  }

  get country(): AbstractControl<any, any> {
    return this.addressForm.get('country')!;
  }

  constructor(
    store: Store<State>,
    tracker: MatomoTracker,
    resources: AppResource,
    private router: Router,
    private lessorService: LessorService
  ) {
    super(store, resources, tracker);
    this.initForm();

    this.countries.push({
      text: 'France',
      value: 'FR'
    });
  }

  override async ngOnInit() {
    super.ngOnInit();
    this.updateResumeStepVisibility(false);
    await this.updateStepper(true);
    await this.initData();
    this.initStateData();
    this.initEvent();
  }

  private async initStateData() {
    this.store.dispatch(fromPath.updateType({ pathType: PathType.HOUSING_SOLIDARITY }));
    const addressType = await lastValueFrom(
      this.store.select(fromAddress.selectAddressTypeData).pipe(take(1))
    );

    if (addressType === Selectionne.Esh) {
      const address = await lastValueFrom(
        this.store.select(fromAddress.selectAddressEshGroupData).pipe(take(1))
      );
      if (address != null) {
        const lessor = this.lessorList.find(elem => elem.name === address.NomBailleur);
        if (lessor != null) {
          this.lessor.patchValue(lessor);
        }

        const postalCode = this.postalCodes.find(elem => elem === address.CodePostal);
        if (postalCode != null) {
          this.postalCode.patchValue(postalCode);
        }

        const city = this.cities.find(elem => elem === address.Ville);
        if (city != null) {
          this.city.patchValue(city);
        }

        const addressStreet = this.addresses.find(elem => elem === address.NumeroEtRue);
        if (addressStreet != null) {
          this.address.patchValue(addressStreet);
        }
      }
    } else {
      this.store.dispatch(fromAddress.patchSelectedAddress({ adresseType: Selectionne.Esh }));
    }
  }

  private initForm() {
    this.addressForm = new FormGroup({
      lessor: new FormControl<string | null>(null, {
        validators: [Validators.required],
        nonNullable: true
      }),
      postalCode: new FormControl<string | null>(
        { value: null, disabled: true },
        {
          validators: [Validators.required],
          nonNullable: true
        }
      ),
      address: new FormControl<string | null>(
        { value: null, disabled: true },
        {
          validators: [Validators.required],
          nonNullable: true
        }
      ),
      city: new FormControl<string | null>(
        { value: null, disabled: true },
        {
          validators: [Validators.required],
          nonNullable: true
        }
      ),
      country: new FormControl<string | null>(null, {
        validators: [Validators.required],
        nonNullable: true,
        updateOn: 'change'
      })
    });
  }

  initEvent() {
    this.lessor.valueChanges.subscribe(() => {
      if (this.lessor.valid) {
        this.postalCode.reset(null, { emitEvent: false });
        this.postalCode.disable();

        this.city.reset(null, { emitEvent: false });
        this.city.disable();

        this.address.reset(null, { emitEvent: false });
        this.address.disable();

        this.lessorNotFind = true;
        this.addressNotFind = false;

        this.selectedLessorId = this.lessorList.find(elem => elem.name === this.lessor.value)!.id;

        // call API
        this.lessorService.GetZipCodeListByLessor(this.selectedLessorId).subscribe({
          next: response => {
            if (response) {
              this.postalCodes = response;
              // Check if only one result
              if (this.postalCodes.length == 1) this.postalCode?.setValue(this.postalCodes[0]);

              // enable control
              this.postalCode.enable();
            }
          },
          error: err => {
            console.error(err);
          }
        });
      }
    });

    this.postalCode.valueChanges.subscribe(() => {
      if (this.postalCode.valid) {
        this.city.reset(null, { emitEvent: false });
        this.city.disable();

        this.address.reset(null, { emitEvent: false });
        this.address.disable();

        this.lessorNotFind = false;
        this.addressNotFind = false;

        // call API
        this.lessorService
          .GetCitiesListFromZipCode(this.selectedLessorId, this.postalCode.value)
          .subscribe({
            next: response => {
              if (response) {
                this.cities = response;
                // Check if only one result
                if (this.cities.length == 1) this.city.setValue(this.cities[0]);

                // enable city control
                this.city.enable();
              }
            },
            error: err => {
              console.error(err);
            }
          });
      }
    });

    this.city.valueChanges.subscribe(() => {
      if (this.city.valid) {
        this.address.reset(null, { emitEvent: false });
        this.address.disable();

        this.lessorNotFind = false;

        // call API
        this.lessorService
          .GetAddressesListFromCity(this.selectedLessorId, this.postalCode.value, this.city.value)
          .subscribe({
            next: response => {
              if (response) {
                this.addresses = response;
                // Check if only one result
                if (this.addresses.length == 1) this.address.setValue(this.addresses[0]);

                this.addressNotFind = true;
                // enable address control
                this.address.enable();
              }
            },
            error: err => {
              console.log(err);
            }
          });
      } else {
        this.addressNotFind = false;
      }
    });

    this.address.valueChanges.subscribe(res => {
      if (this.address.valid) {
        this.formOK = true;
      } else {
        this.formOK = false;
      }
    });

    const postalCode$ = this.postalCode?.valueChanges.pipe(
      map((val?: string) => {
        var CodePostal = val;
        return { CodePostal } as Partial<Esh>;
      })
    );

    const city$ = this.city?.valueChanges.pipe(
      map((val?: string) => {
        var Ville = val;
        return { Ville } as Partial<Esh>;
      })
    );

    const address$ = this.address?.valueChanges.pipe(
      map((val?: string) => {
        var NumeroEtRue = val;
        return { NumeroEtRue } as Partial<Esh>;
      })
    );

    const lessor$ = this.lessor?.valueChanges.pipe(
      map((val?: string) => {
        var NomBailleur = val;
        return { NomBailleur } as Partial<Esh>;
      })
    );

    merge(address$!, postalCode$!, city$!, lessor$!).subscribe((payload: Partial<Esh>) => {
      this.store.dispatch(fromAddress.patchEsh({ payload }));
    });

    this.addressForm.valueChanges
      .pipe(
        map(() => this.addressForm.valid),
        distinctUntilChanged()
      )
      .subscribe((isValid: boolean) => {
        this.store.dispatch(fromAddress.changeValidationStatus({ isValid }));
      });
  }

  async initData() {
    // Retrieve context
    const stateContext = await lastValueFrom(
      this.store.select(fromContext.selectContextId).pipe(take(1))
    );
    if (stateContext) this.context = stateContext;

    this.lessorService.getLessors(this.productCode).subscribe(response => {
      this.lessorList = response;
    });

    this.filteredLessors = this.lessorList.map(l => l.name);
    this.filteredCities = this.cities;
    this.filteredPostalCode = this.postalCodes;
    this.filteredAddresses = this.addresses;

    this.selectedCountry = this.countries.find(obj => {
      return obj.value === 'FR';
    });
  }

  filterLessors($event: any) {
    let query = $event.query;
    let lessors = this.lessorList.map(l => l.name);
    if (query != null) {
      this.filteredLessors = lessors.filter(item => {
        return item.toLowerCase().includes(query.toLowerCase());
      });
    } else {
      this.filteredLessors = lessors;
    }
  }

  filterPostalCode($event: any) {
    let query = $event.query;
    if (query != null) {
      this.filteredPostalCode = this.postalCodes.filter(item => {
        return item.toLowerCase().includes(query.toLowerCase());
      });
    } else {
      this.filteredPostalCode = this.postalCodes;
    }
  }

  filterCities($event: any) {
    let query = $event.query;
    if (query != null) {
      this.filteredCities = this.cities.filter(item => {
        return item.toLowerCase().includes(query.toLowerCase());
      });
    } else {
      this.filteredCities = this.cities;
    }
  }

  filterAddresses($event: any) {
    let query = $event.query;
    if (query != null) {
      var trimmedQuery = query
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLocaleLowerCase('fr-FR');
      this.filteredAddresses = this.addresses.filter(item => {
        return item
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLocaleLowerCase('fr-FR')
          .includes(trimmedQuery);
      });
    } else {
      this.filteredAddresses = this.addresses;
    }
  }

  onRedirectAHCClick() {
    this.tracker.trackEvent(
      MatomoConstants.LESSORNOTFIND,
      MatomoConstants.ADDRESS_ESH,
      MatomoConstants.AHS_TO_AHC
    );

    this.store.dispatch(fromPath.updateType({ pathType: PathType.HOUSING }));
    this.router.navigate(['/address']);
  }

  onPopUpClick() {
    this.show = true;
  }

  async nextStep() {
    await this.updateStepper(false); //Check
    await this.updateResumeStep();
    this.router.navigate(['/houseapartment']);
  }

  async updateStepper(init: boolean) {
    let stepper = await lastValueFrom(this.store.select(fromInfo.selectStepper).pipe(take(1)));
    var tmpStepper = JSON.parse(JSON.stringify(stepper)) as Stepper;

    let title = tmpStepper.devis.titles.find(
      (x: { libelle: string }) => x.libelle == this.resource.stepper.lodging
    );

    let subtitleS = title?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.solidarityaddress
    );

    if (init) {
      if (subtitleS) {
        subtitleS.isActive = true;
        subtitleS.isVisible = true;
      }
    } else {
      if (subtitleS) subtitleS.isValid = true;

      let subtitleN = title?.subtitles.find(
        (x: { url: string }) => x.url == this.resource.stepper.houseapartment
      );

      if (subtitleN) subtitleN.isActive = true;
    }

    this.store.dispatch(fromInfo.updateStepper({ payload: tmpStepper }));
  }

  async updateResumeStep() {
    const resumeStep = await lastValueFrom(
      this.store.select(fromInfo.selectCurrentResumeStep).pipe(take(1))
    );

    const libelle = this.address.value;

    const tmpResumeStep = cloneResumeStep(resumeStep);
    const stepResumeStep = tmpResumeStep.steps.find(x => x.id == this.resource.resumestep.address);
    if (stepResumeStep) {
      stepResumeStep.step = libelle;
      stepResumeStep.isVisible = true;
    } else {
      tmpResumeStep.steps.push({
        id: this.resource.resumestep.address,
        step: libelle,
        url: this.resource.resumestep.solidarityaddress,
        isVisible: true
      });
    }

    this.store.dispatch(fromInfo.updateResumeStep({ payload: tmpResumeStep }));
  }

  onCancel() {
    this.show = false;
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }
}
