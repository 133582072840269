import { createAction, props } from '@ngrx/store';
import { Promotion, Tarification } from '../../models/form-state.model';

export const patchTarification = createAction(
  '[Tarification] Patch Tarification ',
  props<{ payload: Partial<Tarification> }>()
);

export const patchTarificationPromo = createAction(
  '[Tarification] Patch Tarification Promotion',
  props<{ payload: Partial<Promotion> }>()
);

export const init = createAction('[Init]');
