// src/app/core/state/menus/menus.reducer.ts

import { Action, createReducer, on } from '@ngrx/store';
import * as ServiceActions from './service.actions';
import { initialState, ServiceState } from './service.state';

const serviceReducer = createReducer(
  initialState,
  on(
    ServiceActions.patchService,
    (state, action: ReturnType<typeof ServiceActions.patchService>) => ({
      ...state,
      saisieChoixServices: { ...state.saisieChoixServices!, ...action.payload }
    })
  ),
  on(
    ServiceActions.changeValidationStatus,
    (state, { isValid }: ReturnType<typeof ServiceActions.changeValidationStatus>) => ({
      ...state,
      isValid
    })
  ),
  on(ServiceActions.init, state => ({
    ...state,
    ...initialState
  }))
);

export function reducer(state: ServiceState | undefined, action: Action) {
  return serviceReducer(state, action);
}
