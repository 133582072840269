import { createAction, props } from '@ngrx/store';
import { SaisiePaiement } from '../../models/form-state.model';

export const patchPayment = createAction(
  '[Payment Page] Patch Payment info',
  props<{ payload: Partial<SaisiePaiement> }>()
);

export const changeValidationStatus = createAction(
  '[Payment Page] Change Validation Status',
  props<{ isValid: boolean }>()
);

export const init = createAction('[Init]');
