import { Injectable } from '@angular/core';
import { MatomoInitializerService, MatomoTracker } from 'ngx-matomo-client';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MatomoInitService {
  constructor(private matomoInitializer: MatomoInitializerService, private m: MatomoTracker) {}

  initMatomo() {
    try {
      if (environment.production) {
        this.matomoInitializer.initializeTracker({
          siteId: environment.matomoSiteId,
          trackerUrl: environment.matomoHostUrl,
          trackerUrlSuffix: 'piwik.php',
          scriptUrl: environment.matomoScriptUrl
        });
      } else {
        this.matomoInitializer.initializeTracker({
          siteId: environment.matomoSiteId,
          trackerUrl: environment.matomoHostUrl,
          scriptUrl: environment.matomoScriptUrl
        });
      }
    } catch {}
  }
}
