import { SaisiePaiement } from '../../models/form-state.model';

export interface PaymentState {
  payment: SaisiePaiement;
  isValid: boolean;
}

export const initialState: PaymentState = {
  payment: {
    EcheancePrelevement: undefined,
    DateDemarrage: undefined,
    JourPrelevement: undefined,
    IBAN: '',
    Telephone: '',
    PrefixeTel: '',
    TitulaireCompte: undefined,
    ConsentementTelephonique: false
  },
  isValid: false
};
