export class ConfirmContractVM {
  reference!: string;
  numberContract!: string;
  contractDoc!: string;
  ficDoc!: string;
  mandatSepaDoc!: string;
  cgDoc!: string;
  attestationDoc!: string;
  attestationScolaire!: string[];

  constructor(
    reference: string,
    numberContract: string,
    contractDoc: string,
    ficDoc: string,
    mandatSepaDoc: string,
    cgDoc: string,
    attestationDoc: string,
    attestationScolaire: string[]
  ) {
    this.reference = reference;
    this.numberContract = numberContract;
    this.contractDoc = contractDoc;
    this.ficDoc = ficDoc;
    this.mandatSepaDoc = mandatSepaDoc;
    this.cgDoc = cgDoc;
    this.attestationDoc = attestationDoc;
    this.attestationScolaire = attestationScolaire;
  }
}
