<app-header></app-header>
<app-resume-step></app-resume-step>
<div class="container mx-auto mb-6">
  <app-question [question]="questionTwo" [local]="true"></app-question>
  <p class="mb-5 ml-3 -mt-4 text-xs">{{ resource.infobulle.insuredValue2 }}</p>
  <div class="flex flex-wrap justify-center gap-y-4">
    <div class="w-full md:w-1/2 md:pr-2">
      <button
        pButton
        class="buttonToggleSM"
        [label]="labelMaxF1"
        (click)="selectValueMax(insuredValueEnum.MinK)"
        [ngClass]="setClassActive(minK)"></button>
    </div>
    <div class="w-full md:w-1/2 md:pl-2">
      <button
        pButton
        class="buttonToggleSM"
        [label]="labelMaxF2"
        (click)="selectValueMax(insuredValueEnum.MaxK)"
        [ngClass]="setClassActive(maxK)"></button>
    </div>
    <div class="w-full md:w-1/2 md:px-1">
      <button
        pButton
        class="buttonToggleSM"
        [label]="labelMoreThanF2"
        (click)="onMoreThanMaxSelected()"
        [ngClass]="setClassActive(moreThanMaxK)"></button>
    </div>
  </div>
  <app-question [question]="questionOne" [local]="true"></app-question>
  <p class="mb-5 ml-3 -mt-6 text-xs">{{ resource.infobulle.insuredValue1 }}</p>
  <div class="mb-8 space-y-4 md:grid md:gap-x-4 md:space-y-0 md:grid-cols-2">
    <button
      pButton
      class="buttonToggleSM"
      [label]="resource.form.yes"
      (click)="selectUnitValue(insuredValueEnum.Yes)"
      [ngClass]="setClassActive(yes)"></button>
    <button
      pButton
      class="buttonToggleSM"
      [label]="resource.form.no"
      (click)="selectUnitValue(insuredValueEnum.No)"
      [ngClass]="setClassActive(no)"></button>
  </div>
</div>

<div class="container mx-auto h-28 md:h-40">
  <app-footer class="footer"></app-footer>
</div>
<app-web-callback></app-web-callback>
<app-web-callback-popup [show]="displayModal" (cancel)="cancel()"></app-web-callback-popup>
