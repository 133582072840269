import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { CountryModel } from '../models/country.model';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  public configuration = new Configuration();
  baseUrl: string;
  apiEndpoint: string = 'countries/';

  constructor(private http: HttpClient, configuration: Configuration) {
    this.baseUrl = configuration.apiBasePath ?? '';
  }

  public GetCountries(): Observable<Array<CountryModel>> {
    const requestUrl = `${this.baseUrl}${this.apiEndpoint}`;
    return this.http.get<Array<CountryModel>>(requestUrl);
  }
}
