<div class="mb-6 clickable-card-grid">
  <div
    class="clickable-card flex flex-col content-center items-center w-full cursor-pointer clickable-grid"
    (click)="onNoDependencySelected()"
    [ngClass]="setClassActive(noDependencySelected)">
    <div class="flex flex-row items-center place-content-between">
      <p class="clickable-card-text px-6 py-12 text-clickable-card">
        {{ resource.dependency.noDependency }}
      </p>
    </div>
  </div>
  <div
    class="clickable-card flex flex-col content-center items-center w-full cursor-pointer clickable-grid"
    (click)="onLessThan50mSelected()"
    [ngClass]="setClassActive(lessThan50mSelected)">
    <div class="flex flex-row items-center place-content-between">
      <p class="clickable-card-text px-6 py-12 text-clickable-card">
        {{ resource.dependency.inferior50 }}
      </p>
    </div>
  </div>
  <div
    class="clickable-card flex flex-col content-center items-center w-full cursor-pointer clickable-grid"
    (click)="onLessThan100mSelected()"
    [ngClass]="setClassActive(lessThan100mSelected)">
    <div class="flex flex-row items-center place-content-between">
      <p class="clickable-card-text px-6 py-12 text-clickable-card">
        {{ resource.dependency.inferior99 }}
      </p>
    </div>
  </div>
  <div
    class="clickable-card flex flex-col content-center items-center w-full cursor-pointer clickable-grid"
    (click)="onMoreThan100mSelected()"
    [ngClass]="setClassActive(moreThan100mSelected)">
    <div class="flex flex-row items-center place-content-between">
      <p class="clickable-card-text px-6 py-12 text-clickable-card">
        {{ resource.dependency.superior100 }}
      </p>
    </div>
  </div>
  <div
    class="clickable-card flex flex-col content-center items-center w-full cursor-pointer clickable-grid"
    (click)="onMoreThan250mSelected()"
    [ngClass]="setClassActive(moreThan250mSelected)">
    <div class="flex flex-row items-center place-content-between">
      <p class="clickable-card-text px-6 py-12 text-clickable-card">
        {{ resource.dependency.superior250 }}
      </p>
    </div>
  </div>
</div>

<app-web-callback-popup [show]="displayModal" (cancel)="cancel()"></app-web-callback-popup>
