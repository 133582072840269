<app-header></app-header>
<app-resume-step class="container"></app-resume-step>
<div class="container mx-auto">
  <app-info-top></app-info-top>
  <app-question></app-question>
  <router-outlet></router-outlet>
</div>
<div class="container mx-auto h-28 md:h-40">
  <app-footer class="footer"></app-footer>
  <app-web-callback></app-web-callback>
</div>
