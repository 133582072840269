<div class="mx-auto container-h">
  <div *ngIf="headerStep" class="grid grid-cols-4">
    <div class="mt-7 mb-3 mx-5 logo-app">
      <img class="mx-auto mt-5 mb-1" src="{{ logo }}" />
    </div>
    <div class="mt-7 mb-3 col-span-2">
      <p class="title-h mt-4 mb-1">{{ headerStep.step }}</p>
      <p class="subTitle-h mt-1.5">{{ headerStep.subStep }}</p>
    </div>
    <div
      class="mt-10 mb-7.5 px-0 ml-4 sm:mx-14 sm:pr-1 justify-items-start background-norepeat cursor-pointer"
      [ngClass]="getSubStepClass(headerStep.subStepNumber)"
      (click)="showModalDialog()">
      <img class="mt-7 mb-1 mr-auto ml-5 pl-0.5" [src]="getStepSrc(headerStep.stepNumber)" />
    </div>
  </div>
</div>

<p-dialog
  [(visible)]="displayModal"
  [style]="{ width: '345px', height: '660px', margin: '1rem' }"
  [closable]="false"
  [baseZIndex]="10000">
  <ng-template pTemplate="header">
    <p class="titleModal-h mt-4">{{ resource.stepper.mySteps }}</p>
    <img
      class="buttonCloseModal-h cursor-pointer"
      [src]="resource.header.closeModal"
      (click)="displayModal = false"
      alt="close" />
  </ng-template>
  <p class="subTitleModal-h">{{ resource.stepper.returnToStep }}</p>

  <div class="mt-4">
    <div *ngIf="!stepperShow">
      <div *ngFor="let stepper of steppers.devis.titles; let i = index">
        <div class="flex my-1.5">
          <div
            class="flex-none w-8 background-norepeat mt-[3px]"
            [ngClass]="getTitleDevisClass(i, stepper.isActive)"></div>
          <div class="grow mx-3" [ngClass]="getTitleSummaryClass(stepper.isActive)">
            {{ stepper.libelle }}
          </div>
          <div class="flex-none w-8 mt-[5px]"></div>
        </div>
        <div *ngFor="let subStep of stepper.subtitles">
          <div
            class="flex my-1.5"
            *ngIf="!subStep.isValid && !subStep.isActive && subStep.isVisible">
            <div class="flex-none w-8"></div>
            <div class="grow titleStepKO-h">
              {{ subStep.libelle }}
            </div>
            <div class="flex-none w-8 mt-1"></div>
          </div>
          <div
            class="flex my-0.5"
            *ngIf="!subStep.isValid && subStep.isActive && subStep.isVisible">
            <div class="flex-none w-8">
              <div class="subStepEnableBackground background-norepeat rounded-full mx-[3px] my-1">
                <span class="subStepEnableFront background-norepeat rounded-full"></span>
              </div>
            </div>
            <div class="grow titleStepInProgress-h">
              <a [routerLink]="['/' + subStep.url]" routerLinkActive="router-link-active">{{
                subStep.libelle
              }}</a>
            </div>
            <div class="flex-none w-8 mt-1"></div>
          </div>
          <div class="flex my-0.5" *ngIf="subStep.isValid && subStep.isActive && subStep.isVisible">
            <div class="flex-none w-8">
              <div class="stepTracking_bg"></div>
            </div>
            <div class="grow titleStepOK-h">
              <a [routerLink]="['/' + subStep.url]" routerLinkActive="router-link-active">{{
                subStep.libelle
              }}</a>
            </div>
            <div class="flex-none w-8 checkSummary background-norepeat mt-1"></div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="stepperShow">
      <div *ngFor="let stepper of steppers.contract.titles; let i = index">
        <div class="flex my-1.5">
          <div
            class="flex-none w-8 background-norepeat mt-[3px]"
            [ngClass]="getTitleDevisClass(i, stepper.isActive)"></div>
          <div class="grow mx-3" [ngClass]="getTitleSummaryClass(stepper.isActive)">
            {{ stepper.libelle }}
          </div>
          <div class="flex-none w-8 mt-[5px]"></div>
        </div>
        <div *ngFor="let subStep of stepper.subtitles">
          <div
            class="flex my-1.5"
            *ngIf="!subStep.isValid && !subStep.isActive && subStep.isVisible">
            <div class="flex-none w-8"></div>
            <div class="grow titleStepKO-h">
              {{ subStep.libelle }}
            </div>
            <div class="flex-none w-8 mt-1"></div>
          </div>
          <div
            class="flex my-0.5"
            *ngIf="!subStep.isValid && subStep.isActive && subStep.isVisible">
            <div class="flex-none w-8">
              <div class="subStepEnableBackground background-norepeat rounded-full mx-[3px] my-1">
                <span class="subStepEnableFront background-norepeat rounded-full"></span>
              </div>
            </div>
            <div class="grow titleStepInProgress-h">
              <a [routerLink]="['/' + subStep.url]" routerLinkActive="router-link-active">{{
                subStep.libelle
              }}</a>
            </div>
            <div class="flex-none w-8 mt-1"></div>
          </div>
          <div class="flex my-0.5" *ngIf="subStep.isValid && subStep.isActive && subStep.isVisible">
            <div class="flex-none w-8">
              <div class="stepTracking_bg"></div>
            </div>
            <div class="grow titleStepOK-h">
              <a [routerLink]="['/' + subStep.url]" routerLinkActive="router-link-active">{{
                subStep.libelle
              }}</a>
            </div>
            <div class="flex-none w-8 checkSummary background-norepeat mt-1"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
