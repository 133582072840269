import { Formule, SaisieFormule, SaisiePersonnalisation } from './../../models/form-state.model';
import { SaisieSignature } from '../../models/form-state.model';
export interface OfferCustomizationState {
  saisiePersonnalisation: SaisiePersonnalisation;
  isValid: boolean;
  SaisieFormule: SaisieFormule;
  FormuleRecommandee?: Formule;
}

export const initialState: OfferCustomizationState = {
  saisiePersonnalisation: {
    MontantValeurObjets2000: undefined,
    ProtectionJuridique: false,
    ProtectionVelos: false,
    ProtectionNomades: false,
    AssuranceScolaire: undefined
  },
  SaisieFormule: {
    FormuleSelectionnee: undefined,
    CodePromo: undefined,
    ValeurMensuelle: 0,
    ValeurAnnuelle: 0
  },
  FormuleRecommandee: Formule.F1,
  isValid: false
};
