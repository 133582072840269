import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { Handlers } from '../models/handler.model';

@Injectable({
  providedIn: 'root'
})
export class HandlerService {
  public configuration = new Configuration();
  baseUrl: string;

  constructor(private http: HttpClient, configuration: Configuration) {
    this.baseUrl = configuration.apiBasePath ?? '';
  }

  public GetHandlers(productCode: string, distributorCode: string): Observable<Handlers> {
    const requestUrl = `${this.baseUrl}handlers?productCode=${productCode}&distributorCode=${distributorCode}`;
    return this.http.get<Handlers>(requestUrl);
  }
}
