import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { MatomoTracker } from 'ngx-matomo-client';
import { InputMask } from 'primeng/inputmask';
import { PhonePrefixes } from 'src/app/api/models/phonePrefixes.model';
import { PhonePrefixesService } from 'src/app/api/services/phonePrefixes.service';
import { AppResource } from 'src/app/app.resource';
import { State } from 'src/app/core/state/core.state';
import * as fromPayment from 'src/app/core/state/payment';
import * as fromSubscriber from 'src/app/core/state/subscriber';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component.component';

@Component({
  selector: 'app-phone-and-prefix',

  templateUrl: './phone-and-prefix.component.html',

  styleUrls: ['./phone-and-prefix.component.scss']
})
export class PhoneAndPrefixComponent extends BaseComponent implements OnInit {
  @ViewChild('phoneInput') phoneInput!: InputMask;
  @Input() displayConsent: boolean | undefined;
  @Input() displayPaymentInfos: boolean | undefined;
  @Output() phoneControlsAdded = new EventEmitter<FormGroup>();
  @Output() phonePrefixesValues = new EventEmitter<PhonePrefixes[]>();
  readonly defaultCountry = 'France';
  readonly defaultPhonePattern = '6 10 20 30 40';
  readonly defaultPhoneMask = '9 99 99 99 99';

  phoneGroup: FormGroup;
  phonePattern: string = this.defaultPhonePattern;
  phoneMask: string = this.defaultPhoneMask;

  phonePrefixes = new Array<PhonePrefixes>();
  selectedPhonePrefix: PhonePrefixes | undefined;
  isPhonePrefilled: boolean = false;
  isPhoneConsent: boolean = false;
  showPhoneError: boolean = false;

  get phonePrefix() {
    return this.phoneGroup.get('phonePrefix') as FormControl<PhonePrefixes | undefined>;
  }

  get phone() {
    return this.phoneGroup.get('phone') as FormControl<string | undefined>;
  }
  get isPhoneMandatory() {
    return this.phone?.hasValidator(Validators.required) ?? false;
  }

  constructor(
    store: Store<State>,
    resources: AppResource,
    tracker: MatomoTracker,
    private phonePrefixesService: PhonePrefixesService
  ) {
    super(store, resources, tracker);
    this.phoneGroup = new FormGroup({
      phonePrefix: new FormControl<PhonePrefixes | undefined>(
        {
          value: undefined,
          disabled: false
        },
        {
          validators: [Validators.required]
        }
      ),
      phone: new FormControl<string | null>(
        {
          value: null,
          disabled: false
        },
        {
          validators: [this.phoneNumberValidator(), this.phoneNumberFranceValidator()]
        }
      )
    });
  }

  override async ngOnInit(): Promise<void> {
    this.loadData();

    if (this.displayConsent) {
      this.setPhoneToRequired();
      this.addIsPhoneConsentFormControl();
      this.phone?.updateValueAndValidity();
    }
    this.phoneControlsAdded.emit(this.phoneGroup);

    // Add listener on phone value change
    this.phone.valueChanges.subscribe(newValue => {
      // Cancel the first input if the digit is 0 (for France +33 only)
      const phoneFirstZeroCondition: boolean =
        newValue !== undefined &&
        newValue !== null &&
        newValue.length === 1 &&
        newValue.startsWith('0');

      if (
        this.phonePrefix.value &&
        this.phonePrefix.value.region === 'France' &&
        phoneFirstZeroCondition
      ) {
        this.phoneInput.clear();
        this.phoneInput.checkVal(true);
        this.phoneInput.caret(1, 1);
      }
    });
  }

  private loadData() {
    // Load prefixes list
    this.phonePrefixesService.getPhonePrefixes().subscribe(data => {
      this.phonePrefixes = data;
      this.phonePrefixesValues.emit(this.phonePrefixes);

      // Read prefixe and telephone from state
      this.store.select(fromSubscriber.selectSubscriber).subscribe(res => {
        const phonePrefix = res.subscriber?.Indicatif;
        const phone = res.subscriber?.Telephone;

        if (phonePrefix && phone && phone != '') {
          // Prefixe
          this.selectedPhonePrefix = this.phonePrefixes.find(p => p.prefix === phonePrefix);
          this.phonePrefix?.setValue(this.selectedPhonePrefix);
          this.onPhonePrefixChange({ value: this.selectedPhonePrefix });

          // Telephone
          // Note: the setTimeout call is used to make phonePrefix binding apply before updating phone value and avoid input reset
          setTimeout(() => {
            this.phone?.setValue(phone);
            this.isPhonePrefilled = true;
          }, 0);

          this.phoneGroup.updateValueAndValidity();
        } else {
          const defaultPrefix = this.phonePrefixes.find(p => p.region === this.defaultCountry);
          this.selectedPhonePrefix = defaultPrefix;
          this.phonePrefix?.setValue(defaultPrefix);
          this.onPhonePrefixChange({ value: defaultPrefix });
        }
      });

      if (this.displayConsent) {
        this.store.select(fromPayment.selectPayment).subscribe(res => {
          if (res.payment.Telephone && res.payment.Telephone != '') {
            this.phone?.setValue(res.payment.Telephone);
            this.phoneGroup.updateValueAndValidity();
          }

          if (res.payment.PrefixeTel && res.payment.PrefixeTel != '') {
            this.selectedPhonePrefix = this.phonePrefixes.find(p => p === this.phonePrefix?.value);
            this.phonePrefix?.setValue(this.selectedPhonePrefix);
            this.onPhonePrefixChange({ value: this.selectedPhonePrefix });
            this.phoneGroup.updateValueAndValidity();
          }
        });
      }
    });
  }

  onPhonePrefixChange(event: any) {
    const additionalDigitsRequired = event.value.additionalDigitsRequired;
    const useLeadingZero = event.value.region === 'France';
    this.updatePhoneFormat(additionalDigitsRequired, useLeadingZero);
    this.phone?.updateValueAndValidity();
  }

  onPhoneFocus() {
    const phoneValueLength = this.phone.value?.length;
    this.phoneInput.caret(phoneValueLength);
  }

  updatePhoneFormat(digitsCount: number, useLeadingZero: boolean = false) {
    switch (digitsCount) {
      case 6:
        this.phoneMask = '99 99 99';
        this.phonePattern = '75 10 20';
        break;
      case 8:
        this.phoneMask = '99 99 99 99';
        this.phonePattern = '87 10 20 30';
        break;
      case 9:
      default:
        this.phoneMask = (useLeadingZero ? '0' : '') + this.defaultPhoneMask;
        this.phonePattern = (useLeadingZero ? '0' : '') + this.defaultPhonePattern;
    }
  }

  onDropdownKeyDown(event: KeyboardEvent) {
    const pressedKey = event.key.toLowerCase();
    const matchingPrefix = this.phonePrefixes.find(prefix =>
      prefix.region.toLowerCase().startsWith(pressedKey)
    );
    if (matchingPrefix) {
      this.selectedPhonePrefix = matchingPrefix;
    }
  }

  setIsPhoneConsent() {
    this.isPhoneConsent = !this.isPhoneConsent;
    this.phoneGroup.get('isPhoneConsent')?.setValue(this.isPhoneConsent);
  }

  setClassActive(value: boolean): string {
    return value ? 'active' : 'inactive';
  }

  setPhoneToRequired() {
    this.phoneGroup.get('phone')?.addValidators([Validators.required]);
  }

  addIsPhoneConsentFormControl() {
    this.phoneGroup.addControl(
      'isPhoneConsent',
      new FormControl<boolean | null>(
        {
          value: false,
          disabled: false
        },
        {
          validators: [Validators.required],
          nonNullable: true
        }
      )
    );
  }

  phoneNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const additionalDigitsRequired = control.root.get('phoneGroup')?.get('phonePrefix')
        ?.value?.additionalDigitsRequired;
      const phoneNumberLength = control.value ? control.value.length : 0;
      if (
        phoneNumberLength === 0 ||
        (additionalDigitsRequired && phoneNumberLength === additionalDigitsRequired)
      ) {
        return null;
      }
      return { invalidPhoneNumber: true };
    };
  }

  phoneNumberFranceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const phoneNumber = control.value;
      const phonePrefix = control.root.get('phoneGroup')?.get('phonePrefix')?.value?.id;
      if (
        phoneNumber &&
        phonePrefix === 1 &&
        !(phoneNumber.startsWith('6') || phoneNumber.startsWith('7'))
      ) {
        return { invalidPhoneNumberFrance: true };
      }
      return null;
    };
  }

  onPhoneBlur() {
    this.showPhoneError = !!this.phone?.invalid;
  }

  onInput() {
    this.showPhoneError = false;
  }
}
