import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppResource } from 'src/app/app.resource';
import { Resource } from 'src/app/resources/resource';

@Component({
  selector: 'app-error-popup',
  templateUrl: './error-popup.component.html'
})
export class ErrorPopupComponent implements OnInit {
  @Input() show: boolean = false;

  @Input() title: string = '';

  @Input() text: string = '';

  @Output() cancel = new EventEmitter<boolean>();

  public resource!: Resource;

  constructor(private resources: AppResource) {
    this.resource = resources['resource'];
  }

  ngOnInit(): void {}

  onCancel() {
    this.cancel.emit();
  }
}
