import { Component, HostListener, inject, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { environment } from 'src/environments/environment';

import { NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MatomoTracker } from 'ngx-matomo-client';
import { AppResource } from './app.resource';
import { State } from './core/state/core.state';
import { Resource } from './resources/resource';
import { AppInsightsService } from './shared/services/app-insight.service';
import { MatomoInitService } from './shared/services/matomo-init.service';
import { WINDOW, WindowRef } from './shared/services/window.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Vyv-FrontSouscription';
  resource: Resource;
  private windowRef = inject<WindowRef>(WINDOW);
  constructor(
    private config: PrimeNGConfig,
    private appInsightService: AppInsightsService,
    private matomoInitService: MatomoInitService,
    private MatomoTracker: MatomoTracker,
    private router: Router,
    private store: Store<State>,
    protected resources: AppResource,
    private tracker: MatomoTracker
  ) {
    this.resource = resources['resource'];
    this.preloadSVG();
  }

  async ngOnInit() {
    this.matomoInitService.initMatomo();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        const url = event.url;
        if (url.includes('/start/')) {
          this.store.dispatch({ type: '[Init]' });
          sessionStorage.removeItem('state');
        }
      }
    });

    this.config.setTranslation({
      dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      dayNamesMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
      monthNames: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Decembre'
      ],
      monthNamesShort: [
        'Jan',
        'Fév',
        'Mar',
        'Avr',
        'Mai',
        'Juin',
        'Juil',
        'Aou',
        'Sep',
        'Oct',
        'Nov',
        'Déc'
      ],
      dateFormat: 'dd/mm/yy',
      firstDayOfWeek: 1,
      today: "Aujourd'hui",
      weekHeader: 'Wk'
    });
    (this.windowRef as any).matomoHostUrl = environment.matomoHostUrl;
    (this.windowRef as any).matomoSiteId = environment.matomoSiteId;
    (this.windowRef as any).tarteaucitron.user.matomoSiteId = environment.matomoSiteId;
    (this.windowRef as any).tarteaucitron.user.matomoHostUrl = environment.matomoHostUrl;
    document.dispatchEvent(new Event('tarteaucitron_config'));
  }

  loadScript(_url: string) {
    let node = document.createElement('script');
    node.src = _url;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  @HostListener('document:appInsights_allowed', ['$event'])
  onAppInsightsAllowed(ev: any) {
    this.appInsightService.initAppInsights();
  }

  @HostListener('document:appInsights_disallowed', ['$event'])
  onAppInsightsDisallowed(ev: any) {
    this.appInsightService.disableAppInsights();
  }

  @HostListener('document:matomocloud_allowed', ['$event'])
  onMatomoCloudAllowed(ev: any) {
    this.MatomoTracker.setCookieConsentGiven();
    this.MatomoTracker.rememberCookieConsentGiven();
  }

  @HostListener('document:matomocloud_disallowed', ['$event'])
  onMatomoCloudDisallowed(ev: any) {
    this.MatomoTracker.forgetCookieConsentGiven();
    this.MatomoTracker.optUserOut();
  }

  @HostListener('document:matomocloud_accepted', ['$event'])
  onMatomoCloudAccepted(ev: any) {
    this.MatomoTracker.setCookieConsentGiven();
    this.MatomoTracker.rememberCookieConsentGiven();
  }

  @HostListener('document:matomotm_allowed', ['$event'])
  onMatomoTmAllowed(ev: any) {
    this.MatomoTracker.setConsentGiven();
    this.MatomoTracker.rememberConsentGiven();
  }

  @HostListener('document:matomotm_disallowed', ['$event'])
  onMatomoTmDisallowed(ev: any) {
    this.MatomoTracker.forgetConsentGiven();
  }

  @HostListener('document:matomotm_accepted', ['$event'])
  onMatomoTmAccepted(ev: any) {
    this.MatomoTracker.setConsentGiven();
    this.MatomoTracker.rememberConsentGiven();
  }

  @HostListener('document:matomo_allowed', ['$event'])
  onMatomoAllowed(ev: any) {
    this.MatomoTracker.setCookieConsentGiven();
    this.MatomoTracker.rememberCookieConsentGiven();
  }

  @HostListener('document:matomo_disallowed', ['$event'])
  onMatomoDisallowed(ev: any) {
    this.MatomoTracker.forgetCookieConsentGiven();
  }

  @HostListener('document:matomo_accepted', ['$event'])
  onMatomoAccepted(ev: any) {
    this.MatomoTracker.setCookieConsentGiven();
    this.MatomoTracker.rememberCookieConsentGiven();
  }

  preloadSVG() {
    var svgs = [
      this.resource.header.logo,
      this.resource.header.step1,
      this.resource.header.step2,
      this.resource.header.step3,
      this.resource.houseapartment.apartmentPath,
      this.resource.houseapartment.apartmentWhitePath,
      this.resource.houseapartment.housePath,
      this.resource.houseapartment.houseWhitePath,
      this.resource.houseapartment.otherPath,
      this.resource.houseapartment.otherWhitePath,
      this.resource.primarysecondary.primaryPath,
      this.resource.primarysecondary.secondaryPath,
      this.resource.ownertenant.ownerPath,
      this.resource.ownertenant.ownerWhitePath,
      this.resource.ownertenant.tenantPath,
      this.resource.ownertenant.tenantWhitePath
    ];
    var frag = new Array<Node>();

    svgs.forEach(item => {
      var res = document.createElement('link');
      res.rel = 'preload';
      res.type = 'image/svg+xml';
      res.as = 'image';
      res.href = item;
      frag.push(res);
    });

    document.getElementsByTagName('head')[0].append(...frag);
  }
}
