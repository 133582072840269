import { option } from 'src/app/api/models/option.model';

export class SchoolInsuranceVM {
  monthlyRate: string | null;
  annuelRate: string | null;
  options: option[];

  constructor(monthlyRate: string | null, annuelRate: string | null, options: option[]) {
    this.monthlyRate = monthlyRate;
    this.annuelRate = annuelRate;
    this.options = options;
  }
}
