import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GeoCodeJSON } from '../models/components/geocodejson.model';

@Injectable({
  providedIn: 'root'
})
export class AdresseDataGouvService {
  private readonly BASE_URL: string = 'https://api-adresse.data.gouv.fr';

  constructor(private http: HttpClient) {}

  search(adresse: string, autocomplete?: 0 | 1): Observable<GeoCodeJSON> {
    const params = new HttpParams()
      .set('q', adresse)
      .set('autocomplete', autocomplete ? autocomplete.toString() : '')
      .set('type', 'housenumber');

    return this.http.get<GeoCodeJSON>(`${this.BASE_URL}/search`, { params });
  }
}
