import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MatomoTracker } from 'ngx-matomo-client';
import { distinctUntilChanged, lastValueFrom, map, merge, take } from 'rxjs';
import { AppResource } from 'src/app/app.resource';
import { PathType } from 'src/app/core/enums/path-type.enum';
import { Etage, SaisieHabitation, TypeHabitation } from 'src/app/core/models/form-state.model';
import { cloneResumeStep } from 'src/app/core/models/resume-step.model';
import { Step } from 'src/app/core/models/step.model';
import { Stepper } from 'src/app/core/models/stepper.model';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component.component';
import { MatomoConstants } from 'src/app/shared/constants/matomo.constants';
import { PopUpInfoVM } from 'src/app/shared/models/components/popupinfoVm';
import { QuestionVM } from 'src/app/shared/models/components/questionVm';
import { String } from 'typescript-string-operations';
import * as fromAddress from '../../../core/state/address';
import { State } from '../../../core/state/core.state';
import * as fromHousing from '../../../core/state/housing';
import { HousingState } from '../../../core/state/housing';
import * as fromInfo from '../../../core/state/info';
import * as fromPath from '../../../core/state/path';

export interface IdFloorValue {
  id: Etage;
  name: string;
}

interface DropDownList {
  value: number;
  text: string;
}

@Component({
  selector: 'app-number-pieces',
  templateUrl: './number-pieces.component.html',
  styleUrls: ['./number-pieces.component.scss']
})
export class NumberPiecesComponent extends BaseComponent implements OnInit {
  mouseYesOvered = false;
  mouseNoOvered = false;

  yesSelected = false;
  noSelected = false;

  override step: Step = {
    step: this.resource.header.stepHousing,
    stepNumber: 1,
    subStep: this.resource.header.subStepNumberPieces,
    subStepNumber: 6
  };

  public override currentPage = MatomoConstants.NOMBRE_PIECES;

  questionFloor = new QuestionVM(this.resource.question.numberPieces);
  popupInfoFloor = new PopUpInfoVM(this.resource.infobulle.numberPieces, '', '', '', '');
  questionVeranda = new QuestionVM('Avez-vous une véranda ?');
  popupInfoVeranda = new PopUpInfoVM(this.resource.infobulle.veranda1, '', '', '', '');
  roomsForm!: FormGroup;

  rooms: DropDownList[] = [];
  selectedRoom!: DropDownList | undefined;

  roomsMoreThan: DropDownList[] = [];
  selectedRoomMoreThan!: DropDownList | undefined;

  floors: IdFloorValue[] = [];
  selectedFloor!: IdFloorValue | undefined;

  displayModal = false;
  ready: boolean = false;
  habitation: TypeHabitation | undefined;
  path!: PathType;

  PathType = PathType;
  max!: number;

  get numberOfRooms() {
    return this.roomsForm.get('numberOfRooms');
  }

  get numberOfRoomsMoreThan() {
    return this.roomsForm.get('numberOfRoomsMoreThan');
  }

  get floor() {
    return this.roomsForm.get('floor');
  }

  constructor(
    store: Store<State>,
    resources: AppResource,
    tracker: MatomoTracker,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {
    super(store, resources, tracker);
  }

  override async ngOnInit() {
    this.updateResumeStepVisibility(true);
    await this.loadState();
    this.loadFloors();
    this.loadRooms();
    this.loadRoomsMoreThan();
    this.initRightForm();
    await this.loadStateData();
    super.ngOnInit();
  }

  initRightForm() {
    switch (this.path) {
      case PathType.HOUSING_SOLIDARITY:
        if (this.habitation === TypeHabitation.Appartement) {
          this.initFormSolidarityFlat();
        } else {
          this.initFormSolidarity();
        }

        break;
      case PathType.HOUSING_TOURCOING:
      case PathType.HOUSING_PARIS:
        if (this.habitation === TypeHabitation.Appartement) {
          this.initFormParisFlat();
        } else {
          this.initFormParis();
        }
        break;
      case PathType.HOUSING:
        if (this.habitation == TypeHabitation.Appartement) {
          this.initFormFlat();
        } else {
          this.initForm();
        }
        break;
      default:
        this.initForm();
        break;
    }
  }

  loadRooms() {
    this.rooms.push({ value: 1, text: '1 pièce' });
    for (var i = 2; i < this.max; ++i) {
      this.rooms.push({ value: i, text: `${i} pièces` });
    }
    this.rooms.push({ value: this.max, text: `${this.max} pièces et plus` });
  }

  loadRoomsMoreThan() {
    this.roomsMoreThan.push({ value: 0, text: '0 pièce' });
    this.roomsMoreThan.push({ value: 1, text: '1 pièce' });
    for (var i = 2; i < this.max; ++i) {
      this.roomsMoreThan.push({ value: i, text: `${i} pièces` });
    }
    this.roomsMoreThan.push({ value: this.max, text: `${this.max} pièces et plus` });
  }

  loadFloors() {
    this.floors.push(
      { id: Etage.RezDeChaussee, name: 'Rez de chaussée' },
      { id: Etage.Intermediaire, name: 'Intermédiaire' },
      { id: Etage.DernierEtage, name: 'Dernier étage' }
    );
  }

  initFormFlat() {
    this.roomsForm = new FormGroup(
      {
        numberOfRooms: new FormControl<number | null>(null, {
          validators: [Validators.required],
          nonNullable: true
        }),
        numberOfRoomsMoreThan: new FormControl<number | null>(null, {
          validators: [Validators.required],
          nonNullable: true
        }),
        floor: new FormControl<number | null>(null, {
          validators: [Validators.required],
          nonNullable: true
        })
      },
      {
        updateOn: 'change',
        validators: [numberRoomsValidator('numberOfRooms', 'numberOfRoomsMoreThan')]
      }
    );

    this.roomsForm.valueChanges.subscribe(res => {
      this.cd.detectChanges();
    });

    this.roomsForm.valueChanges
      .pipe(
        map(() => this.roomsForm.valid),
        distinctUntilChanged()
      )
      .subscribe((isValid: boolean) => {
        this.store.dispatch(fromHousing.patchNumberRoomsValidity({ isValid }));
      });

    const numberOfRooms$ = this.numberOfRooms?.valueChanges.pipe(
      map((val: DropDownList) => {
        var NombrePieces = val.value;
        return { NombrePieces } as Partial<SaisieHabitation>;
      })
    );

    const numberofRoomsMoreThan$ = this.numberOfRoomsMoreThan?.valueChanges.pipe(
      map((val: DropDownList) => {
        var NombrePiecesSuperieures = val.value;
        return { NombrePiecesSuperieures } as Partial<SaisieHabitation>;
      })
    );

    const floor$ = this.floor?.valueChanges.pipe(
      map((val: IdFloorValue) => {
        var Etage = undefined;

        if (val !== undefined) Etage = val.id;

        return { Etage } as Partial<SaisieHabitation>;
      })
    );

    merge(numberOfRooms$!, numberofRoomsMoreThan$!, floor$!).subscribe(
      (payload: Partial<SaisieHabitation>) => {
        this.store.dispatch(fromHousing.patchNumberOfPeople({ payload }));
      }
    );

    this.ready = true;
  }

  initForm() {
    this.roomsForm = new FormGroup(
      {
        numberOfRooms: new FormControl<number | null>(null, {
          validators: [Validators.required],
          nonNullable: true
        }),
        numberOfRoomsMoreThan: new FormControl<number | null>(null, {
          validators: [Validators.required],
          nonNullable: true
        })
      },
      {
        updateOn: 'change',
        validators: [numberRoomsValidator('numberOfRooms', 'numberOfRoomsMoreThan')]
      }
    );

    this.roomsForm.valueChanges
      .pipe(
        map(() => this.roomsForm.valid),
        distinctUntilChanged()
      )
      .subscribe((isValid: boolean) => {
        this.store.dispatch(fromHousing.patchNumberRoomsValidity({ isValid }));
      });

    const numberOfRooms$ = this.numberOfRooms?.valueChanges.pipe(
      map((val: DropDownList) => {
        var NombrePieces = val.value;
        return { NombrePieces } as Partial<SaisieHabitation>;
      })
    );

    const numberofRoomsMoreThan$ = this.numberOfRoomsMoreThan?.valueChanges.pipe(
      map((val: DropDownList) => {
        var NombrePiecesSuperieures = val.value;
        return { NombrePiecesSuperieures } as Partial<SaisieHabitation>;
      })
    );

    merge(numberOfRooms$!, numberofRoomsMoreThan$!).subscribe(
      (payload: Partial<SaisieHabitation>) => {
        this.store.dispatch(fromHousing.patchNumberOfPeople({ payload }));
      }
    );

    this.ready = true;
  }

  initFormSolidarity() {
    this.roomsForm = new FormGroup(
      {
        numberOfRooms: new FormControl<number | null>(null, {
          validators: [Validators.required],
          nonNullable: true
        })
      },
      { updateOn: 'change' }
    );
    this.roomsForm.valueChanges
      .pipe(
        map(() => this.roomsForm.valid),
        distinctUntilChanged()
      )
      .subscribe((isValid: boolean) => {
        this.store.dispatch(fromHousing.patchNumberRoomsValidity({ isValid }));
      });

    const numberOfRooms$ = this.numberOfRooms?.valueChanges.pipe(
      map((val: DropDownList) => {
        var NombrePieces = val.value;
        return { NombrePieces } as Partial<SaisieHabitation>;
      })
    );

    merge(numberOfRooms$!).subscribe((payload: Partial<SaisieHabitation>) => {
      this.store.dispatch(fromHousing.patchNumberOfPeople({ payload }));
    });
    this.ready = true;
  }

  initFormSolidarityFlat() {
    this.roomsForm = new FormGroup(
      {
        numberOfRooms: new FormControl<number | null>(null, {
          validators: [Validators.required],
          nonNullable: true
        }),
        floor: new FormControl<number | null>(null, {
          validators: [Validators.required],
          nonNullable: true
        })
      },
      { updateOn: 'change' }
    );
    this.roomsForm.valueChanges
      .pipe(
        map(() => this.roomsForm.valid),
        distinctUntilChanged()
      )
      .subscribe((isValid: boolean) => {
        this.store.dispatch(fromHousing.patchNumberRoomsValidity({ isValid }));
      });

    const numberOfRooms$ = this.numberOfRooms?.valueChanges.pipe(
      map((val: DropDownList) => {
        var NombrePieces = val.value;
        return { NombrePieces } as Partial<SaisieHabitation>;
      })
    );

    const floor$ = this.floor?.valueChanges.pipe(
      map((val: IdFloorValue) => {
        var Etage = undefined;

        if (val !== undefined) Etage = val.id;

        return { Etage } as Partial<SaisieHabitation>;
      })
    );

    merge(numberOfRooms$!, floor$!).subscribe((payload: Partial<SaisieHabitation>) => {
      this.store.dispatch(fromHousing.patchNumberOfPeople({ payload }));
    });
    this.ready = true;
  }

  initFormParis() {
    this.roomsForm = new FormGroup(
      {
        numberOfRooms: new FormControl<number | null>(null, {
          validators: [Validators.required],
          nonNullable: true
        })
      },
      { updateOn: 'change' }
    );

    this.roomsForm.valueChanges
      .pipe(
        map(() => this.roomsForm.valid),
        distinctUntilChanged()
      )
      .subscribe((isValid: boolean) => {
        this.store.dispatch(fromHousing.patchNumberRoomsValidity({ isValid }));
      });

    const numberOfRooms$ = this.numberOfRooms?.valueChanges.pipe(
      map((val: DropDownList) => {
        var NombrePieces = val.value;
        return { NombrePieces } as Partial<SaisieHabitation>;
      })
    );

    merge(numberOfRooms$!).subscribe((payload: Partial<SaisieHabitation>) => {
      this.store.dispatch(fromHousing.patchNumberOfPeople({ payload }));
    });

    this.ready = true;
  }

  initFormParisFlat() {
    this.roomsForm = new FormGroup(
      {
        numberOfRooms: new FormControl<number | null>(null, {
          validators: [Validators.required],
          nonNullable: true
        }),
        floor: new FormControl<number | null>(null, {
          validators: [Validators.required],
          nonNullable: true
        })
      },
      { updateOn: 'change' }
    );

    this.roomsForm.valueChanges
      .pipe(
        map(() => this.roomsForm.valid),
        distinctUntilChanged()
      )
      .subscribe((isValid: boolean) => {
        this.store.dispatch(fromHousing.patchNumberRoomsValidity({ isValid }));
      });

    const numberOfRooms$ = this.numberOfRooms?.valueChanges.pipe(
      map((val: DropDownList) => {
        var NombrePieces = val.value;
        return { NombrePieces } as Partial<SaisieHabitation>;
      })
    );

    const floor$ = this.floor?.valueChanges.pipe(
      map((val: IdFloorValue) => {
        var Etage = undefined;

        if (val !== undefined) Etage = val.id;

        return { Etage } as Partial<SaisieHabitation>;
      })
    );
    merge(numberOfRooms$!, floor$!).subscribe((payload: Partial<SaisieHabitation>) => {
      this.store.dispatch(fromHousing.patchNumberOfPeople({ payload }));
    });

    this.ready = true;
  }

  onVerandaSelected() {
    this.yesSelected = !this.yesSelected;
    if (this.noSelected) {
      this.noSelected = false;
    }

    this.store.dispatch(
      fromHousing.patchOccupantType({
        payload: { AvecVeranda: true }
      })
    );
  }

  onVerandaUnselected() {
    this.noSelected = !this.noSelected;
    if (this.yesSelected) {
      this.yesSelected = false;
    }

    this.store.dispatch(
      fromHousing.patchOccupantType({
        payload: { AvecVeranda: false }
      })
    );
  }

  cancel() {
    this.displayModal = false;
    this.initRightForm();
    this.yesSelected = false;
    this.noSelected = false;
  }

  async nextStep() {
    if (
      this.numberOfRooms?.value.value == this.max ||
      this.numberOfRoomsMoreThan?.value.value == this.max ||
      this.numberOfRooms?.value.value + this.numberOfRoomsMoreThan?.value.value >= this.max
    ) {
      this.displayModal = true;
      this.tracker.trackEvent(MatomoConstants.TOO_MANY_ROOMS, MatomoConstants.NOMBRE_PIECES);
      this.tracker.trackEvent(MatomoConstants.ARRET_PARCOURS, MatomoConstants.NOMBRE_PIECES);
    } else {
      await this.updateResumeStep();
      let address = await lastValueFrom(this.store.select(fromAddress.selectAddress).pipe(take(1)));

      var housingData = await lastValueFrom(
        this.store.select(fromHousing.selectHousing).pipe(take(1))
      );

      await this.updateStepper(this.path, address.isZoneInondable, housingData);

      switch (this.path) {
        case PathType.HOUSING:
          if (!address.isZoneInondable) {
            this.router.navigate(['dependency']);
          } else {
            if (
              housingData.SaisieHabitation.TypeHabitation == TypeHabitation.Maison ||
              (housingData.SaisieHabitation.TypeHabitation == TypeHabitation.Appartement &&
                housingData.SaisieHabitation.Etage == Etage.RezDeChaussee)
            )
              this.router.navigate(['previousclaim']);
            else this.router.navigate(['dependency']);
          }
          break;
        case PathType.HOUSING_TOURCOING:
        case PathType.HOUSING_PARIS:
        case PathType.HOUSING_SOLIDARITY:
          this.router.navigate(['subscriber']);
          break;
      }
    }
  }

  private async loadState() {
    this.habitation = await lastValueFrom(
      this.store.select(fromHousing.selectHousingType).pipe(take(1))
    );
    this.path = await lastValueFrom(this.store.select(fromPath.selectPathType).pipe(take(1)));

    this.max =
      this.path === PathType.HOUSING_PARIS ||
      this.path === PathType.HOUSING_SOLIDARITY ||
      this.path === PathType.HOUSING_TOURCOING
        ? 9
        : 13;
    if (this.path === PathType.HOUSING_SOLIDARITY) {
      this.store.dispatch(
        fromHousing.patchOccupantType({
          payload: { AvecVeranda: undefined }
        })
      );
    }
  }

  private async loadStateData() {
    var housingData = await lastValueFrom(
      this.store.select(fromHousing.selectHousing).pipe(take(1))
    );
    if (
      housingData.SaisieHabitation.NombrePieces != null &&
      housingData.SaisieHabitation.NombrePieces != undefined
    )
      this.selectedRoom = this.rooms.find(obj => {
        return obj.value === housingData.SaisieHabitation.NombrePieces;
      });
    else
      this.selectedRoom = this.rooms.find(obj => {
        return obj.value === 1;
      });

    if (
      this.numberOfRoomsMoreThan != null &&
      housingData.SaisieHabitation.NombrePiecesSuperieures != null &&
      housingData.SaisieHabitation.NombrePiecesSuperieures != undefined
    )
      this.selectedRoomMoreThan = this.roomsMoreThan.find(obj => {
        return obj.value === housingData.SaisieHabitation.NombrePiecesSuperieures;
      });
    else
      this.selectedRoomMoreThan = this.roomsMoreThan.find(obj => {
        return obj.value === 0;
      });

    if (
      housingData.SaisieHabitation.Etage != null &&
      housingData.SaisieHabitation.Etage != Etage.Undefined
    )
      this.selectedFloor = this.floors.find(obj => {
        return obj.id === housingData.SaisieHabitation.Etage;
      });

    if (
      housingData.SaisieHabitation.AvecVeranda != null &&
      housingData.SaisieHabitation.AvecVeranda != undefined
    ) {
      if (housingData.SaisieHabitation.AvecVeranda) {
        this.yesSelected = true;
      } else {
        this.noSelected = true;
      }
    }
  }

  async updateStepper(path: string, isZoneInondable: boolean, housingData: HousingState) {
    let stepper = await lastValueFrom(this.store.select(fromInfo.selectStepper).pipe(take(1)));
    var tmpStepper = JSON.parse(JSON.stringify(stepper)) as Stepper;

    let title = tmpStepper.devis.titles.find(
      (x: { libelle: string }) => x.libelle == this.resource.stepper.lodging
    );

    let subtitleS = title?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.numberpieces
    );

    if (subtitleS) subtitleS.isValid = true;

    let subtitleN;
    let titleN;

    switch (this.path) {
      case PathType.HOUSING:
        if (!isZoneInondable) {
          subtitleN = title?.subtitles.find(
            (x: { url: string }) => x.url == this.resource.stepper.dependency
          );
        } else {
          if (
            housingData.SaisieHabitation.TypeHabitation == TypeHabitation.Maison ||
            (housingData.SaisieHabitation.TypeHabitation == TypeHabitation.Appartement &&
              housingData.SaisieHabitation.Etage == Etage.RezDeChaussee)
          )
            subtitleN = title?.subtitles.find(
              (x: { url: string }) => x.url == this.resource.stepper.previousclaim
            );
          else
            subtitleN = title?.subtitles.find(
              (x: { url: string }) => x.url == this.resource.stepper.dependency
            );
        }
        break;
      case PathType.HOUSING_PARIS:
      case PathType.HOUSING_SOLIDARITY:
        titleN = tmpStepper.devis.titles.find(
          (x: { libelle: string }) => x.libelle == this.resource.stepper.offer
        );

        if (titleN) titleN.isActive = true;

        subtitleN = titleN?.subtitles.find(
          (x: { url: string }) => x.url == this.resource.stepper.subscriber
        );
        break;
    }

    if (subtitleN) {
      subtitleN.isActive = true;
      subtitleN.isVisible = true;
    }

    this.store.dispatch(fromInfo.updateStepper({ payload: tmpStepper }));
  }

  async updateResumeStep() {
    const resumeStep = await lastValueFrom(
      this.store.select(fromInfo.selectCurrentResumeStep).pipe(take(1))
    );

    const tmpResumeStep = cloneResumeStep(resumeStep);

    let stepResumeStepNBP = tmpResumeStep.steps?.find(
      (x: { id: string }) => x.id == this.resource.resumestep.numberpieces
    );
    let libelleNBP = '';

    if (this.numberOfRoomsMoreThan?.value.value != undefined)
      libelleNBP =
        String.format(this.resource.resumestep.descriptionPiece, this.numberOfRooms?.value.value) +
        ' ' +
        String.format(
          this.resource.resumestep.descriptionPiece2,
          this.numberOfRoomsMoreThan?.value.value
        );
    else
      libelleNBP = String.format(
        this.resource.resumestep.descriptionPiece,
        this.numberOfRooms?.value.value
      );

    if (stepResumeStepNBP) {
      if (stepResumeStepNBP) stepResumeStepNBP.step = libelleNBP;
    } else {
      tmpResumeStep.steps?.push({
        id: this.resource.resumestep.numberpieces,
        step: libelleNBP,
        url: this.resource.resumestep.numberpieces,
        isVisible: true
      });
    }
    this.store.dispatch(fromInfo.updateResumeStep({ payload: tmpResumeStep }));
  }

  setClassActive(value: boolean): string {
    return value ? 'active' : 'inactive';
  }
}

export function numberRoomsValidator(field1: string, field2: string) {
  return function (frm: any) {
    let field1Value = frm.get(field1).value;
    let field2Value = frm.get(field2).value;

    if (frm != null) {
      if (field1Value != null && field2Value != null) {
        if (field1Value.value < field2Value.value) {
          return { numberCheck: {} };
        } else {
          return null;
        }
      }
    }

    return null;
  };
}
