<div class="flex mt-6 mb-6 items-center clickable-card-parent">
  <div
    class="mx-auto clickable-card flex flex-col mb-4 content-center items-center w-full cursor-pointer"
    (click)="onTenantSelected()"
    [ngClass]="setClassActive(tenantSelected)">
    <div class="tenant"></div>
    <div class="flex flex-row items-center pb-8 place-content-between">
      <p class="mr-2">{{ resource.ownertenant.tenant }}</p>
    </div>
  </div>
  <div
    class="mx-auto clickable-card flex flex-col mb-4 content-center items-center w-full cursor-pointer"
    (click)="onOwnerSelected()"
    [ngClass]="setClassActive(ownerSelected)">
    <div class="owner"></div>
    <div class="flex flex-row items-center pb-8 place-content-between">
      <p class="mr-2">{{ resource.ownertenant.owner }}</p>
    </div>
  </div>
</div>
