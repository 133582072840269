<p-dialog
  [header]="title"
  [(visible)]="show"
  [modal]="true"
  [style]="{ width: '330px', margin: '1rem' }"
  [closable]="true"
  [baseZIndex]="10000"
  (onHide)="onCancel()">
  <p class="text-P my-5 whitespace-pre-line">
    {{ text }}
  </p>
</p-dialog>
