import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InfoState } from './info.state';

export const selectInfo = createFeatureSelector<InfoState>('info');

export const selectCurrentStep = createSelector(selectInfo, (state: InfoState) => state.step);

export const selectCurrentQuestion = createSelector(
  selectInfo,
  (state: InfoState) => state.question
);

export const selectCurrentResumeStep = createSelector(
  selectInfo,
  (state: InfoState) => state.resumeStep
);

export const selectCurrentInfo = createSelector(selectInfo, (state: InfoState) => state.info);

export const selectNextUrl = createSelector(selectInfo, (state: InfoState) => state.nextUrl);

export const selectStepper = createSelector(selectInfo, (state: InfoState) => state.stepper);

export const selectCurrentPage = createSelector(
  selectInfo,
  (state: InfoState) => state.currentPage
);
