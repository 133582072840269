import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import * as fromPath from './path.actions';
import { HandlerService } from 'src/app/api/services/handler.service';
import { PathType } from '../../enums/path-type.enum';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../core/state/core.state';
import { DistributorCode } from 'src/app/shared/models/enum/distributorCode.enum';

@Injectable()
export class PathEffects {
  updateTypeEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromPath.updateType),
        map(action => action.pathType),
        tap((pathType: PathType) => {
          this.getHandlers(pathType, DistributorCode.VY);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private handlerService: HandlerService,
    protected store: Store<fromRoot.State>
  ) {}

  getHandlers(productCode: string, distributorCode: string): void {
    this.handlerService.GetHandlers(productCode, distributorCode).subscribe(handlers => {
      this.store.dispatch(fromPath.updateHandlers({ handlers }));
    });
  }
}
