<div
  id="scrollResumeStep"
  *ngIf="resumeStep?.isVisible"
  class="container-resumestep md:container flex mx-auto px-5 md:px-3 my-auto pb-1">
  <div class="container" [ngClass]="getAlign()">
    <div
      *ngFor="let step of resumeStep.steps"
      class="text-resumestep"
      [ngClass]="{ 'text-resumestepHM': brand === 'HM' }">
      <a
        *ngIf="step.isVisible"
        matomoClickAction="Stepper"
        [routerLink]="['/' + step.url]"
        [ngClass]="{ 'text-resumestepCurrent': step.current }">
        {{ step.step }}</a
      >
    </div>
  </div>
</div>
