import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MatomoTracker } from 'ngx-matomo-client';
import { first, lastValueFrom, take } from 'rxjs';
import { option } from 'src/app/api/models/option.model';
import { ClientAdvisorService } from 'src/app/api/services/clientAdvisor.service';
import { QuotationService } from 'src/app/api/services/quotation.service';
import { AppResource } from 'src/app/app.resource';
import { PathType } from 'src/app/core/enums/path-type.enum';
import {
  AssuranceScolaire,
  Formule,
  FormuleApi,
  MontantValeurObjets2000,
  SaisieFormule,
  SaisieHabitation,
  SaisieInfosSouscripteur,
  SaisiePersonnalisation,
  Tarification
} from 'src/app/core/models/form-state.model';
import { Stepper } from 'src/app/core/models/stepper.model';
import * as fromAddress from 'src/app/core/state/address';
import { AddressState } from 'src/app/core/state/address';
import * as fromContext from 'src/app/core/state/context';
import { State } from 'src/app/core/state/core.state';
import * as fromHousing from 'src/app/core/state/housing';
import * as fromInfo from 'src/app/core/state/info';
import * as fromOfferCustomization from 'src/app/core/state/offer-customization';
import * as fromPath from 'src/app/core/state/path';
import * as fromService from 'src/app/core/state/service';
import * as fromSubscriber from 'src/app/core/state/subscriber';
import * as fromTarification from 'src/app/core/state/tarification';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component.component';
import { HeaderVM } from 'src/app/shared/models/components/headerVm';
import { QuestionVM } from 'src/app/shared/models/components/questionVm';
import { SchoolInsuranceVM } from 'src/app/shared/models/components/schoolInsuranceVm';
import { OfferCustomizationEnum } from 'src/app/shared/models/enum/offercustomization.enum';
import { QuotationSummaryService } from 'src/app/shared/services/quotation-summary.service';
import { ApiRequestBodyUtils } from 'src/app/shared/utils/apiRequestBodyUtils';
import { DateUtils } from 'src/app/shared/utils/dateUtils';
import { roundToMonth } from 'src/app/shared/utils/mathUtils';
import { environment } from 'src/environments/environment';
import { String } from 'typescript-string-operations';

export interface children {
  lastname: string;
  firstname: string;
  birthday: string;
  enable: boolean;
}

@Component({
  selector: 'app-school-insurance',
  templateUrl: './school-insurance.component.html',
  styleUrls: ['./school-insurance.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SchoolInsuranceComponent extends BaseComponent implements OnInit {
  headerStep!: HeaderVM;
  override question!: QuestionVM;

  iB_CP: boolean = false;
  iB_C: boolean = false;
  iB_P: boolean = false;
  iB_S: boolean = false;

  rate: number = 0;
  promo: boolean = false;
  codePromo: string = '';
  baseOption!: string;

  birthday!: Date;
  schoolInsuranceVm!: SchoolInsuranceVM;
  options: option[] = [];
  form!: FormGroup;
  addNC: boolean = false;
  maxDate = new Date();
  schoolInsurances: AssuranceScolaire[] = [];

  context!: string;
  product!: PathType;
  address!: AddressState;
  saisieHabitation!: SaisieHabitation;
  saisieInfosSouscripteur!: SaisieInfosSouscripteur;
  tarification!: Tarification;
  path!: PathType;
  currentFormule!: FormuleApi;
  offer!: SaisieFormule;
  formuleRecommandee!: Formule;
  customization!: SaisiePersonnalisation;
  displayError: boolean = false;

  clientAdvisorFullName: string = '';

  isParis: boolean = false;
  isTourcoing: boolean = false;
  get childrens() {
    return this.form.get('childrens') as FormArray;
  }

  get brand() {
    return environment.brand;
  }

  isLoading: boolean = false;

  constructor(
    store: Store<State>,
    tracker: MatomoTracker,
    resources: AppResource,
    private fb: FormBuilder,
    private currencyPipe: CurrencyPipe,
    private quotationService: QuotationService,
    private quotationSummaryService: QuotationSummaryService,
    private clientAdvisorService: ClientAdvisorService
  ) {
    super(store, resources, tracker);
    this.headerStep = new HeaderVM(
      this.brand === 'HM' ? this.resource.header.stepQuote : this.resource.header.stepOffer,
      2,
      this.resource.header.subStepCustomization,
      9
    );

    this.question = new QuestionVM(this.resource.question.customization3);
  }

  override async ngOnInit() {
    super.ngOnInit();

    let contextId = await lastValueFrom(
      this.store.select(fromContext.selectContextId).pipe(take(1))
    );
    this.context = contextId!;

    this.product = await lastValueFrom(this.store.select(fromPath.selectPathType).pipe(take(1)));

    this.address = await lastValueFrom(this.store.select(fromAddress.selectAddress).pipe(take(1)));

    const housing = await lastValueFrom(this.store.select(fromHousing.selectHousing).pipe(take(1)));
    this.saisieHabitation = housing.SaisieHabitation;

    this.tarification = await lastValueFrom(
      this.store.select(fromTarification.selectTarificationState).pipe(take(1))
    );

    const offerCustomization = await lastValueFrom(
      this.store.select(fromOfferCustomization.selectOfferCustomization).pipe(take(1))
    );
    this.offer = offerCustomization.SaisieFormule;
    this.formuleRecommandee = offerCustomization.FormuleRecommandee!;
    this.customization = offerCustomization.saisiePersonnalisation;

    const subscriber = await lastValueFrom(
      this.store.select(fromSubscriber.selectSubscriber).pipe(take(1))
    );
    this.saisieInfosSouscripteur = subscriber.subscriber!;

    this.currentFormule = this.tarification.Formules?.find(
      x => x.NomFormule === this.offer.FormuleSelectionnee
    )!;

    this.clientAdvisorFullName = await this.clientAdvisorService.getClientAdvisorFullName();

    await this.initRateBase();

    this.schoolInsuranceVm = new SchoolInsuranceVM(
      this.currencyPipe.transform(roundToMonth(this.rate, 2)),
      this.currencyPipe.transform(this.rate),
      this.options
    );

    this.setOptionBase();
  }

  setOptionBase() {
    if (this.iB_CP) {
      if (this.promo)
        this.baseOption = String.format(
          this.resource.offercustomization.baseConfortPlusPromo,
          this.codePromo
        );
      else this.baseOption = this.resource.offercustomization.baseConfortPlus;
    } else if (this.iB_C) {
      if (this.promo)
        this.baseOption = String.format(
          this.resource.offercustomization.baseConfortPromo,
          this.codePromo
        );
      else this.baseOption = this.resource.offercustomization.baseConfort;
    } else if (this.iB_P) {
      if (this.promo)
        this.baseOption = String.format(
          this.isParis
            ? this.resource.offercustomization.baseParisiennePromo
            : this.resource.offercustomization.baseTourquennoisePromo,
          this.codePromo
        );
      else
        this.baseOption = this.isParis
          ? this.resource.offercustomization.baseParisienne
          : this.resource.offercustomization.baseTourquennoise;
    } else if (this.iB_S) {
      if (this.promo)
        this.baseOption = String.format(
          this.resource.offercustomization.baseSolidairePromo,
          this.codePromo
        );
      else this.baseOption = this.resource.offercustomization.baseSolidaire;
    }

    this.options.push({
      id: OfferCustomizationEnum.Base,
      name: this.baseOption,
      valueShow: this.currencyPipe.transform(this.offer.ValeurMensuelle),
      monthlyValue: this.offer.ValeurMensuelle,
      annuelValue: this.offer.ValeurAnnuelle,
      optional: false
    });

    this.form = this.fb.group({
      childrens: this.fb.array([this.children()])
    });

    this.loadStateData();

    this.updateRate();
  }

  async initRateBase() {
    this.path = await lastValueFrom(this.store.select(fromPath.selectPathType).pipe(take(1)));

    if (this.path == PathType.HOUSING) {
      if (this.offer.FormuleSelectionnee == Formule.F1) {
        this.iB_C = true;
      } else if (this.offer.FormuleSelectionnee == Formule.F2) {
        this.iB_CP = true;
      }
    } else if (this.path == PathType.HOUSING_SOLIDARITY) {
      this.iB_S = true;
    } else if (this.path == PathType.HOUSING_PARIS || this.path == PathType.HOUSING_TOURCOING) {
      if (this.path == PathType.HOUSING_PARIS) this.isParis = true;
      if (this.path == PathType.HOUSING_TOURCOING) this.isTourcoing = true;
      this.iB_P = true;
    } else {
      if (this.offer.FormuleSelectionnee == Formule.F1) {
        this.iB_C = true;
      } else if (this.offer.FormuleSelectionnee == Formule.F2) this.iB_CP = true;
    }
    this.rate = this.currentFormule?.PrimeMensuelle!;

    if (this.offer.CodePromo != null && this.offer.CodePromo != '') {
      this.promo = true;
      this.codePromo = this.offer.CodePromo;
    }
  }

  private loadStateData() {
    this.store
      .select(fromOfferCustomization.selectOfferCustomization)
      .pipe(first())
      .subscribe(res => {
        if (res.saisiePersonnalisation.MontantValeurObjets2000 != null) {
          switch (res.saisiePersonnalisation.MontantValeurObjets2000) {
            case MontantValeurObjets2000.Montant5000:
              if (this.iB_CP) {
                this.options.push({
                  id: OfferCustomizationEnum.Object,
                  name: this.resource.offercustomization.option5k,
                  valueShow: this.resource.offercustomization.rateInclude,
                  monthlyValue: 0,
                  annuelValue: 0,
                  optional: false
                });
              }
              break;
            case MontantValeurObjets2000.Montant10000:
              this.options.push({
                id: OfferCustomizationEnum.Object,
                name: this.resource.offercustomization.option10k,
                valueShow: this.currencyPipe.transform(
                  roundToMonth(this.currentFormule.PrimesOptions?.ObjetsValeur?.Valeur10000, 2)
                ),
                monthlyValue: roundToMonth(
                  this.currentFormule.PrimesOptions?.ObjetsValeur?.Valeur10000!,
                  2
                ),
                annuelValue: this.currentFormule.PrimesOptions?.ObjetsValeur?.Valeur10000!,
                optional: false
              });
              break;
            case MontantValeurObjets2000.Montant20000:
              this.options.push({
                id: OfferCustomizationEnum.Object,
                name: this.resource.offercustomization.option20k,
                valueShow: this.currencyPipe.transform(
                  roundToMonth(this.currentFormule.PrimesOptions?.ObjetsValeur?.Valeur15000, 2)
                ),
                monthlyValue: roundToMonth(
                  this.currentFormule.PrimesOptions?.ObjetsValeur?.Valeur15000,
                  2
                ),
                annuelValue: this.currentFormule.PrimesOptions?.ObjetsValeur?.Valeur15000!,
                optional: false
              });
              break;
          }
        } else {
          if (this.iB_CP) {
            this.options.push({
              id: OfferCustomizationEnum.Object,
              name: this.resource.offercustomization.option5k,
              valueShow: this.resource.offercustomization.rateInclude,
              monthlyValue: 0,
              annuelValue: 0,
              optional: false
            });
          }

          if (res.saisiePersonnalisation.AssuranceScolaire == undefined) {
            this.options.push({
              id: OfferCustomizationEnum.SI,
              name: this.resource.offercustomization.optionSI,
              valueShow: '',
              monthlyValue: 0,
              annuelValue: 0,
              optional: false
            });
          }
        }

        if (res.saisiePersonnalisation.ProtectionVelos) {
          this.options.push({
            id: OfferCustomizationEnum.BP,
            name: this.resource.offercustomization.optionBP,
            valueShow: this.currencyPipe.transform(
              roundToMonth(this.currentFormule.PrimesOptions?.ProtectionVelos!, 2)
            ),
            monthlyValue: roundToMonth(this.currentFormule.PrimesOptions?.ProtectionVelos!, 2),
            annuelValue: this.currentFormule.PrimesOptions?.ProtectionVelos!,
            optional: false
          });
        }

        if (res.saisiePersonnalisation.ProtectionJuridique) {
          this.options.push({
            id: OfferCustomizationEnum.JP,
            name: this.resource.offercustomization.optionJP,
            valueShow: this.currencyPipe.transform(
              roundToMonth(this.currentFormule.PrimesOptions?.ProtectionJuridique!, 2)
            ),
            monthlyValue: roundToMonth(this.currentFormule.PrimesOptions?.ProtectionJuridique!, 2),
            annuelValue: this.currentFormule.PrimesOptions?.ProtectionJuridique!,
            optional: false
          });
        }

        if (res.saisiePersonnalisation.ProtectionNomades) {
          this.options.push({
            id: OfferCustomizationEnum.NP,
            name: this.resource.offercustomization.optionNP,
            valueShow: this.currencyPipe.transform(
              roundToMonth(this.currentFormule.PrimesOptions?.ProtectionNomades!, 2)
            ),
            monthlyValue: roundToMonth(this.currentFormule.PrimesOptions?.ProtectionNomades!, 2),
            annuelValue: this.currentFormule.PrimesOptions?.ProtectionNomades!,
            optional: false
          });
        }

        if (res.saisiePersonnalisation.AssuranceScolaire != undefined) {
          this.options.push({
            id: OfferCustomizationEnum.SI,
            name: this.resource.offercustomization.optionSI,
            valueShow: '',
            monthlyValue: 0,
            annuelValue: 0,
            optional: false
          });
          if (res.saisiePersonnalisation.AssuranceScolaire.length > 0) {
            this.schoolInsurances = res.saisiePersonnalisation.AssuranceScolaire;
            this.form = this.fb.group({
              childrens: this.fb.array([])
            });
            this.schoolInsurances.forEach(element => {
              this.addChildrenState(element);
            });

            this.addNC = false;
            this.updateOption(OfferCustomizationEnum.SI);
          }
        }
      });
  }

  updateRate() {
    let total = 0;
    this.options.forEach(element => {
      total += element.annuelValue;
    });
    total = roundToMonth(total, 2);
    this.schoolInsuranceVm.monthlyRate = this.currencyPipe.transform(total);
  }

  updateOption(id: string) {
    let total = 0;
    let index = this.form.value.childrens.length;
    let option = this.options.find(x => x.id == id);
    //TODO: Api

    total = this.getRate(
      index,
      this.currentFormule.PrimesOptions?.AssuranceScolaire?.Enfant1!,
      this.currentFormule.PrimesOptions?.AssuranceScolaire?.Enfant2!,
      this.currentFormule.PrimesOptions?.AssuranceScolaire?.Enfant3!,
      this.currentFormule.PrimesOptions?.AssuranceScolaire?.Enfant4Plus!
    );
    if (option != null) {
      option.valueShow = this.currencyPipe.transform(roundToMonth(total, 2));
      option.monthlyValue = roundToMonth(total, 2);
      option.annuelValue = total;
    }
    this.updateRate();
  }

  get childrensFieldAsFormArray(): FormArray {
    return this.childrens;
  }

  getLibelle(i: number): string {
    if (i == 0) return String.format(this.resource.schoolinsurance.firstChildren, i + 1);
    else return String.format(this.resource.schoolinsurance.otherChildren, i + 1);
  }

  getRate(
    i: number,
    rateOneC: number,
    rateTwoC: number,
    rateThreeC: number,
    rateFourC: number
  ): number {
    if (i == 1) {
      return rateOneC;
    } else if (i == 2) {
      return rateTwoC;
    } else if (i == 3) {
      return rateThreeC;
    } else if (i >= 4) {
      return rateFourC;
    }
    return 0;
  }

  children(): FormGroup {
    return this.fb.group({
      lastname: ['', Validators.required],
      firstname: ['', Validators.required],
      birthday: ['', Validators.required],
      enable: [true]
    });
  }

  addChildren(): void {
    this.childrensFieldAsFormArray.push(this.children());
    this.addNC = false;
  }

  childrenState(schoolInsurance: AssuranceScolaire): FormGroup {
    return this.fb.group({
      lastname: [schoolInsurance.Nom, Validators.required],
      firstname: [schoolInsurance.Prenom, Validators.required],
      birthday: [DateUtils.IsoToDayMonthYear(schoolInsurance.DateNaissance), Validators.required],
      enable: [true]
    });
  }

  addChildrenState(schoolInsurance: AssuranceScolaire): void {
    this.childrensFieldAsFormArray.push(this.childrenState(schoolInsurance));
    this.addNC = false;
  }

  editChildren(i: number): void {
    this.form.value.childrens[i].enable = true;
    this.addNC = false;
  }

  removeChildren(i: number): void {
    this.childrensFieldAsFormArray.removeAt(i);
    this.addNC = true;
    this.updateOption(OfferCustomizationEnum.SI);
  }

  formValue(): void {
    console.log(this.form.value);
  }

  validate(i: number) {
    this.form.value.childrens[i].enable = false;
    this.addNC = true;

    this.form.value.childrens.forEach((element: children) => {
      if (element.enable) this.addNC = false;
    });

    this.updateOption(OfferCustomizationEnum.SI);
  }

  async nextStep() {
    this.isLoading = true;

    let icounter = 0;
    this.schoolInsurances = [];
    this.form.value.childrens.forEach((element: children) => {
      icounter++;
      this.schoolInsurances.push({
        Numero: icounter,
        Nom: element.lastname,
        Prenom: element.firstname,
        DateNaissance: element.birthday
      });
    });

    this.store.dispatch(
      fromOfferCustomization.patchOfferCustomization({
        payload: {
          AssuranceScolaire: this.schoolInsurances
        }
      })
    );

    this.store.dispatch(
      fromOfferCustomization.changeValidationStatus({
        isValid: true
      })
    );

    const numberOfChildren = this.form.value?.childrens?.length;
    if (numberOfChildren) {
      this.store.dispatch(
        fromService.patchService({
          payload: {
            NombreEnfants: numberOfChildren
          }
        })
      );
    }

    // Create new tarif with personalization and create devis
    const bodyTarif = ApiRequestBodyUtils.GenerateTarifCreateRequestBodyWithPersonnalisation(
      this.context,
      this.product,
      this.brand,
      this.address.adresseType,
      this.address.addresseSelection,
      this.address.addresseManuelle,
      this.address.adresseEsh,
      this.saisieHabitation,
      this.offer,
      this.formuleRecommandee,
      {
        MontantValeurObjets2000: this.customization.MontantValeurObjets2000,
        ProtectionJuridique: this.customization.ProtectionJuridique,
        ProtectionNomades: this.customization.ProtectionNomades,
        ProtectionVelos: this.customization.ProtectionVelos,
        AssuranceScolaire: this.schoolInsurances
      }
    );

    const bodyDevis = ApiRequestBodyUtils.GenerateDevisCreateRequestBody(
      this.context,
      this.clientAdvisorFullName,
      this.product,
      this.brand,
      this.address.adresseType,
      this.address.addresseSelection,
      this.address.addresseManuelle,
      this.address.adresseEsh,
      this.saisieHabitation,
      this.saisieInfosSouscripteur,
      this.offer,
      this.formuleRecommandee
    );

    //Todo :  Remplacer les "magic string" par des "const"
    this.quotationService.CreateQuotation(bodyTarif, bodyDevis).subscribe({
      next: resQuotation => {
        this.quotationSummaryService.processQuotationSummary(
          resQuotation.quotationReference,
          this.context,
          this.saisieInfosSouscripteur.Email
        );
      },
      error: () => {
        this.displayError = true;
        this.isLoading = false;
      },
      complete: () => (this.isLoading = false)
    });
  }

  async updateStepper() {
    let stepper = await lastValueFrom(this.store.select(fromInfo.selectStepper).pipe(take(1)));
    var tmpStepper = JSON.parse(JSON.stringify(stepper)) as Stepper;

    let title = tmpStepper.devis.titles.find(
      (x: { libelle: string }) => x.libelle == this.resource.stepper.offer
    );

    let subtitleS = title?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.schoolinsurance
    );

    if (subtitleS) subtitleS.isValid = true;

    let subtitleN = title?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.confirmquote
    );

    let titleN = tmpStepper.devis.titles.find(
      (x: { libelle: string }) => x.libelle == this.resource.stepper.quote
    );

    if (titleN) titleN.isActive = true;

    let subtitleNS = titleN?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.confirmquote
    );

    if (subtitleNS) subtitleNS.isActive = true;

    if (subtitleN) {
      subtitleN.isActive = true;
      subtitleN.isVisible = true;
    }

    this.store.dispatch(fromInfo.updateStepper({ payload: tmpStepper }));
  }

  ToCapitalize(event: any) {
    let value = event.target.value;
    if (value[0] != undefined) event.target.value = value[0].toUpperCase() + value.slice(1);
  }
}
