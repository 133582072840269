/**
 * VyV Conseil MRH Public API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { VyVContractEnvoiAttestationBailleurInfoBailleurPrive } from './vyVContractEnvoiAttestationBailleurInfoBailleurPrive';
import { VyVContractEnvoiAttestationBailleurInfoBailleurSocial } from './vyVContractEnvoiAttestationBailleurInfoBailleurSocial';

export interface VyVContractEnvoiAttestationBailleurInfo {
  TypeBailleur: VyVContractEnvoiAttestationBailleurInfo.TypeBailleurEnum;
  BailleurPrive: VyVContractEnvoiAttestationBailleurInfoBailleurPrive | null;
  BailleurSocial: VyVContractEnvoiAttestationBailleurInfoBailleurSocial | null;
}
export namespace VyVContractEnvoiAttestationBailleurInfo {
  export type TypeBailleurEnum = 'Social' | 'Prive';
  export const TypeBailleurEnum = {
    Social: 'Social' as TypeBailleurEnum,
    Prive: 'Prive' as TypeBailleurEnum
  };
}
