export function addDownloadParam(urlStr: string): string {
  try {
    const url = new URL(urlStr);
    if (url.searchParams.has('download')) {
      url.searchParams.set('download', 'true');
    } else {
      url.searchParams.append('download', 'true');
    }
    return url.toString();
  } catch (e) {
    return urlStr;
  }
}

export function makeIframeUrl(urlStr: string): string {
  const url = new URL(urlStr);
  url.hash = '#toolbar=0&navpanes=0&scrollbar=0';
  return url.toString();
}
