export class CreateContextRequestBuilder {
  private requestData: any;

  constructor() {
    this.requestData = {
      codeReseau: null,
      codeOperation: 'DROIT_CREATE_DEVISS',
      idVendeur: 0,
      modeConnexion: 'COB',
      idGestionaire: 0,
      referenceDevis: null,
      produit: null,
      branche: 'MRH',
      souscripteur: {
        civilite: null,
        prenom: null,
        nom: null,
        dateNaissance: null,
        paysNaissance: null,
        departementNaissance: null,
        villeNaissance: null,
        adresse: null,
        adresse2: null,
        codePostal: null,
        ville: null,
        telephone: null,
        email: null,
        profil: null,
        typologieSouscripteur: 'p'
      },
      codeVendeur: null,
      nomVendeur: null,
      prenomVendeur: null,
      siteVendeur: '000001',
      canalVente: 'W',
      referenceContrat: null,
      typeContact: null,
      donneesPartenaire: null
    };
  }

  withProduit(produit: string | null): CreateContextRequestBuilder {
    this.requestData.produit = produit?.toUpperCase();
    return this;
  }

  build(): any {
    return this.requestData;
  }
}
