import { StepShow, Title, Subtitle } from './../../models/stepper.model';
// src/app/core/state/menus/menus.reducer.ts

import { Action, createReducer, on } from '@ngrx/store';
import * as InfoActions from './info.actions';
import { initialState, InfoState } from './info.state';

const infoReducer = createReducer(
  initialState,
  on(
    InfoActions.updateResumeStep,
    (state, action: ReturnType<typeof InfoActions.updateResumeStep>) => ({
      ...state,
      resumeStep: { ...state.resumeStep, ...action.payload }
    })
  ),
  on(InfoActions.updateInfo, (state, action: ReturnType<typeof InfoActions.updateInfo>) => ({
    ...state,
    info: { ...state.info, ...action.payload }
  })),
  on(
    InfoActions.updateQuestion,
    (state, action: ReturnType<typeof InfoActions.updateQuestion>) => ({
      ...state,
      question: { ...state.question, ...action.payload }
    })
  ),
  on(
    InfoActions.updateCurrentPage,
    (state, { currentPage }: ReturnType<typeof InfoActions.updateCurrentPage>) => ({
      ...state,
      currentPage
    })
  ),
  on(InfoActions.updateStep, (state, action: ReturnType<typeof InfoActions.updateStep>) => ({
    ...state,
    step: { ...state.step, ...action.payload }
  })),
  on(InfoActions.updateStepper, (state, action: ReturnType<typeof InfoActions.updateStepper>) => ({
    ...state,
    stepper: { ...state.stepper, ...action.payload }
  })),
  on(
    InfoActions.updateNextUrl,
    (state, { nextUrl }: ReturnType<typeof InfoActions.updateNextUrl>) => ({
      ...state,
      nextUrl
    })
  ),
  on(InfoActions.resetResumeStep, state => ({
    ...state,
    resumeStep: { ...state.resumeStep, ...initialState.resumeStep }
  })),
  on(InfoActions.resetInfo, state => ({
    ...state,
    info: { ...state.info, ...initialState.info }
  })),
  on(InfoActions.resetQuestion, state => ({
    ...state,
    question: { ...state.question, ...initialState.question }
  })),
  on(InfoActions.resetStep, state => ({
    ...state,
    step: { ...state.step, ...initialState.step }
  })),
  on(InfoActions.resetStepper, state => ({
    ...state,
    stepper: { ...state.stepper, ...initialState.stepper }
  })),
  on(InfoActions.init, state => ({
    ...state,
    ...initialState
  }))
);

export function reducer(state: InfoState | undefined, action: Action) {
  return infoReducer(state, action);
}
