import { Formule, SaisieFormule, SaisiePersonnalisation } from './../../models/form-state.model';
import { createAction, props } from '@ngrx/store';

export const patchOfferCustomization = createAction(
  '[Offer Customization Page] Patch Offer Customization info',
  props<{ payload: Partial<SaisiePersonnalisation> }>()
);

export const changeValidationStatus = createAction(
  '[Offer Page] Change Validation Status',
  props<{ isValid: boolean }>()
);

export const patchCodePromo = createAction(
  '[Offer Page] Patch Saisie Formule',
  props<{ CodePromo: string }>()
);

export const patchSaisieFormule = createAction(
  '[Offer Page] Patch Saisie Formule',
  props<{ payload: Partial<SaisieFormule> }>()
);

export const patchFormuleRecommandee = createAction(
  '[Offer Page] Patch Formule Recommandee',
  props<{ FormuleRecommandee: Formule }>()
);

export const init = createAction('[Init]');
