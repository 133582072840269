import { Package, Price } from 'src/app/api/models/price.model';
import { VyVContractCreateResponse } from 'src/app/api/models/vyVContractCreateResponse';
import { Contract, Tarification, TypePromo } from 'src/app/core/models/form-state.model';

export class ApiResponseBodyUtils {
  static ExtractTarifResponseBody(response: Price): Tarification {
    return {
      Promotion: {
        TypePromo: response.promotion?.promotionType.includes('Discount')
          ? ('Pourcentage' as TypePromo)
          : response.promotion?.promotionType === 'Month'
          ? ('MoisOfferts' as TypePromo)
          : undefined,
        DetailsPromo: response.promotion?.promotionLabel
      },
      Formules: response.packages.map(p => ({
        NomFormule:
          p.code == 'CON' ? 'F1' : p.code == 'CON+' ? 'F2' : p.code == 'PAR' ? 'LM' : 'LM',
        PrimeMensuelle: p.monthlyPremiumAmountIncludingTax,
        PrimeAnnuelle: p.annualPremiumAmountIncludingTax,
        PrimesOptions: {
          ObjetsValeur: p.optionalCoverages.OBJVAL
            ? {
                Valeur10000: p.optionalCoverages.OBJVAL['10K'],
                Valeur15000: p.optionalCoverages.OBJVAL['20K']
              }
            : undefined,
          ProtectionNomades: p.optionalCoverages.ANO ? p.optionalCoverages.ANO['_'] : undefined,
          ProtectionJuridique: ApiResponseBodyUtils.GetProtectionJuridiqueOption(p),
          ProtectionVelos: p.optionalCoverages.VELO ? p.optionalCoverages.VELO['_'] : undefined,
          AssuranceScolaire: p.optionalCoverages.RCS
            ? {
                // Les propriétés Numero, Nom, Prenom et DateNaissance ne sont pas retournées par l'API
                Numero: 0,
                Nom: '',
                Prenom: '',
                DateNaissance: '',
                Enfant1: p.optionalCoverages.RCS['1'],
                Enfant2: p.optionalCoverages.RCS['2'],
                Enfant3: p.optionalCoverages.RCS['3'],
                Enfant4Plus: p.optionalCoverages.RCS['4+']
              }
            : undefined
        }
      }))
    };
  }

  static GetProtectionJuridiqueOption(p: Package): number | undefined {
    return p.optionalCoverages.PJ?._ ?? p.optionalCoverages.PJSOL?._;
  }

  static ExtractContractResponseBody(response: VyVContractCreateResponse): Contract {
    return {
      EstSansEffet: false,
      EstSigne: false,
      NumeroContrat: response.NumeroContrat,
      RefDocumentCP: response.RefDocumentCP,
      RefDocumentFIC: response.RefDocumentFIC,
      RefDocumentMandatSEPA: response.RefDocumentMandatSEPA,
      RefDocumentAttestation: response.RefDocumentAttestation,
      RefDocumentCG: response.RefDocumentCG,
      RefDocumentsSI: response.RefDocumentsSI,
      ClientAdvisorFullName: response.ClientAdvisorFullName
    };
  }
}
