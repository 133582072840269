import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppResource } from 'src/app/app.resource';
import { ResumeStep } from 'src/app/core/models/resume-step.model';
import { Resource } from 'src/app/resources/resource';
import { environment } from 'src/environments/environment';
import * as fromRoot from '../../../core/state/core.state';
import * as fromInfo from '../../../core/state/info';

@Component({
  selector: 'app-resume-step',
  templateUrl: './resume-step.component.html',
  styleUrls: ['./resume-step.component.scss']
})
export class ResumeStepComponent implements OnInit {
  resumeStep!: ResumeStep;

  resource: Resource;

  get brand() {
    return environment.brand;
  }

  constructor(
    resources: AppResource,
    private store: Store<fromRoot.State>,
    private router: Router
  ) {
    this.resource = resources['resource'];
  }

  ngOnInit() {
    this.store.select(fromInfo.selectCurrentResumeStep).subscribe(res => {
      let result = res.steps
        .filter(step => step.step)
        .map(step => {
          return this.router.url.endsWith(step.url) ? { ...step, current: true } : step;
        });
      const currentStepIndex = result.findIndex(step => this.router.url.endsWith(step.url));

      // manage the case when user is going back
      if (currentStepIndex >= 0) {
        this.resumeStep = {
          steps:
            this.brand === 'HM' ? result.slice(currentStepIndex - 1, currentStepIndex + 2) : result,
          isVisible: res.isVisible
        };
      } else {
        this.resumeStep = {
          steps: this.brand === 'HM' ? result.slice(-3) : result,
          isVisible: res.isVisible
        };
      }
    });
  }

  getAlign(): string | null {
    var scrollingElement = document.getElementById('scrollResumeStep');
    if (scrollingElement != null) {
      scrollingElement.scrollTo(0, scrollingElement.scrollHeight);
    }

    if (this.resumeStep.steps?.length === 1) return 'oneValue';
    else if (this.resumeStep.steps?.length === 2) return 'twoValue';
    else return null;
  }
}
