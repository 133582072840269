import { PopUpInfoHAVM, PopUpInfoVM } from '../../models/components/popupinfoVm';
import { Component, Input, OnInit } from '@angular/core';
import { QuestionVM } from '../../models/components/questionVm';
import { AppResource } from 'src/app/app.resource';
import { Resource } from 'src/app/resources/resource';
import * as fromInfo from '../../../core/state/info';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../core/state/core.state';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {
  @Input() question!: QuestionVM;
  @Input() popupInfo?: PopUpInfoVM;
  @Input() popupInfoHA?: PopUpInfoHAVM;
  @Input() local: boolean = false;

  logo!: string;
  resource: Resource;

  constructor(resources: AppResource, private store: Store<fromRoot.State>) {
    this.resource = resources['resource'];
    this.logo = this.resource.question.logo;
  }

  ngOnInit() {
    if (!this.local) {
      this.store.select(fromInfo.selectCurrentQuestion).subscribe(res => {
        this.question = {
          libelle: res.libelle
        };
        this.popupInfo = res.popupInfo;
      });
    } else {
      this.question = this.question;
      this.popupInfo = this.popupInfo;
      this.popupInfoHA = this.popupInfoHA;
    }
  }
}
