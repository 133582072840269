// src/app/core/state/menus/menus.reducer.ts

import { Action, createReducer, on } from '@ngrx/store';
import * as HousingActions from './housing.actions';
import { initialState, HousingState } from './housing.state';

const addressReducer = createReducer(
  initialState,
  on(
    HousingActions.patchHousingNature,
    (state, action: ReturnType<typeof HousingActions.patchHousingNature>) => ({
      ...state,
      SaisieHabitation: {
        ...state.SaisieHabitation,
        ...action.payload
      }
    })
  ),
  on(
    HousingActions.patchHousingType,
    (state, action: ReturnType<typeof HousingActions.patchHousingType>) => ({
      ...state,
      SaisieHabitation: {
        ...state.SaisieHabitation,
        ...action.payload
      }
    })
  ),
  on(
    HousingActions.patchOccupantType,
    (state, action: ReturnType<typeof HousingActions.patchOccupantType>) => ({
      ...state,
      SaisieHabitation: {
        ...state.SaisieHabitation,
        ...action.payload
      }
    })
  ),
  on(
    HousingActions.patchPreferentialType,
    (state, action: ReturnType<typeof HousingActions.patchPreferentialType>) => ({
      ...state,
      SaisieHabitation: {
        ...state.SaisieHabitation,
        ...action.payload
      }
    })
  ),
  on(
    HousingActions.patchNumberOfPeople,
    (state, action: ReturnType<typeof HousingActions.patchNumberOfPeople>) => ({
      ...state,
      SaisieHabitation: {
        ...state.SaisieHabitation,
        ...action.payload
      }
    })
  ),
  on(
    HousingActions.patchNumberRooms,
    (state, action: ReturnType<typeof HousingActions.patchNumberRooms>) => ({
      ...state,
      SaisieHabitation: {
        ...state.SaisieHabitation,
        ...action.payload
      }
    })
  ),
  on(
    HousingActions.patchParticularity,
    (state, action: ReturnType<typeof HousingActions.patchParticularity>) => ({
      ...state,
      SaisieHabitation: {
        ...state.SaisieHabitation,
        ...action.payload
      }
    })
  ),
  on(
    HousingActions.patchInsuredValue,
    (state, action: ReturnType<typeof HousingActions.patchInsuredValue>) => ({
      ...state,
      SaisieHabitation: {
        ...state.SaisieHabitation,
        ...action.payload
      }
    })
  ),
  on(HousingActions.init, state => ({
    ...state,
    ...initialState
  }))
);

export function reducer(state: HousingState | undefined, action: Action) {
  return addressReducer(state, action);
}
