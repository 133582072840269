// src/app/core/state/menus/menus.state.ts

import { AddressGroup } from '../../models/address-group.model';
import { Esh, Selection, Selectionne, Tarification } from '../../models/form-state.model';

export interface TarificationState {
  Tarification: Tarification;
}

export const initialState: TarificationState = {
  Tarification: {
    Formules: [],
    Promotion: {}
  }
};
