<app-header [headerStep]="headerStep" [local]="true"></app-header>
<ng-container *ngIf="context">
  <div *ngIf="contrat && contrat.EstSansEffet">
    <div class="container mx-auto mb-6">
      <p class="title-P my-5">{{ resource.signature.titleSansEffet }}</p>
      <br />
      <p class="text-P">{{ resource.signature.textSansEffet1 }}</p>
      <br />
      <p class="text-P">
        {{ resource.signature.textSansEffet2 }}
      </p>
      <br />
      <p class="text-P">
        {{ resource.signature.textSansEffet3 }}<b>{{ sansEffetPhoneNumber }}</b>
        {{ resource.signature.textSansEffet4 }}
        <b
          ><a href="https://vyv-conseil.fr/">{{ resource.signature.vyvWebsite }}</a></b
        >
        {{ resource.signature.textSansEffet5 }}
      </p>
    </div>
  </div>

  <div class="container mx-auto mb-6" *ngIf="contrat && !contrat.EstSansEffet">
    <div *ngIf="!stepB && contrat">
      <app-question [question]="question" [local]="true"></app-question>
      <div class="my-5">
        <div class="backgroundIFrame-ES">
          <ngx-spinner
            type="ball-spin-clockwise"
            bdColor="rgba(0,0,0,0.4)"
            color="#472583"
            [fullScreen]="false"></ngx-spinner>
          <div *ngIf="condP">
            <pdf-viewer
              *ngIf="signatureVm.condPUrlEmbed"
              [src]="{
                url: signatureVm.condPUrlEmbed,
                withCredentials: true,
                httpHeaders: {
                  'x-brand': brand
                }
              }"
              (on-progress)="onProgress($event, electronicSignatureEnum.CondP)"
              (error)="onCpPdfError($event)"
              [original-size]="false"
              [render-text]="true"
              [autoresize]="true"
              style="min-width: 327px; height: 380px"></pdf-viewer>
            <div *ngIf="!signatureVm.condPUrlEmbed">
              {{ resource.error.errorCP }}
            </div>
          </div>
          <div *ngIf="infoB">
            <pdf-viewer
              *ngIf="signatureVm.infoUrlEmbed"
              [src]="{
                url: signatureVm.infoUrlEmbed,
                withCredentials: true,
                httpHeaders: {
                  'x-brand': brand
                }
              }"
              (on-progress)="onProgress($event, electronicSignatureEnum.Info)"
              (error)="onFicPdfError($event)"
              [original-size]="false"
              [render-text]="true"
              [autoresize]="true"
              style="min-width: 327px; height: 380px"></pdf-viewer>
            <div *ngIf="!signatureVm.infoUrlEmbed">{{ resource.error.errorFIC }}</div>
          </div>
          <div *ngIf="sepa">
            <pdf-viewer
              *ngIf="signatureVm.sepaUrlEmbed"
              [src]="{
                url: signatureVm.sepaUrlEmbed,
                withCredentials: true,
                httpHeaders: {
                  'x-brand': brand
                }
              }"
              (on-progress)="onProgress($event, electronicSignatureEnum.Sepa)"
              (error)="onMandatSepaPdfError($event)"
              [original-size]="false"
              [render-text]="true"
              [autoresize]="true"
              style="min-width: 327px; height: 380px"></pdf-viewer>
            <div *ngIf="!signatureVm.sepaUrlEmbed">{{ resource.error.errorSEPA }}</div>
          </div>
        </div>
      </div>

      <div class="flex my-5">
        <div class="backgroundButton-ES flex px-1 py-0.5">
          <button
            class="my-auto flex-1"
            [ngClass]="getClass(condP)"
            (click)="updateClickButton(electronicSignatureEnum.CondP)">
            {{ resource.signature.condP }}
          </button>
          <button
            class="mx-0.5 my-auto px-1 flex-1"
            [ngClass]="getClass(infoB)"
            (click)="updateClickButton(electronicSignatureEnum.Info)">
            {{ resource.signature.info }}
          </button>
          <button
            class="my-auto flex-1"
            [ngClass]="getClass(sepa)"
            (click)="updateClickButton(electronicSignatureEnum.Sepa)">
            {{ resource.signature.sepa }}
          </button>
        </div>
      </div>
      <div class="my-5 space-y-4">
        <div class="flex">
          <p-checkbox
            [binary]="true"
            [(ngModel)]="isChecked"
            (ngModelChange)="updateChecks()"></p-checkbox>
          <p class="text-ES mx-3 py-0.5">
            {{ resource.signature.readOK_1 }}
            <span class="textB-ES"
              ><a [href]="signatureVm.condPUrlDl" target="_blank" rel="noopener">{{
                resource.signature.condP | lowercase
              }}</a></span
            >{{ resource.signature.readOK_2 }}
            <span class="textB-ES"
              ><a [href]="signatureVm.infoUrlDl" target="_blank" rel="noopener">{{
                resource.signature.info | lowercase
              }}</a></span
            >{{ resource.signature.readOK_3 }}
            <span class="textB-ES"
              ><a [href]="signatureVm.sepaUrlDl" target="_blank" rel="noopener">{{
                resource.signature.sepa
              }}</a></span
            >
          </p>
        </div>
      </div>
      <div class="flex mb-5 mt-6">
        <button
          pButton
          [disabled]="!isChecked"
          type="button"
          class="button-NS w-full"
          [label]="resource.signature.receiveCode"
          iconPos="right"
          (click)="validateStepOne()"
          [loading]="isLoading">
          <img
            *ngIf="!isLoading"
            style="position: absolute; right: 1.25rem"
            [src]="resource.signature.logoPhone" />
        </button>
      </div>
    </div>
    <div *ngIf="stepB && contrat" [formGroup]="numericCodeForm">
      <app-info-top [infoTop]="infoTop"></app-info-top>
      <app-question [question]="question" [local]="true"></app-question>
      <div class="flex" [ngClass]="displayErrorText ? 'mt-6 mb-1' : 'my-6'">
        <div class="mx-auto space-x-2">
          <input
            id="NOne"
            class="inputCode-ES"
            formControlName="NOne"
            [maxLength]="1"
            pattern="[0-9]*"
            (input)="onKeyUp($event, 'NTwo')" />
          <input
            id="NTwo"
            class="inputCode-ES"
            formControlName="NTwo"
            pattern="[0-9]*"
            [maxLength]="1"
            (input)="onKeyUp($event, 'NThree')" />
          <input
            id="NThree"
            class="inputCode-ES"
            formControlName="NThree"
            pattern="[0-9]*"
            [maxLength]="1"
            pattern="[0-9]*"
            (input)="onKeyUp($event, 'NFour')" />
          <input
            id="NFour"
            class="inputCode-ES"
            formControlName="NFour"
            [maxLength]="1"
            pattern="[0-9]*"
            (input)="onKeyUp($event, 'NFive')" />
          <input
            id="NFive"
            class="inputCode-ES"
            formControlName="NFive"
            [maxLength]="1"
            pattern="[0-9]*"
            (input)="onKeyUp($event, 'NSix')" />
          <input
            id="NSix"
            class="inputCode-ES"
            formControlName="NSix"
            [maxLength]="1"
            pattern="[0-9]*" />
        </div>
      </div>
      <small class="p-error flex justify-center -mt-1 mb-1" *ngIf="errorBadCodeSMS">
        {{ resource.signature.textBadCodeSms }}
      </small>
      <div class="flex mx-auto mb-6" *ngIf="displayErrorText">
        <small class="p-error mx-auto"
          ><b>{{ errorCodeSMS }}</b></small
        >
      </div>

      <div class="flex mx-auto">
        <p class="text-ES mx-auto">
          {{ resource.signature.notSms }}
          <a style="cursor: pointer" (click)="sendBackSmsCode()"
            ><span class="textB-ES">{{ resource.signature.notSmsB }}</span></a
          >
        </p>
      </div>
      <div class="flex my-8">
        <button
          pButton
          type="button"
          class="button-NS w-full"
          [disabled]="!numericCodeForm.valid"
          [label]="resource.nextstep.sign"
          (click)="validateStepTwo()"
          [loading]="isLoading"></button>
      </div>

      <div class="mb-5">
        <div class="flex my-2">
          <p class="textCodeError-ES">{{ resource.signature.textCodeError }}</p>
        </div>

        <div class="flex my-2" (click)="callClientService()">
          <p class="button-outline-call-ES">
            <span *ngIf="brand === 'Vyv'; else textOtherBrand">
              {{ resource.signature.textCodeErrorB }} {{ sansEffetPhoneNumber }}
            </span>
            <ng-template #textOtherBrand>
              {{ resource.signature.textCodeErrorB }}
            </ng-template>
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div class="container mx-auto h-28 md:h-40">
  <app-footer class="footer"></app-footer>
</div>

<app-error-popup
  [show]="displayError"
  (cancel)="displayError = false"
  [title]="resource.error.oups"
  [text]="resource.error.api"></app-error-popup>

<p-dialog
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '350px', height: '270px', margin: '1rem' }"
  [closable]="true"
  [showHeader]="true"
  [baseZIndex]="10000">
  <p-header>
    <p>{{ titleModal }}</p>
  </p-header>
  <p class="text-P my-5">{{ descriptionModal }}</p>
  <div class="flex justify-center mt-10">
    <button
      pButton
      type="button"
      class="button-NS w-full"
      [label]="resource.lessorcertificate.confirmModal"
      (click)="confirmModal()"></button>
  </div>
</p-dialog>
<p-dialog
  [(visible)]="showCantSignModal"
  [header]="resource.signature.cantSignModalTitle"
  [modal]="true"
  [style]="{ width: '330px', margin: '1rem' }"
  [closable]="true"
  [baseZIndex]="10000">
  <p class="text-P my-5">{{ resource.signature.cantSignModalDesc_1 }}</p>
  <ul class="text-P my-5">
    <li>{{ resource.signature.cantSignModalDesc_3 }}</li>
  </ul>
  <div class="flex justify-center mt-5">
    <button
      pButton
      type="button"
      class="button-NS w-full"
      [label]="resource.signature.cantSignModalValidate"
      (click)="confirmModal()"
      [loading]="isLoading"></button>
  </div>
</p-dialog>
<p-dialog
  [(visible)]="displayContextErrorModal"
  [modal]="true"
  [style]="{ width: '380px', height: '250px', margin: '1rem' }"
  [closable]="true"
  [showHeader]="true"
  [baseZIndex]="10000"
  (onHide)="closeErrorModal()">
  <p-header>
    <p>{{ resource.errorquote.title }}</p>
  </p-header>
  <p class="text-P my-5">
    {{ resource.errorsign.message }}
  </p>
</p-dialog>
<app-web-callback></app-web-callback>
