import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Context } from 'src/app/api/models/context.model';

@Component({
  selector: 'app-context',
  template: ''
})
export class ContextComponent implements OnInit {
  public Context?: Context;
  constructor(private route: ActivatedRoute, private router: Router) {}
  ngOnInit(): void {
    this.Context = this.route.snapshot.data['context'] as Context;
    localStorage.setItem('context', JSON.stringify(this.Context));
    this.router.navigate(['/start', this.Context.token]);
  }
}
