// src/app/core/state/menus/menus.reducer.ts

import { Action, createReducer, on } from '@ngrx/store';
import * as TarificationActions from './tarifications.actions';
import { initialState, TarificationState } from './tarifications.state';

const tarificationReducer = createReducer(
  initialState,
  on(
    TarificationActions.patchTarification,
    (state, action: ReturnType<typeof TarificationActions.patchTarification>) => ({
      ...state,
      Tarification: { ...state.Tarification, ...action.payload }
    })
  ),
  on(TarificationActions.init, state => ({
    ...state,
    ...initialState
  })),
  on(
    TarificationActions.patchTarificationPromo,
    (state, action: ReturnType<typeof TarificationActions.patchTarificationPromo>) => ({
      ...state,
      Tarification: {
        ...state.Tarification,
        Promotion: { ...state.Tarification?.Promotion, ...action.payload }
      }
    })
  )
);

export function reducer(state: TarificationState | undefined, action: Action) {
  return tarificationReducer(state, action);
}
