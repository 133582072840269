<div class="flex my-2 space-x-1 mx-5">
  <div class="flex-none w-8">
    <img [src]="resource.insurancebenefit.chipListPath" class="chipListPosition-R" />
  </div>

  <div class="inline-block align-middle my-auto grow">
    <p class="textPanel-R">
      {{ insuranceBenefitVm?.text1 }}
      <span class="textBPanel-R">{{ insuranceBenefitVm?.textB_1 }}</span>
      {{ insuranceBenefitVm?.text2 }}
    </p>
    <p class="textPanel-R">
      <span class="textBPanel-R">{{ insuranceBenefitVm?.textB_2 }}</span>
    </p>
    <div *ngIf="showDescription" class="pt-5 space-y-3">
      <p class="textDescription" *ngFor="let description of insuranceBenefitVm.description">
        {{ description }}
      </p>
    </div>
  </div>

  <div class="flex-none w-8">
    <button class="button-chevron" (click)="details()">
      <img [src]="arrowPath" class="arrowPosition-R" />
    </button>
  </div>
</div>
