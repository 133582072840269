import { Injectable } from '@angular/core';
import { DistributorCode } from 'src/app/shared/models/enum/distributorCode.enum';

@Injectable({
    providedIn: 'root'
  })
export class DistributorService {
    public static GetDistributorCode(): string {
        return DistributorCode.VY;
      }
}
