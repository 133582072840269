import { createAction, props } from '@ngrx/store';
import { SaisieInfosSouscripteur } from '../../models/form-state.model';

export const patchSubscriber = createAction(
  '[Subscriber Page] Patch Subscriber Info',
  props<{ payload: Partial<SaisieInfosSouscripteur> }>()
);

export const resetSubscriber = createAction('[Subscriber Page] Reset Subscriber Info');

export const changeValidationStatus = createAction(
  '[Subscriber Page] Change Validation Status',
  props<{ isValid: boolean }>()
);

export const init = createAction('[Init]');
