<p-dialog
  [(visible)]="show"
  [modal]="true"
  [style]="{ width: '330px', height: 'brand === HM ? 100px : 540px', margin: '1rem' }"
  [closable]="false"
  [showHeader]="false"
  [baseZIndex]="10000">
  <p class="title-P my-5">
    {{ resource.webcallback.sorry }}
  </p>
  <p class="text-P my-5" [ngClass]="{ 'text-P-other': brand === 'HM' }">
    {{ resource.webcallback.callUs }}
  </p>
  <p class="text-P text-P-other my-6 whitespace-pre-line" *ngIf="brand === 'HM'">
    {{ resource.webcallback.callUs2 }}
  </p>
  <div *ngIf="brand === 'Vyv'">
    <div class="infoModal-WCB mb-8 px-5 py-9 border-none">
      <p class="title-WCB px-2">
        {{ resource.webcallback.title }} <b>{{ resource.webcallback.titleB }}</b>
      </p>
      <div class="mx-4 my-5 button-CB flex">
        <img class="my-auto ml-6 mr-1" [src]="resource.webcallback.logo" />
        <span class="titleButton-CB my-auto ml-3 mr-2">{{ phoneNumber }}</span>
      </div>
      <p class="title-WCB px-10">
        {{ resource.webcallback.subtitle }}
        <br />
        <b> {{ resource.webcallback.subtitleB }}</b>
      </p>
    </div>
  </div>

  <div class="flex justify-center mt-2">
    <button
      pButton
      type="button"
      class="button-C w-full"
      [label]="resource.nextstep.cancel"
      (click)="onCancel()"></button>
  </div>
</p-dialog>
