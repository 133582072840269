// src/app/core/state/menus/menus.reducer.ts

import { Action, createReducer, on } from '@ngrx/store';
import * as ContractActions from './contract.actions';
import { initialState, ContractState } from './contract.state';

const contractReducer = createReducer(
  initialState,
  on(
    ContractActions.patchContract,
    (state, action: ReturnType<typeof ContractActions.patchContract>) => ({
      ...state,
      Contract: { ...state.Contract, ...action.payload }
    })
  ),
  on(ContractActions.init, state => ({
    ...state,
    ...initialState
  }))
);

export function reducer(state: ContractState | undefined, action: Action) {
  return contractReducer(state, action);
}
