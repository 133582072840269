import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { VyVSharedProductCode } from 'src/app/api/models/vyVSharedProductCode';
import { PathType, isPathType } from 'src/app/core/enums/path-type.enum';
import { BackendContext } from 'src/app/core/models/context.model';
import {
  Civilite,
  Devis,
  MontantValeurObjets2000,
  QualiteOccupant
} from 'src/app/core/models/form-state.model';
import * as fromSubscriber from 'src/app/core/state/subscriber';
import { MSALInstanceFactory } from 'src/app/shared/config/msal-config';
import { CANAL_VENTE_C } from 'src/app/shared/constants/context.constants';
import { CONTEXT, OLD_CONTEXT } from 'src/app/shared/constants/localstorage.constants';
import {
  ADDRESS_PATH,
  SOLIDARITY_ADDRESS_PATH,
  START_PATH
} from 'src/app/shared/constants/route.constants';
import { PRODUCT_PARAM } from 'src/app/shared/constants/url.constants';
import { PolicyOverviewService } from 'src/app/shared/services/policy-overview.service';
import { QuotationSummaryService } from 'src/app/shared/services/quotation-summary.service';
import { environment } from 'src/environments/environment';
import * as fromContext from '../../../../core/state/context';
import * as fromContract from '../../../../core/state/contract';
import * as fromRoot from '../../../../core/state/core.state';
import * as fromDevis from '../../../../core/state/devis';
import * as fromPath from '../../../../core/state/path';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html'
})
export class RedirectComponent implements OnInit {
  pathType: PathType | null = null;
  montantValeurObjets2000: MontantValeurObjets2000 | null = null;
  qualiteOccupant: QualiteOccupant | undefined;
  contextId: string | null = null;
  contextData: BackendContext | null = null;
  quotation: Devis | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<fromRoot.State>,
    private quotationSummaryService: QuotationSummaryService,
    private policyOverviewService: PolicyOverviewService
  ) {
    if (this.router.url.includes(START_PATH)) {
      const contextValue = this.route.snapshot.paramMap.get(CONTEXT);
      if (contextValue !== null && contextValue !== '') {
        this.contextId = contextValue;
        // check for nested route for new backend context
        const productValue = this.route.snapshot.paramMap.get(PRODUCT_PARAM)?.toUpperCase() || '';
        if (productValue == null || productValue === '') {
          const contextObj = JSON.parse(localStorage.getItem(CONTEXT) || 'null');
          if (contextObj != null) {
            if (localStorage.getItem(OLD_CONTEXT) !== null) {
              this.contextData = JSON.parse(localStorage.getItem(OLD_CONTEXT) || 'null');
              localStorage.setItem(CONTEXT, JSON.stringify(this.contextData));
              localStorage.removeItem(OLD_CONTEXT);
            } else {
              this.contextData = contextObj;
            }
            return;
          } else {
            console.warn('Failed to retrieve context data from localStorage');
          }
        } else {
          if (isPathType(productValue)) {
            this.pathType = productValue;
            return;
          } else {
            console.warn('Invalid product code', productValue);
          }
        }
      }
    }
  }

  async ngOnInit() {
    const contextObj = JSON.parse(localStorage.getItem(CONTEXT) ?? 'null');
    if (contextObj.canalVente === CANAL_VENTE_C) {
      await this.initializeMsal();
    }
    this.store.dispatch(fromContext.setContextId({ contextId: this.contextId! }));
    if (this.contextData != null) {
      // update state using context data
      this.store.dispatch(fromContext.setContextData({ payload: this.contextData }));
      switch (this.contextData.produit) {
        case 'HM_HC':
        case VyVSharedProductCode.HC:
          this.pathType = PathType.HOUSING;
          break;
        case VyVSharedProductCode.HP:
          this.pathType = PathType.HOUSING_PARIS;
          break;
        case VyVSharedProductCode.HT:
          this.pathType = PathType.HOUSING_TOURCOING;
          break;
        case VyVSharedProductCode.HS:
          this.pathType = PathType.HOUSING_SOLIDARITY;
          break;
        default:
          this.pathType = PathType.UNDEFINED;
          break;
      }
    }

    console.log('Using context ID', this.contextId, 'and product', this.pathType);

    // determine where to redirect from context data
    if (this.contextData?.referenceContrat != null) {
      this.store.dispatch(fromPath.updateType({ pathType: this.pathType || PathType.UNDEFINED }));
      this.policyOverviewService.processPolicyOverview(
        this.contextData?.referenceContrat,
        this.contextId as string,
        this.contextData.souscripteur?.email as string,
        this.contextData.contrat?.EstSigne
      );
      return;
    } else if (this.contextData?.referenceDevis != null) {
      // cas de reprise devis
      if (this.pathType) {
        this.store.dispatch(fromPath.updateType({ pathType: this.pathType }));
      }
      this.quotationSummaryService.processQuotationSummary(
        this.contextData.referenceDevis,
        this.contextId!,
        this.contextData.souscripteur?.email ?? ''
      );
      return;
    } else if (this.pathType) {
      this.store.dispatch(fromPath.updateType({ pathType: this.pathType }));

      switch (this.pathType) {
        case PathType.HOUSING:
        case PathType.HOUSING_PARIS:
        case PathType.HOUSING_TOURCOING:
          if (environment.brand === 'HM') {
            this.store.dispatch(
              fromSubscriber.patchSubscriber({
                payload: {
                  Civilite:
                    this.contextData?.souscripteur?.civilite === 1
                      ? Civilite.Monsieur
                      : this.contextData?.souscripteur?.civilite === 2
                      ? Civilite.Madame
                      : undefined,
                  Nom: this.contextData?.souscripteur?.nom,
                  Prenom: this.contextData?.souscripteur?.prenom,
                  Email: this.contextData?.souscripteur?.email,
                  DateNaissance: this.contextData?.souscripteur?.dateNaissance,
                  LieuNaissance: this.contextData?.souscripteur?.villeNaissance,
                  PaysNaissance: this.contextData?.souscripteur?.paysNaissance,
                  Telephone: this.contextData?.souscripteur?.telephone,
                  EstClient: !!this.contextData?.souscripteur
                }
              })
            );
          }
          this.router.navigate(['/' + ADDRESS_PATH]);
          return;
        case PathType.HOUSING_SOLIDARITY:
          this.router.navigate(['/' + SOLIDARITY_ADDRESS_PATH]);
          return;
        default:
        // fallthrough
      }
    }

    // default 404
    this.router.navigate(['/404']);
  }

  private async initializeMsal() {
    const msalInstance = MSALInstanceFactory();
    try {
      await msalInstance.initialize();
      const response = await msalInstance.handleRedirectPromise();
      if (!response) {
        await msalInstance.loginPopup({ scopes: environment.apiConfig.scopes });
      }

      // Attempt to acquire token silently
      const account = msalInstance.getAllAccounts()[0];
      if (account) {
        try {
          const tokenResponse = await msalInstance.acquireTokenSilent({
            scopes: environment.apiConfig.scopes,
            account: account
          });
          this.store.dispatch(
            fromDevis.patchDevis({
              payload: { ClientAdvisorFullName: tokenResponse.account?.name }
            })
          );
          this.store.dispatch(
            fromContract.patchContract({
              payload: { ClientAdvisorFullName: tokenResponse.account?.name }
            })
          );
        } catch (silentError) {
          console.error(
            'Silent token acquisition failed, falling back to interactive method',
            silentError
          );

          // Fallback to interactive token acquisition
          const tokenResponse = await msalInstance.acquireTokenPopup({
            scopes: environment.apiConfig.scopes
          });
          this.store.dispatch(
            fromDevis.patchDevis({
              payload: { ClientAdvisorFullName: tokenResponse.account?.name }
            })
          );
          this.store.dispatch(
            fromContract.patchContract({
              payload: { ClientAdvisorFullName: tokenResponse.account?.name }
            })
          );
        }
      }
    } catch (error) {
      console.error('Login error:', error);
    }
  }
}
