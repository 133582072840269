<p-dialog
  [modal]="true"
  [(visible)]="visible"
  [style]="{ width: '30rem' }"
  [draggable]="false"
  [closable]="false">
  <ng-template pTemplate="header">
    <div class="inline-flex items-center flex-col gap-2 ml-auto mr-auto">
      <div class="flex ml-auto">
        <span class="cursor-pointer" (click)="closeDialog()"><i class="pi pi-times"></i></span>
      </div>
      <span class="font-bold white-space-nowrap mt-7 text-xl">
        {{ resource.strongAuthentication.title }}
      </span>
      <span class="ml-4"> {{ resource.strongAuthentication.text1 }} {{ email }}</span>
    </div>
  </ng-template>
  <form [formGroup]="authForm" class="p-4 grid justify-items-center" (ngSubmit)="onSubmit()">
    <ng-otp-input
      [config]="{ length: 6, allowNumbersOnly: true }"
      [formCtrl]="otpFormControl"
      required></ng-otp-input>
    <div class="mt-10">
      <button
        pButton
        label="Connexion"
        class="bg-pink border-none p-3 text-white"
        type="submit"
        [disabled]="!authForm.valid"></button>
    </div>
  </form>
</p-dialog>
<app-error-toast
  *ngIf="detailMessage"
  [summaryMessage]="summaryMessage"
  [detailMessage]="detailMessage"></app-error-toast>
