export enum PathType {
  UNDEFINED = '',
  HOUSING_SOLIDARITY = 'HS',
  HOUSING_PARIS = 'HP',
  HOUSING_TOURCOING = 'HT',
  HOUSING = 'HC'
}

export const isPathType = (pathType: string | null): pathType is PathType => {
  switch (pathType) {
    case PathType.HOUSING:
    case PathType.HOUSING_PARIS:
    case PathType.HOUSING_TOURCOING:
    case PathType.HOUSING_SOLIDARITY:
      return true;
    default:
      return false;
  }
};
