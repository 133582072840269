import { option } from 'src/app/api/models/option.model';

export class CustomizeOfferVM {
  rate: string | null;
  rate10K: string;
  rate20K: string;
  subtitleJP: string;
  subtitleBP: string;
  subtitleNP: string;
  subtitleSI: string;
  options: option[];

  constructor(
    rate: string | null,
    rate10K: string,
    rate20K: string,
    subtitleJP: string,
    subtitleBP: string,
    subtitleNP: string,
    subtitleSI: string,
    options: option[]
  ) {
    this.rate = rate;
    this.rate10K = rate10K;
    this.rate20K = rate20K;
    this.subtitleJP = subtitleJP;
    this.subtitleBP = subtitleBP;
    this.subtitleNP = subtitleNP;
    this.subtitleSI = subtitleSI;
    this.options = options;
  }
}
