import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PathState } from './path.state';

export const selectPath = createFeatureSelector<PathState>('path');

export const selectPathType = createSelector(selectPath, (state: PathState) => state.pathType);

export const selectPreviousPathType = createSelector(
  selectPath,
  (state: PathState) => state.previousPathType
);

export const selectHandlers = createSelector(selectPath, (state: PathState) => state.handlers);
