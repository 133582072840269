import { Component, Input, OnInit } from '@angular/core';
import * as fromInfo from '../../../core/state/info';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../core/state/core.state';
import { skip } from 'rxjs';
import { InfoTopVM } from '../../models/components/infotopVm';

@Component({
  selector: 'app-info-top',
  templateUrl: './info-top.component.html',
  styleUrls: ['./info-top.component.scss']
})
export class InfoTopComponent implements OnInit {
  @Input() infoTop!: InfoTopVM;

  constructor(private store: Store<fromRoot.State>) {}

  ngOnInit() {
    this.store
      .select(fromInfo.selectCurrentInfo)
      .pipe(skip(1))
      .subscribe(res => {
        this.infoTop = {
          logo: res.logo,
          secondTitle: res.secondTitle,
          subTitle: res.subTitle,
          title: res.title,
          isVisible: res.isVisible
        };
      });
  }
}
