<div class="container-WCB mr-1">
  <div class="infoModal-WCB mb-16 px-5 py-9" *ngIf="show" (click)="$event.stopPropagation()">
    <p class="title-header">
      <b>
        {{ resource.webcallback.titleHeader }}
      </b>
    </p>
    <hr />
    <p class="title-WCB px-2">
      {{ resource.webcallback.title }}
    </p>
    <div class="mx-4 my-4 button-CB flex" (click)="callClientService()">
      <img
        class="my-auto ml-6 mr-1"
        *ngIf="brand === 'HM'; else otherLogo"
        [src]="resource.webcallback.logoPopup" />
      <ng-template #otherLogo>
        <img class="my-auto ml-6 mr-1" [src]="resource.webcallback.logo" />
      </ng-template>
      <span class="titleButton-CB my-auto ml-3 mr-2">{{ phoneNumber }}</span>
    </div>
    <p class="title-WCB px-10">
      {{ resource.webcallback.subtitle }}
      <br />
      <b> {{ resource.webcallback.subtitleB }}</b>
    </p>
  </div>
  <button
    class="button-WCB"
    (click)="clickFunction(); $event.stopPropagation()"
    matomoClickAction="web-callback">
    <img class="mx-auto my-auto" [src]="resource.webcallback.logo" />
  </button>
</div>
