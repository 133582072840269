<div class="container mx-auto mb-6">
  <div
    class="grid gap-x-4 grid-cols-1 md:grid-cols-2"
    [formGroup]="addressForm"
    *ngIf="!isAutocomplete && addressForm">
    <div class="w-full">
      <input
        class="my-2.5 grid w-full"
        type="text"
        pInputText
        formControlName="address"
        [placeholder]="resource.form.address" />
      <small
        class="p-error"
        *ngIf="
          address?.touched && address?.invalid && !address?.value && (address?.errors)!['required']
        "
        >{{ resource.error.address }}</small
      >
    </div>
    <div>
      <input
        class="my-2.5 grid w-full"
        type="text"
        pInputText
        [placeholder]="resource.form.postalCode"
        formControlName="postalCode" />
      <small
        class="p-error"
        *ngIf="postalCode?.touched && postalCode?.invalid && (postalCode?.errors)!['required']"
        >{{ resource.error.postalCode }}</small
      >
      <small
        class="p-error"
        *ngIf="postalCode?.touched && postalCode?.invalid && (postalCode?.errors)!['pattern']"
        >{{ resource.error.postalCodeWrong }}</small
      >
    </div>
    <div>
      <input
        class="my-2.5 grid w-full"
        type="text"
        pInputText
        [placeholder]="resource.form.city"
        formControlName="city" />
      <small
        class="p-error"
        *ngIf="city?.touched && city?.invalid && (city?.errors)!['required']"
        >{{ resource.error.city }}</small
      >
    </div>
    <div id="country">
      <p-autoComplete
        class="my-2.5 grid w-full"
        [inputStyle]="{ width: '100%' }"
        (completeMethod)="filterCountries($event)"
        formControlName="country"
        [forceSelection]="true"
        appendTo="body"
        [placeholder]="resource.form.country"
        [suggestions]="filteredCountries"
        [dropdown]="true"
        field="countryNameFr"
        [minLength]="3"></p-autoComplete>
      <small
        id="username2-help"
        class="p-error"
        *ngIf="country?.dirty && country?.invalid && country?.hasError('required')"
        >{{ resource.error.country }}</small
      >
    </div>
    <div class="my-5 md:col-span-2">
      <button
        pButton
        [disabled]="!this.addressForm.valid"
        class="mx-auto button-NS width-max iconSideLabel"
        [label]="resource.nextstep.validate"
        icon="pi pi-arrow-right"
        iconPos="right"
        (click)="valider()"
        [loading]="isLoading"></button>
    </div>
  </div>
  <div
    class="grid"
    [formGroup]="autocompleteAddressForm"
    *ngIf="isAutocomplete && autocompleteAddressForm">
    <div class="mb-2">
      <p-autoComplete
        [style]="{ width: '100%' }"
        [inputStyle]="{ width: '100%' }"
        (completeMethod)="filterAddress($event)"
        formControlName="address"
        [forceSelection]="true"
        field="place"
        appendTo="body"
        [delay]="500"
        [suggestions]="filteredAdresses"
        [minLength]="4"></p-autoComplete>
      <small
        class="p-error"
        *ngIf="
          adresseAutocomplete?.dirty &&
          adresseAutocomplete?.invalid &&
          (adresseAutocomplete?.errors)!['required']
        "
        >{{ resource.error.address }}</small
      >
    </div>
    <p class="cursor-pointer underline" *ngIf="isAutocomplete" (click)="onNotFoundAddressClick()">
      {{ resource.address.notFind }}
    </p>
    <div class="my-5">
      <button
        pButton
        [disabled]="!this.autocompleteAddressForm.valid"
        class="mx-auto button-NS width-max iconSideLabel"
        [label]="resource.nextstep.validate"
        icon="pi pi-arrow-right"
        iconPos="right"
        (click)="valider()"
        [loading]="isLoading"></button>
    </div>
  </div>
</div>
<app-error-popup
  [show]="displayError"
  (cancel)="displayError = false"
  [title]="resource.error.oups"
  [text]="errorMessageApi"></app-error-popup>

<app-web-callback-popup [show]="displayModal" (cancel)="cancel()"></app-web-callback-popup>
