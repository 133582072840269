import { Title } from './../../../core/models/stepper.model';
import { Component, Input, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import * as fromInfo from '../../../core/state/info';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../core/state/core.state';
import { skip } from 'rxjs';
import { HeaderVM } from '../../models/components/headerVm';
import { Resource } from 'src/app/resources/resource';
import { AppResource } from 'src/app/app.resource';
import { Stepper } from 'src/app/core/models/stepper.model';

export class HeaderVm {
  header!: HeaderVM;
  constructor() {}
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() headerStep!: HeaderVM;
  @Input() local: boolean = false;

  steppers!: Stepper;

  logo!: string;

  stepperShow!: boolean;
  displayModal: boolean = false;
  resource: Resource;

  constructor(
    private primengConfig: PrimeNGConfig,
    private store: Store<fromRoot.State>,
    protected resources: AppResource
  ) {
    this.resource = resources['resource'];
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.logo = this.resource.header.logo;

    if (!this.local) {
      this.store.select(fromInfo.selectCurrentStep).subscribe(res => {
        this.headerStep = {
          step: res.step,
          stepNumber: res.stepNumber,
          subStep: res.subStep,
          subStepNumber: res.subStepNumber
        };

        if (this.headerStep && this.headerStep.stepNumber == 3) this.stepperShow = true;
        else this.stepperShow = false;
      });
    } else {
      this.headerStep = this.headerStep;
      if (this.headerStep && this.headerStep.stepNumber == 3) this.stepperShow = true;
      else this.stepperShow = false;
    }

    this.store.select(fromInfo.selectStepper).subscribe(res => {
      this.steppers = res;
    });
  }

  //#region Public Methods
  public getStepSrc(stepNumber: number | undefined): string {
    switch (stepNumber) {
      case 1:
        return this.resource.header.step1;
      case 2:
        return this.resource.header.step2;
      case 3:
        return this.resource.header.step3;
      default:
        return this.resource.header.step1;
    }
  }

  public getSubStepClass(subStep: number | undefined): string {
    switch (subStep) {
      case 0:
        return 'Ellipse_0';
      case 1:
        return 'Ellipse_1';
      case 2:
        return 'Ellipse_2';
      case 3:
        return 'Ellipse_3';
      case 4:
        return 'Ellipse_4';
      case 5:
        return 'Ellipse_5';
      case 6:
        return 'Ellipse_6';
      case 7:
        return 'Ellipse_7';
      case 8:
        return 'Ellipse_8';
      case 9:
        return 'Ellipse_9';
      case 10:
        return 'Ellipse_10';
      default:
        return 'Ellipse_0';
    }
  }

  public showModalDialog() {
    this.displayModal = true;
  }

  public getTitleDevisClass(stepNumber: number | undefined, isActive: boolean): string {
    if (isActive)
      switch (stepNumber) {
        case 0:
          return 'step1Enable';
        case 1:
          return 'step2Enable';
        case 2:
          return 'step3Enable';
        default:
          return 'step1Enable';
      }
    else
      switch (stepNumber) {
        case 0:
          return 'step1Disable';
        case 1:
          return 'step2Disable';
        case 2:
          return 'step3Disable';
        default:
          return 'step1Disable';
      }
  }

  public getTitleSummaryClass(isActive: boolean): string {
    if (isActive) return 'titleSummaryOK-h';
    else return 'titleSummaryKO-h';
  }
  //#endregion
}
