import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatomoTracker } from 'ngx-matomo-client';
import { AppResource } from 'src/app/app.resource';
import { State } from 'src/app/core/state/core.state';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component.component';

import { HeaderVM } from 'src/app/shared/models/components/headerVm';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-e-sign-msg-advisor',
  templateUrl: './e-sign-msg-advisor.component.html'
})
export class ESignMsgAdvisorComponent extends BaseComponent {
  headerStep!: HeaderVM;

  get brand() {
    return environment.brand;
  }

  constructor(store: Store<State>, resources: AppResource, tracker: MatomoTracker) {
    super(store, resources, tracker);

    this.headerStep = new HeaderVM(
      this.brand === 'HM' ? this.resource.header.stepContract : this.resource.header.stepSample,
      3,
      this.resource.header.subStepPayment,
      8
    );
  }
}
