import { createAction, props } from '@ngrx/store';
import { BackendContext, Context, ContextId } from '../../models/context.model';

export const updateContext = createAction(
  '[Context] Update context',
  props<{ payload: Partial<Context> }>()
);

export const setContextId = createAction(
  '[Context] Set context ID',
  props<{ contextId: ContextId }>()
);

export const setContextData = createAction(
  '[Context] Set context data',
  props<{ payload: BackendContext }>()
);

export const init = createAction('[Init]');
