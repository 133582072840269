import { createAction, props } from '@ngrx/store';
import { SaisieHabitation } from '../../models/form-state.model';

export const patchHousingType = createAction(
  '[Housing Page] Patch Housing Type',
  props<{ payload: Partial<SaisieHabitation> }>()
);

export const patchHousingNature = createAction(
  '[Housing Page] Patch Housing Nature',
  props<{ payload: Partial<SaisieHabitation> }>()
);

export const patchOccupantType = createAction(
  '[Housing Page] Patch Occupant Type',
  props<{ payload: Partial<SaisieHabitation> }>()
);

export const patchPreferentialType = createAction(
  '[Housing Page] Patch Preferential Rate',
  props<{ payload: Partial<SaisieHabitation> }>()
);

export const patchNumberRooms = createAction(
  '[Housing Page] Patch Numbers Room',
  props<{ payload: Partial<SaisieHabitation> }>()
);

export const patchNumberRoomsValidity = createAction(
  '[Housing Page] Patch Numbers Room Validity',
  props<{ isValid: boolean }>()
);

export const patchNumberOfPeople = createAction(
  '[Housing Page] Patch Number of People',
  props<{ payload: Partial<SaisieHabitation> }>()
);

export const patchParticularity = createAction(
  '[Housing Page] Patch Particularity',
  props<{ payload: Partial<SaisieHabitation> }>()
);

export const patchInsuredValue = createAction(
  '[Housing Page] Patch InsuredValue',
  props<{ payload: Partial<SaisieHabitation> }>()
);

export const init = createAction('[Init]');
