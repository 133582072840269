import { Component, Input, OnInit } from '@angular/core';
import { AppResource } from 'src/app/app.resource';
import { Resource } from 'src/app/resources/resource';
import { InsuranceBenefitVM } from '../../models/components/insurancebenefitVm';

@Component({
  selector: 'app-insurance-benefit',
  templateUrl: './insurance-benefit.component.html',
  styleUrls: ['./insurance-benefit.component.scss']
})
export class InsuranceBenefitComponent implements OnInit {
  @Input() insuranceBenefitVm!: InsuranceBenefitVM;
  resource: Resource;

  showDescription: boolean = false;
  arrowPath!: string;

  constructor(resources: AppResource) {
    this.resource = resources['resource'];
  }

  ngOnInit(): void {
    this.arrowPath = this.resource.insurancebenefit.arrowDownPath;
  }

  details(): void {
    this.showDescription = !this.showDescription;
    if (this.showDescription)
      this.arrowPath = this.resource.insurancebenefit.arrowUpPath;
    else this.arrowPath = this.resource.insurancebenefit.arrowDownPath;
  }
}
