<div class="space-y-4 mt-6 md:grid md-grid-cols-4 md:gap-4 md:space-y-0">
  <div class="flex" *ngIf="showLessor">
    <div
      class="panelSV width-max"
      [ngClass]="setClassActive(checkLessor)"
      (click)="setInputSwitch('L')">
      <div class="flex mx-2.5 my-3">
        <div class="basis-5/6 mr-1">
          <p class="textPanel-SV">{{ resource.services.sendLessor }}</p>
        </div>
        <div class="basis-1/6 text-right">
          <p-inputSwitch [(ngModel)]="checkLessor" (click)="setInputSwitch('L')"></p-inputSwitch>
        </div>
      </div>
    </div>
  </div>
  <div class="flex">
    <div
      class="panelSV width-max"
      [ngClass]="setClassActive(checkCancellation)"
      (click)="setInputSwitch('C')">
      <div class="flex mx-2 my-3">
        <div class="basis-5/6 mr-1">
          <p class="textPanel-SV">{{ resource.services.cancellation }}</p>
        </div>
        <div class="basis-1/6 text-right">
          <p-inputSwitch
            [(ngModel)]="checkCancellation"
            (click)="setInputSwitch('C')"></p-inputSwitch>
        </div>
      </div>
    </div>
  </div>
  <div class="flex" (click)="setInputSwitch('O')" *ngIf="brand !== 'HM'">
    <div class="panelSV width-max" [ngClass]="setClassActive(checkOpposition)">
      <div class="flex mx-2 my-3">
        <div class="basis-5/6 mr-1">
          <p class="textPanel-SV">{{ resource.services.check }}</p>
        </div>
        <div class="basis-1/6 text-right">
          <p-inputSwitch
            [(ngModel)]="checkOpposition"
            (click)="setInputSwitch('O')"></p-inputSwitch>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="flex mt-6 mb-6">
  <button
    pButton
    type="button"
    class="button-NS w-full iconSideLabel"
    [label]="resource.nextstep.nextStep"
    iconPos="right"
    icon="pi pi-arrow-right"
    (click)="nextStep()"></button>
</div>
