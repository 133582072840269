import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MatomoTracker } from 'ngx-matomo-client';
import { lastValueFrom, take } from 'rxjs';
import { LessorService } from 'src/app/api/services/lessor.service';
import { AppResource } from 'src/app/app.resource';
import { PathType } from 'src/app/core/enums/path-type.enum';
import { QualiteOccupant, TypeBailleur } from 'src/app/core/models/form-state.model';
import { Question } from 'src/app/core/models/question.model';
import { Step } from 'src/app/core/models/step.model';
import { Stepper } from 'src/app/core/models/stepper.model';
import * as fromContext from 'src/app/core/state/context';
import * as fromInfo from 'src/app/core/state/info';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component.component';
import { MatomoConstants } from 'src/app/shared/constants/matomo.constants';
import { environment } from 'src/environments/environment';
import * as fromAddress from '../../../core/state/address';
import { State } from '../../../core/state/core.state';
import * as fromHousing from '../../../core/state/housing';
import * as fromPath from '../../../core/state/path';
import * as fromService from '../../../core/state/service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [LessorService]
})
export class ServicesComponent extends BaseComponent implements OnInit {
  override step: Step = {
    step: this.resource.header.stepContract,
    stepNumber: 3,
    subStep: this.resource.header.subStepAssociatedService,
    subStepNumber: 3
  };

  override question: Question = {
    libelle: this.resource.question.associatedService1,
    popupInfo: undefined
  };

  public override currentPage = MatomoConstants.CHOIX_SERVICES;

  checkLessor: boolean = false;
  checkCancellation: boolean = false;
  checkOpposition: boolean = false;

  urlModeleCourrier = environment.modeleCourrierResiliation;

  path!: PathType;
  showLessor: boolean = true;

  //Si conseiller ou client
  isAdvisor: boolean = false;

  get brand() {
    return environment.brand;
  }

  constructor(
    store: Store<State>,
    resources: AppResource,
    tracker: MatomoTracker,
    private router: Router,
    private lessorService: LessorService
  ) {
    super(store, resources, tracker);
  }

  override async ngOnInit() {
    super.ngOnInit();

    await this.loadStateData();
  }

  private async loadStateData() {
    this.store.dispatch(
      fromService.changeValidationStatus({
        isValid: false
      })
    );

    this.store.select(fromService.selectService).subscribe(res => {
      this.checkLessor = res.saisieChoixServices.EnvAttB;
      this.checkCancellation = res.saisieChoixServices.ResAncAss;
      this.checkOpposition = res.saisieChoixServices.OppositionMarketing;
    });

    //Afficher ou non la partie bailleur
    var housingData = await lastValueFrom(
      this.store.select(fromHousing.selectHousing).pipe(take(1))
    );

    this.path = await lastValueFrom(this.store.select(fromPath.selectPathType).pipe(take(1)));

    if (
      housingData.SaisieHabitation.QualiteOccupant == QualiteOccupant.Locataire &&
      (this.path == PathType.HOUSING ||
        this.path === PathType.HOUSING_SOLIDARITY ||
        this.path === PathType.HOUSING_PARIS ||
        this.path == PathType.HOUSING_TOURCOING)
    ) {
      this.showLessor = true;
    } else {
      this.showLessor = false;
    }

    this.store.select(fromContext.selectContext).subscribe(res => {
      if (res.contextData != null)
        if (res.contextData?.modeConnexion != 'COB') this.isAdvisor = true;
    });
  }

  async nextStep() {
    this.store.dispatch(
      fromService.patchService({
        payload: {
          EnvAttB: this.checkLessor,
          ResAncAss: this.checkCancellation,
          OppositionMarketing: this.checkOpposition
        }
      })
    );

    this.store.dispatch(
      fromService.changeValidationStatus({
        isValid: true
      })
    );

    if (this.checkOpposition) {
      this.tracker.trackEvent(
        MatomoConstants.CONTENT_INTERACTION,
        MatomoConstants.CHOIX_SERVICES,
        MatomoConstants.OPPOSITION
      );
    }

    this.updateStepper(this.checkLessor, this.checkCancellation);

    if (this.path == PathType.HOUSING_SOLIDARITY && this.checkLessor) {
      var addressESH = await lastValueFrom(
        this.store.select(fromAddress.selectAddressEshGroupData).pipe(take(1))
      );

      let nomBailleur = '';
      let email = '';
      if (addressESH?.NomBailleur) {
        this.lessorService.GetLessorEmailByName(addressESH?.NomBailleur).subscribe(res => {
          nomBailleur = addressESH!.NomBailleur!;
          email = res;

          const payload = {
            EnvoiAttestationBailleur: {
              TypeBailleur: TypeBailleur.Prive,
              BailleurSocial: undefined,
              BailleurPrive: {
                NomBailleur: nomBailleur,
                Email: email
              },
              isValid: true
            }
          };

          this.store.dispatch(fromService.patchService({ payload }));
          this.store.dispatch(
            fromService.changeValidationStatus({
              isValid: true
            })
          );
          if (this.checkCancellation) this.router.navigate(['/cancellation']);
          else this.router.navigate(['/payment']);
        });
      } else {
        this.router.navigate(['/lessorcertificate']);
      }
    } else if (this.path != PathType.HOUSING_SOLIDARITY && this.checkLessor)
      this.router.navigate(['/lessorcertificate']);
    else if (this.checkCancellation) this.router.navigate(['/cancellation']);
    else this.skipStep();
  }

  skipStep() {
    this.store.dispatch(
      fromService.patchService({
        payload: {
          ResAncAss: false
        }
      })
    );
    this.store.dispatch(
      fromService.changeValidationStatus({
        isValid: true
      })
    );

    this.updateStepper(this.checkLessor, this.checkCancellation);

    this.router.navigate(['/payment']);
  }

  async updateStepper(checkLessor: boolean, checkCancellation: boolean) {
    let stepper = await lastValueFrom(this.store.select(fromInfo.selectStepper).pipe(take(1)));
    var tmpStepper = JSON.parse(JSON.stringify(stepper)) as Stepper;

    let title = tmpStepper.contract.titles.find(
      (x: { libelle: string }) => x.libelle == this.resource.stepper.contract
    );

    let subtitleS = title?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.services
    );

    if (subtitleS) {
      subtitleS.isValid = true;
      subtitleS.isActive = true;
    }

    let titleN = tmpStepper.contract.titles.find(
      (x: { libelle: string }) => x.libelle == this.resource.stepper.contract
    );

    let lessorN = titleN?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.lessorcertificate
    );

    if (lessorN) {
      if (checkLessor) {
        lessorN.isActive = true;
        lessorN.isVisible = true;
      } else {
        lessorN.isActive = false;
        lessorN.isVisible = false;
      }
    }

    let cancellationN = titleN?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.cancellation
    );

    if (cancellationN) {
      if (checkCancellation && !checkLessor) {
        cancellationN.isActive = true;
        cancellationN.isVisible = true;
      } else if (checkCancellation && checkLessor) {
        cancellationN.isActive = false;
        cancellationN.isVisible = true;
      } else {
        cancellationN.isActive = false;
        cancellationN.isVisible = false;
      }
    }

    let titleNS = tmpStepper.contract.titles.find(
      (x: { libelle: string }) => x.libelle == this.resource.stepper.sampling
    );

    if (titleNS) titleNS.isActive = true;

    let paymentN = titleNS?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.payment
    );

    if (!this.checkLessor && !this.checkCancellation) {
      if (paymentN) paymentN.isActive = true;
    }

    this.store.dispatch(fromInfo.updateStepper({ payload: tmpStepper }));
  }

  setClassActive(value: boolean): string {
    return value ? 'active' : 'inactive';
  }

  setInputSwitch(choice: string) {
    switch (choice) {
      case 'L':
        this.checkLessor = !this.checkLessor;
        break;
      case 'C':
        this.checkCancellation = !this.checkCancellation;
        break;
      case 'O':
        this.checkOpposition = !this.checkOpposition;
        break;
    }
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }
}
