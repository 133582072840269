import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DETAIL_MESSAGE, SUMMARY_MESSAGE } from '../../constants/error-toast.constants';

@Component({
  selector: 'app-error-toast',
  templateUrl: './error-toast.component.html'
})
export class ErrorToastComponent implements OnChanges {
  @Input() summaryMessage: string = '';
  @Input() detailMessage: string = '';

  constructor(private messageService: MessageService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes[SUMMARY_MESSAGE]) {
      this.summaryMessage = changes[SUMMARY_MESSAGE].currentValue ?? this.summaryMessage;
    }
    if (changes[DETAIL_MESSAGE]) {
      this.detailMessage = changes[DETAIL_MESSAGE].currentValue ?? this.detailMessage;
    }
    this.messageService.add({
      severity: 'error',
      summary: this.summaryMessage,
      detail: this.detailMessage
    });
  }
}
