import { Guid } from 'guid-typescript';
import { DownloadableFile } from 'src/app/api/models/document-blob';
import { option } from 'src/app/views/devis/quote-confirmation/quote-confirmation.component';

export class ConfirmQuoteVM {
  subtitle: string;
  numberQuote: string;
  rateMonth!: string | null;
  rateYear!: string | null;
  options: option[];
  agreementId?: Guid;
  quoteDoc?: DownloadableFile;
  ficDoc?: DownloadableFile;
  productInformationDoc?: DownloadableFile;
  generalConditionDoc?: DownloadableFile;

  constructor(
    numberQuote: string,
    subtitle: string,
    options: option[],
    agreementId?: Guid,
    quoteDoc?: DownloadableFile,
    ficDoc?: DownloadableFile,
    productInformationDoc?: DownloadableFile,
    generalConditionDoc?: DownloadableFile
  ) {
    this.subtitle = subtitle;
    this.numberQuote = numberQuote;
    this.options = options;
    this.ficDoc = ficDoc;
    this.quoteDoc = quoteDoc;
    this.generalConditionDoc = generalConditionDoc;
    this.productInformationDoc = productInformationDoc;
    this.agreementId = agreementId;
  }
}
