import { DependencyEnum } from './../../../shared/models/enum/dependency.enum';
import { Component, OnInit } from '@angular/core';
import { AppResource } from 'src/app/app.resource';
import { PopUpInfoVM } from 'src/app/shared/models/components/popupinfoVm';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component.component';
import { Step } from 'src/app/core/models/step.model';
import { State } from 'src/app/core/state/core.state';
import { Store } from '@ngrx/store';
import { Question } from 'src/app/core/models/question.model';
import * as fromHousing from '../../../core/state/housing';
import * as fromInfo from '../../../core/state/info';
import { SurfaceDependance, TypeHabitation } from 'src/app/core/models/form-state.model';
import { lastValueFrom, take } from 'rxjs';
import { ResumeStep, cloneResumeStep } from 'src/app/core/models/resume-step.model';
import { HousingState } from '../../../core/state/housing';

import { MatomoTracker } from 'ngx-matomo-client';
import { MatomoConstants } from 'src/app/shared/constants/matomo.constants';
import { Stepper } from 'src/app/core/models/stepper.model';
@Component({
  selector: 'app-dependency',
  templateUrl: './dependency.component.html',
  styleUrls: ['./dependency.component.scss']
})
export class DependencyComponent extends BaseComponent implements OnInit {
  popupInfo!: PopUpInfoVM;
  dependencyEnum = DependencyEnum;

  housingData!: HousingState;
  displayModal: boolean = false;

  noDependencyOvered = false;
  lessThan50mOvered = false;
  lessThan100mOvered = false;
  moreThan100mOvered = false;

  noDependencySelected = false;
  lessThan50mSelected = false;
  lessThan100mSelected = false;
  moreThan100mSelected = false;
  moreThan250mSelected = false;

  override step: Step = {
    step: this.resource.header.stepHousing,
    stepNumber: 1,
    subStep: this.resource.header.subStepDependency,
    subStepNumber: 8
  };

  override question: Question = {
    libelle: this.resource.question.dependency,
    popupInfo: new PopUpInfoVM(this.resource.infobulle.dependency, '', '', '', '')
  };

  public override currentPage = MatomoConstants.DEPENDANCES;

  //Form
  noDependency: boolean = false;
  inferior50: boolean = false;
  inferior99: boolean = false;
  superior100: boolean = false;

  constructor(
    store: Store<State>,
    resources: AppResource,
    tracker: MatomoTracker,
    private router: Router
  ) {
    super(store, resources, tracker);
  }

  override async ngOnInit() {
    super.ngOnInit();
    this.updateResumeStepVisibility(true);
    this.loadStateData();
  }

  private async loadStateData() {
    this.housingData = await lastValueFrom(
      this.store.select(fromHousing.selectHousing).pipe(take(1))
    );

    switch (this.housingData.SaisieHabitation.SurfaceDependance) {
      case SurfaceDependance.Aucune:
        this.noDependencySelected = true;
        break;
      case SurfaceDependance.MoinsDe50m2:
        this.lessThan50mSelected = true;
        break;
      case SurfaceDependance.De50a100m2:
        this.lessThan100mSelected = true;
        break;
      case SurfaceDependance.PlusDe100m2:
        this.moreThan100mSelected = true;
        break;
    }
  }

  selectValue(value: string) {
    switch (value) {
      case DependencyEnum.NoDependency:
        this.noDependency = true;
        this.inferior50 = false;
        this.inferior99 = false;
        this.superior100 = false;
        this.store.dispatch(
          fromHousing.patchNumberOfPeople({
            payload: { SurfaceDependance: SurfaceDependance.Aucune }
          })
        );
        break;
      case DependencyEnum.Inferior50:
        this.noDependency = false;
        this.inferior50 = true;
        this.inferior99 = false;
        this.superior100 = false;
        this.store.dispatch(
          fromHousing.patchNumberOfPeople({
            payload: { SurfaceDependance: SurfaceDependance.MoinsDe50m2 }
          })
        );
        break;
      case DependencyEnum.Inferior99:
        this.noDependency = false;
        this.inferior50 = false;
        this.inferior99 = true;
        this.superior100 = false;
        this.store.dispatch(
          fromHousing.patchNumberOfPeople({
            payload: { SurfaceDependance: SurfaceDependance.De50a100m2 }
          })
        );
        break;
      case DependencyEnum.Superior100:
        this.noDependency = false;
        this.inferior50 = false;
        this.inferior99 = false;
        this.superior100 = true;
        this.store.dispatch(
          fromHousing.patchNumberOfPeople({
            payload: { SurfaceDependance: SurfaceDependance.PlusDe100m2 }
          })
        );
        break;
    }

    this.redirectRules();
  }

  setClassActive(value: boolean): string {
    return value ? 'active' : 'inactive';
  }

  async onNoDependencySelected() {
    this.noDependencySelected = !this.noDependencySelected;
    this.lessThan50mSelected = false;
    this.lessThan100mSelected = false;
    this.moreThan100mOvered = false;
    if (this.noDependencySelected) {
      await this.updateResumeStep(this.resource.resumestep.noDependency);

      this.store.dispatch(
        fromHousing.patchNumberOfPeople({
          payload: { SurfaceDependance: SurfaceDependance.Aucune }
        })
      );
      this.redirectRules();
    }
  }

  async onLessThan50mSelected() {
    this.lessThan50mSelected = !this.lessThan50mSelected;
    this.noDependencySelected = false;
    this.lessThan100mSelected = false;
    this.moreThan100mSelected = false;
    this.moreThan250mSelected = false;
    if (this.lessThan50mSelected) {
      await this.updateResumeStep(this.resource.resumestep.lessThan50m);

      this.store.dispatch(
        fromHousing.patchNumberOfPeople({
          payload: { SurfaceDependance: SurfaceDependance.MoinsDe50m2 }
        })
      );
      this.redirectRules();
    }
  }

  async onLessThan100mSelected() {
    this.lessThan100mSelected = !this.lessThan100mSelected;
    this.noDependencySelected = false;
    this.lessThan50mSelected = false;
    this.moreThan100mSelected = false;
    this.moreThan250mSelected = false;
    if (this.lessThan100mSelected) {
      await this.updateResumeStep(this.resource.resumestep.lessThan100m);

      this.store.dispatch(
        fromHousing.patchNumberOfPeople({
          payload: { SurfaceDependance: SurfaceDependance.De50a100m2 }
        })
      );
      this.redirectRules();
    }
  }

  async onMoreThan100mSelected() {
    this.moreThan100mSelected = !this.moreThan100mSelected;
    this.lessThan50mSelected = false;
    this.lessThan100mSelected = false;
    this.moreThan250mSelected = false;
    this.noDependencySelected = false;
    if (this.moreThan100mSelected) {
      await this.updateResumeStep(this.resource.resumestep.moreThan100m);

      this.store.dispatch(
        fromHousing.patchNumberOfPeople({
          payload: { SurfaceDependance: SurfaceDependance.PlusDe100m2 }
        })
      );
      this.redirectRules();
    }
  }

  async onMoreThan250mSelected() {
    this.moreThan250mSelected = !this.moreThan250mSelected;
    this.lessThan50mSelected = false;
    this.lessThan100mSelected = false;
    this.moreThan100mSelected = false;
    this.noDependencySelected = false;
    if (this.moreThan250mSelected) {
      await this.updateResumeStep(this.resource.resumestep.moreThan100m);

      this.store.dispatch(
        fromHousing.patchNumberOfPeople({
          payload: { SurfaceDependance: SurfaceDependance.PlusDe250m2 }
        })
      );
    }
    this.displayModal = true;
  }

  cancel() {
    this.displayModal = false;
  }

  redirectRules() {
    if (this.housingData.SaisieHabitation.TypeHabitation == TypeHabitation.Maison) {
      this.updateStepper(true);
      this.router.navigate(['/particularity']);
    } else {
      this.updateStepper(false);
      this.router.navigate(['/insuredvalue']);
    }
  }

  async updateStepper(particularity: boolean) {
    let stepper = await lastValueFrom(this.store.select(fromInfo.selectStepper).pipe(take(1)));
    var tmpStepper = JSON.parse(JSON.stringify(stepper)) as Stepper;

    let title = tmpStepper.devis.titles.find(
      (x: { libelle: string }) => x.libelle == this.resource.stepper.lodging
    );

    let subtitleS = title?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.dependency
    );

    if (subtitleS) subtitleS.isValid = true;

    let subtitleN;
    if (particularity)
      subtitleN = title?.subtitles.find(
        (x: { url: string }) => x.url == this.resource.stepper.particularity
      );
    else
      subtitleN = title?.subtitles.find(
        (x: { url: string }) => x.url == this.resource.stepper.insuredvalue
      );

    if (subtitleN) {
      subtitleN.isActive = true;
      subtitleN.isVisible = true;
    }

    this.store.dispatch(fromInfo.updateStepper({ payload: tmpStepper }));
  }

  async updateResumeStep(libelle: string) {
    const resumeStep = await lastValueFrom(
      this.store.select(fromInfo.selectCurrentResumeStep).pipe(take(1))
    );
    const tmpResumeStep = cloneResumeStep(resumeStep);

    const stepResumeStep = tmpResumeStep.steps?.find(
      x => x.id == this.resource.resumestep.dependency
    );

    if (stepResumeStep) {
      stepResumeStep.step = libelle;
      stepResumeStep.isVisible = true;
    } else {
      tmpResumeStep.steps?.push({
        id: this.resource.resumestep.dependency,
        step: libelle,
        url: this.resource.resumestep.dependency,
        isVisible: true
      });
    }
    this.store.dispatch(fromInfo.updateResumeStep({ payload: tmpResumeStep }));
  }
}
