import { Action, createReducer, on } from '@ngrx/store';
import { setContextId, updateContext, setContextData, init } from './context.actions';
import { initialState, ContextState } from './context.state';

const contextReducer = createReducer(
  initialState,
  on(updateContext, (state, action: ReturnType<typeof updateContext>) => ({
    ...state,
    ...action.payload
  })),
  on(setContextId, (state, { contextId }: ReturnType<typeof setContextId>) => ({
    ...state,
    contextId
  })),
  on(setContextData, (state, action: ReturnType<typeof setContextData>) => ({
    ...state,
    contextData: { ...action.payload }
  })),
  on(init, state => ({
    ...state,
    ...initialState
  }))
);

export function reducer(state: ContextState | undefined, action: Action) {
  return contextReducer(state, action);
}
