import { createAction, props } from '@ngrx/store';
import { SaisieChoixServices } from '../../models/form-state.model';

export const patchService = createAction(
  '[Service Page] Patch Service info',
  props<{ payload: Partial<SaisieChoixServices> }>()
);

export const changeValidationStatus = createAction(
  '[Service Page] Change Validation Status',
  props<{ isValid: boolean }>()
);

export const init = createAction('[Init]');
