// src/app/core/state/menus/menus.reducer.ts

import { Action, createReducer, on } from '@ngrx/store';
import * as ElectronicSignatureActions from './electronic-signature.actions';
import { initialState, ElectronicSignatureState } from './electronic-signature.state';

const electronicSignatureReducer = createReducer(
  initialState,
  on(
    ElectronicSignatureActions.patchSignature,
    (state, action: ReturnType<typeof ElectronicSignatureActions.patchSignature>) => ({
      ...state,
      electronicSignature: { ...state.electronicSignature!, ...action.payload }
    })
  ),
  on(
    ElectronicSignatureActions.changeValidationStatus,
    (state, { isValid }: ReturnType<typeof ElectronicSignatureActions.changeValidationStatus>) => ({
      ...state,
      isValid
    })
  ),
  on(ElectronicSignatureActions.init, state => ({
    ...state,
    ...initialState
  }))
);

export function reducer(state: ElectronicSignatureState | undefined, action: Action) {
  return electronicSignatureReducer(state, action);
}
