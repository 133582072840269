// src/app/core/state/menus/menus.state.ts

import { Esh, Selection, Selectionne } from '../../models/form-state.model';

export interface AddressState {
  addresseManuelle: Esh;
  addresseSelection?: Selection;
  adresseEsh?: Esh;
  adresseType: Selectionne;
  isValid: boolean;
  isParis: boolean;
  isTourcoing: boolean;
  isZoneInondable: boolean;
  isEligibleAHP: boolean;
}

export const initialState: AddressState = {
  addresseManuelle: {
    CodePostal: '',
    NomBailleur: '',
    NumeroEtRue: '',
    Pays: 'France',
    Ville: ''
  },
  adresseEsh: {
    CodePostal: '',
    NomBailleur: '',
    NumeroEtRue: '',
    Pays: 'France',
    Ville: ''
  },
  adresseType: Selectionne.Undefined,
  isValid: false,
  isParis: false,
  isTourcoing: false,
  isZoneInondable: false,
  isEligibleAHP: false
};
