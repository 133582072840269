import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../core/state/core.state';
import * as fromPolicy from 'src/app/core/state/contract';
import { Router } from '@angular/router';
import { PoliciesService } from 'src/app/api/services/policies.service';
import { PolicySummaryResponse } from 'src/app/api/models/policySummaryResponse.model';

@Injectable({
  providedIn: 'root'
})
export class PolicySummaryService {
  constructor(
    private policyService: PoliciesService,
    private store: Store<fromRoot.State>,
    private router: Router
  ) {}

  public processPolicySummary(agreementId: string, contextId: string, email: string) {
    this.policyService
      .GetPolicySummary(
        Guid.parse(agreementId).toJSON().value,
        Guid.parse(contextId).toJSON().value,
        email
      )
      .subscribe(data => {
        this.dispatchPolicy(data);
        this.router.navigate(['/confirmcontract']);
      });
  }

  private dispatchPolicy(dataPolicy: PolicySummaryResponse) {
    this.store.dispatch(
      fromPolicy.patchContract({
        payload: {
          RefContrat: dataPolicy.reference,
          RefDocumentCP: dataPolicy.cpFileId.toString(),
          RefDocumentFIC: dataPolicy.ficFileId.toString(),
          RefDocumentCG: dataPolicy.generalConditionFileId.toString(),
          RefDocumentMandatSEPA: dataPolicy.sepaFileId.toString(),
          RefDocumentsSI: [...(dataPolicy.attscoFileIds ?? []).map(att => att.toString())],
          RefDocumentAttestation: dataPolicy.attassuFileId?.toString()
        }
      })
    );
  }
}
